<template>
  <v-menu
    v-model="menuFiltros"
    :close-on-content-click="false"
    :nudge-width="260"
    max-width="400"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="filtros.length"
        :value="filtros.length"
        color="red"
        overlap
      >
        <v-btn
          elevation="0"
          class="ml-2"
          color="primary"
          height="40"
          v-bind="attrs"
          v-on="on"
          outlined
        >
          <v-icon
            small
            left
          >
            mdi-filter-variant
          </v-icon>
          Filtros
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title>
        <h5>Filtros</h5>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels
          v-model="panels"
          accordion
          dense
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="d-flex align-center">
                <v-icon
                  small
                  left
                >
                  mdi-calendar
                </v-icon>
                Período
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3">
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menuDtIni"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formataDtIni"
                        label="Data inicial"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filtrosSel.data_inicial"
                      @input="menuDtIni = false"
                      locale="PT-BR"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menuDtFin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formataDtFin"
                        label="Data final"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filtrosSel.data_final"
                      @input="menuDtFin = false"
                      locale="PT-BR"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-divider></v-divider>
            <v-expansion-panel-header>
              <span class="d-flex align-center">
                <v-icon
                  small
                  left
                >
                  mdi-check-circle-outline
                </v-icon>
                Status
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3">
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isPendentes"
                  label="Pendentes"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isLiberados"
                  label="Liberados"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isNegados"
                  label="Negados"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isCancelados"
                  label="Cancelados"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="limpaFiltro"
          class="text-capitalize" 
          text
        >
          Limpar
        </v-btn>
        <v-btn 
          @click="aplicaFiltros"
          class="text-capitalize"
          color="primary"
          text
        >
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'
export default {
  data(){
    return {
      filtros: [],
      panels: [],
      menuFiltros: false,
      menuDtIni: false,
      menuDtFin: false,
      filtrosSel: {
        data_inicial: '',
        data_final: '',
        isPendentes: false,
        isLiberados: false,
        isNegados: false,
        isCancelados: false
      },

    }
  },

  props: {
    callback: {
      type: Function,
    },
  },

  computed: {
    formataDtIni(){
      return this.filtrosSel.data_inicial ? moment(this.filtrosSel.data_inicial).format('DD/MM/YYYY') : ''
    },

    formataDtFin(){
      return this.filtrosSel.data_final ? moment(this.filtrosSel.data_final).format('DD/MM/YYYY') : ''
    },
  },

  methods: {
    aplicaFiltros(){
      this.filtros = []
      const { data_inicial,data_final,isPendentes,isLiberados,isNegados,isCancelados } = this.filtrosSel

      if (data_inicial){
        this.filtros.push({
          campo: 'data_inicial',
          valor: data_inicial
        })
      }
      if (data_final){
        this.filtros.push({
          campo: 'data_final',
          valor: data_final
        })
      }
      if (isPendentes){
        this.filtros.push({
          campo: 'status',
          valor: 'P'
        })
      }
      if (isLiberados){
        this.filtros.push({
          campo: 'status',
          valor: 'L'
        })
      }
      if (isNegados){
        this.filtros.push({
          campo: 'status',
          valor: 'N'
        })
      }
      if (isCancelados){
        this.filtros.push({
          campo: 'status',
          valor: 'C'
        })
      }

      this.fechaFiltro();
      this.retornaFiltro();
    },

    limpaFiltro(){
      this.setaFiltroInicial()
      this.fechaFiltro();
      this.retornaFiltro();
    },

    fechaFiltro(){
      this.panels = []
      this.menuFiltros = false
    },

    setaFiltroInicial(){
      const data = moment().format('YYYY-MM-DD')
      const status = 'P'

      this.filtrosSel = {
        data_inicial: data,
        data_final: data,
        isPendentes: true,
        isLiberados: false,
        isNegados: false,
        isCancelados: false
      }

      this.filtros = []
      this.filtros.push({
        campo: 'data_inicial',
        valor: data
      })
      this.filtros.push({
        campo: 'data_final',
        valor: data
      })
      this.filtros.push({
        campo: 'status',
        valor: status
      })
    },

    retornaFiltro() {
      if (this.callback) {
        this.callback(this.filtros);
      }
    },
  },

  created(){
    this.setaFiltroInicial();
    this.retornaFiltro();
  }
}
</script>

<style>

</style>
<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Alteração de senha'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">
        <v-card
          :class="'rounded-xl ' + (isMobile ? 'pa-6' : 'pa-12')"
          outlined
        >
          <v-form
            ref="senhaForm"
            v-model="valid"
            @submit.prevent="submit()"
          >
            <v-row dense>
              <v-col>
                <v-text-field
                  label="Nome"
                  v-model="usuario.nome"
                  :rules="[v => !!v || 'Campo obrigatório']"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  label="Senha nova"
                  v-model="form.senha_nova"
                  :rules="[ v => !!v || 'Campo obrigatório' ]"
                  :append-icon="mostraSenhaNova ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="mostraSenhaNova = !mostraSenhaNova"
                  :type="mostraSenhaNova ? 'text' : 'password'"
                  :autofocus="! isMobile"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  label="Confirme a senha nova"
                  v-model="form.senha_nova_confirma"
                  :rules="[ v => !!v || 'Campo obrigatório' ]"
                  :append-icon="mostraSenhaConfirma ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="mostraSenhaConfirma = !mostraSenhaConfirma"
                  :type="mostraSenhaConfirma ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="d-flex justify-end">
                <v-btn
                  color="primary"
                  elevation="0"
                  type="submit"
                  :block="isMobile"
                  large
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      id: 0,
      usuario: {},
      valid: true,
      mostraSenhaNova: false,
      mostraSenhaConfirma: false,
      form: {
        senha_nova: '',
        senha_nova_confirma: ''
      }
    }
  },

  components: {
    Titulo
  },

  methods: {
    submit(){
      if (this.$refs.senhaForm.validate()){
        const payload = {
          id: this.id,
          form: this.form
        }
        this.$store.dispatch('AlteraSenhaOutroUsuario', payload)
        .then(() => {
          this.$router.go(-1)
        })
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.id = Number(this.$route.params.id_usuario)
    this.$store.dispatch('buscaUsuarioPorId', this.id)
    .then(() => {
      this.usuario = this.$store.getters.usuario_alt
    })
  }
}
</script>

<style>

</style>
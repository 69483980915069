<template>
  <v-container fluid fill-height class="pa-0 bg-fundo">
    <DialogSuporte :dialog="dialogSuporte" @fecha-dialog-suporte="dialogSuporte = false" />
    <v-layout align-center>
      <v-flex lg4 sm10 xs12 offset-sm-1 offset-lg-4 :class="isMobile ? 'pa-2' : ''">
        <v-card :class="'pb-12 ' + (isMobile ? 'px-2' : 'px-8')">
          <v-card-title class="d-flex justify-center pa-12">
            <img
              src="../assets/logo_inbra.png"
              alt="Logo INBRA Systems"
              height="50"
            >
          </v-card-title>
          <v-card-text>
            <v-form v-model="formLoginValid" ref="formLogin">
              <v-text-field
                v-model="login.email"
                placeholder="Informe o e-mail"
                :rules="[ validaEmail ]"
                @keypress.enter="loga"
                type="email"
                :autofocus="! isMobile"
              ></v-text-field>
              <v-text-field
                v-model="login.senha"
                placeholder="Informe a senha"
                :append-icon="mostraSenha ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="mostraSenha = !mostraSenha"
                @keypress.enter="loga"
                :type="mostraSenha ? 'text' : 'password'"
                :rules="[ v => !!v || 'Informe a senha' ]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex flex-column">
            <v-btn
              :loading="$store.getters.isLoadingBtnLogin"
              color="primary"
              @click="loga"
              class="pa-6"
              block
            >
              Entrar
            </v-btn>

            <span class="mt-6">
              <a href="#" @click="dialogSuporte = true">Precisa de ajuda?</a>
            </span>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { emailValido } from '../functions'
import DialogSuporte from '@/dialogs/DialogSuporte'

export default {
  data(){
    return {
      isMobile: false,
      formLoginValid: true,
      mostraSenha: false,
      dialogSuporte: false,
      login: {
        email: '',
        senha: ''
      }
    }
  },

  components: {
    DialogSuporte
  },

  methods: {
    validaEmail(value){
      if (value.length == 0){
        return 'Informe o e-mail'
      } else if (! emailValido(value)) {
        return 'E-mail inválido'
      } else {
        return true
      }
    },

    loga(){
      if (this.$refs.formLogin.validate()){
        this.$store.dispatch('loga', this.login)
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
.header-title {
  font-weight: bold;
  font-size: 1.5rem;
}

@media (max-width: 600px){
  .header-title {
    font-size: 1.1rem;
  }
}

.bg-fundo {
  background: #74ebd5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #ACB6E5, #74ebd5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #ACB6E5, #74ebd5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
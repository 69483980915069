<template>
  <v-form
    ref="clienteForm"
    v-model="valid"
    @submit.prevent="submit()"
  >
    <v-row>
      <v-col>
        <v-tabs
          v-model="tab"
          fixed-tabs
          show-arrows
        >
          <v-tab>
            <v-icon left>
              mdi-format-list-bulleted
            </v-icon>
            Geral
          </v-tab>
          <v-tab>
            <v-icon left>
              mdi-database-outline
            </v-icon>
            BD
          </v-tab>
          <v-tab>
            <v-icon left>
              mdi-image-outline
            </v-icon>
            Logo
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-3">
      <v-col>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row dense>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="Nome"
                  v-model="form.nome"
                  :rules="[v => !!v || 'Campo obrigatório']"
                  :autofocus="! isMobile"
                ></v-text-field>
              </v-col>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="CPF/CNPJ"
                  v-model="cpf_cnpj"
                  :rules="[ validaCpfCnpj ]"
                  v-mask="mascaraCpfCnpj"
                  @input="atualizaMascara"
                ></v-text-field>
              </v-col>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="Inscrição estadual"
                  v-model="form.ie"
                  :rules="[v => !!v || 'Campo obrigatório']"
                  v-mask="'NNNNNNNNNNNNNNNNNN'"
                ></v-text-field>
              </v-col>
              <v-col lg="12" cols="12">
                <v-select
                  label="Sistema"
                  :items="sistemas"
                  v-model="form.sistema"
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-select>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row dense>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="Endereço"
                  v-model="form.bd_host"
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="Usuário"
                  v-model="form.bd_usuario"
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="Senha"
                  v-model="form.bd_senha"
                  :rules="[ v => !!v || 'Campo obrigatório' ]"
                  :append-icon="mostraSenha ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="mostraSenha = !mostraSenha"
                  :type="mostraSenha ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="Nome do banco"
                  v-model="form.bd_nome"
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>
              <v-col lg="12" cols="12">
                <v-text-field
                  label="Porta"
                  v-model="form.bd_porta"
                  :rules="[v => !!v || 'Campo obrigatório']"
                  v-mask="'######'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row dense>
              <v-col lg="12" cols="12" class="d-flex justify-center">
                <v-img
                  v-if="url_logo"
                  :src="url_logo"
                  max-width="180"
                  max-height="180"
                  contain
                ></v-img>
              </v-col>
              <v-col lg="12" cols="12" class="d-flex justify-center">
                <v-btn 
                  elevation="0"
                  class="my-4"
                  large
                  @click="seleciona"
                >
                  <v-icon
                    left
                  >
                    mdi-tray-arrow-up
                  </v-icon>
                  Selecionar imagem
                </v-btn>
              </v-col>
              <v-file-input
                ref="input"
                v-model="logomarca_obj"
                accept="image/*"
                class="d-none"
                @change="mostraImagem"
              ></v-file-input>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-end">
        <v-btn
          color="primary"
          elevation="0"
          type="submit"
          large
          :block="isMobile"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { cpfCnpjValido,removeMascara } from '@/functions'

export default {
  data(){
    return {
      isMobile: false,
      tab: null,
      valid: true,
      isAlteracao: false,
      mascaraCpfCnpj: '',
      cpf_cnpj: '',
      mostraSenha: false,
      url_logo: '',
      logomarca_obj: null,
      form: {
        nome: '',
        cpf_cnpj: '',
        ie: '',
        sistema: '',
        bd_host: '',
        bd_usuario: '',
        bd_senha: '',
        bd_nome: '',
        bd_porta: ''
      },
      sistemas: [ 'Mastertransp','Masternfe' ]
    }    
  },

  props: {
    cliente: {
      type: Object,
      default(){
        return {}
      }
    }
  },

  watch: {
    cliente(){
      const { nome,ie,cpf_cnpj,sistema,bd_host,bd_usuario,bd_senha,bd_nome,bd_porta,logomarca } = this.cliente
      const cpfcnpj = this.$options.filters.formataCpfCnpj(cpf_cnpj)
      this.cpf_cnpj = cpfcnpj ?? ''
      this.url_logo = logomarca ?? ''

      this.form = {
        nome: nome ?? '',
        ie: ie ?? '',
        sistema: sistema ?? '',
        bd_host: bd_host ?? '',
        bd_usuario: bd_usuario ?? '',
        bd_senha: bd_senha ?? '',
        bd_nome: bd_nome ?? '',
        bd_porta: bd_porta ?? ''
      }

      this.isAlteracao = true
    }
  },

  methods: {
    validaCpfCnpj(cpf_cnpj){
      return ! cpfCnpjValido(cpf_cnpj) ? 'CPF/CNPJ inválido' : true
    },

    atualizaMascara(){
      const cpfcnpj = removeMascara(this.cpf_cnpj)
      this.mascaraCpfCnpj = cpfcnpj.length == 11 ? '###.###.###-##' : '##.###.###/####-##'
    },

    seleciona(){
      this.$refs.input.$refs.input.click()
    },

    mostraImagem(){
      if (this.logomarca_obj){
        const url = URL.createObjectURL(this.logomarca_obj)
        this.url_logo = url
      }
    },

    submit(){
      if (this.$refs.clienteForm.validate()){
        const { nome,ie,sistema,bd_host,bd_usuario,bd_senha,bd_nome,bd_porta } = this.form

        const formData = new FormData()
        formData.append('nome', nome)
        formData.append('ie', ie)
        formData.append('sistema', sistema)
        formData.append('bd_host', bd_host)
        formData.append('bd_usuario', bd_usuario)
        formData.append('bd_senha', bd_senha)
        formData.append('bd_nome', bd_nome)
        formData.append('bd_porta', bd_porta)
        formData.append('cpf_cnpj', removeMascara(this.cpf_cnpj))
        
        if (this.logomarca_obj){
          formData.append('logomarca', this.logomarca_obj)
        }

        this.$emit('on-submit', formData)
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style>

</style>
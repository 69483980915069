<template>
  <v-menu
    v-model="menuFiltros"
    :close-on-content-click="false"
    :nudge-width="260"
    max-width="400"
    offset-y
    origin="top"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="filtros.length"
        :value="filtros.length"
        color="red"
        overlap
      >
        <v-btn
          elevation="0"
          class="ml-2"
          color="primary"
          height="40"
          v-bind="attrs"
          v-on="on"
          outlined
          :small="isMobile"
        >
          <v-icon small left> mdi-filter-variant </v-icon>
          Filtros
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title>
        <h5>Filtros</h5>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="panels" accordion dense flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="d-flex align-center">
                <v-icon small left>mdi-calendar</v-icon>
                Período
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3">
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menuDtIni"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formataDtIni"
                        label="Data inicial"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filtrosSel.data_inicial"
                      @input="menuDtIni = false"
                      locale="PT-BR"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menuDtFin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formataDtFin"
                        label="Data final"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filtrosSel.data_final"
                      @input="menuDtFin = false"
                      locale="PT-BR"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-divider></v-divider>
            <v-expansion-panel-header>
              <span class="d-flex align-center">
                <v-icon small left> mdi-check-circle-outline </v-icon>
                Situação
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3">
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isSemEventos"
                  label="Sem eventos"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isUtilizada"
                  label="Utilizada"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isCienciaOperacao"
                  label="Ciência da operação"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isDesconhecimento"
                  label="Desconhecimento da operação"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isConfirmacao"
                  label="Confirmação da operação"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                  v-model="filtrosSel.isNaoRealizada"
                  label="Operação não realizada"
                  hide-details
                  dense
                ></v-checkbox>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-divider></v-divider>
            <v-expansion-panel-header>
              <span class="d-flex align-center">
                <v-icon small left> mdi-domain </v-icon>
                CNPJ filial
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3">
              <v-row>
                <v-col>
                  <v-select
                    :hint="
                      filtrosSel.filiais.length == 0 ? 'Todas as filiais' : ''
                    "
                    v-model="filtrosSel.filiais"
                    :items="filiais"
                    item-value="cpfcnpj"
                    item-text="cpfcnpj"
                    label="Filiais"
                    persistent-hint
                    multiple
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-divider></v-divider>
            <v-expansion-panel-header>
              <span class="d-flex align-center">
                <v-icon small left> mdi-currency-usd </v-icon>
                Valor da nota
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3">
              <v-row>
                <v-col>
                  <v-range-slider
                    :hint="
                      filtrosSel.valorNota[0] + filtrosSel.valorNota[1] == 0
                        ? 'Todas os valores'
                        : ''
                    "
                    v-model="filtrosSel.valorNota"
                    persistent-hint
                    max="150000"
                    min="0"
                  ></v-range-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <span
                  >De {{ filtrosSel.valorNota[0] | formataValor }} a
                  {{ filtrosSel.valorNota[1] | formataValor }}</span
                >
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="limpaFiltro" class="text-capitalize" text>
          Limpar
        </v-btn>
        <v-btn
          @click="aplicaFiltros"
          class="text-capitalize"
          color="primary"
          text
        >
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      isMobile: false,
      filiais: [],
      menuFiltros: false,
      filtros: [],
      panels: [],
      menuDtIni: false,
      menuDtFin: false,
      filtrosSel: {
        data_inicial: "",
        data_final: "",
        isSemEventos: false,
        isUtilizada: false,
        isCienciaOperacao: false,
        isDesconhecimento: false,
        isConfirmacao: false,
        isNaoRealizada: false,
        valorNota: [0, 0],
        filiais: [],
      },
    };
  },

  props: {
    callback: {
      type: Function,
    },
  },

  computed: {
    formataDtIni() {
      return this.filtrosSel.data_inicial
        ? moment(this.filtrosSel.data_inicial).format("DD/MM/YYYY")
        : "";
    },

    formataDtFin() {
      return this.filtrosSel.data_final
        ? moment(this.filtrosSel.data_final).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    aplicaFiltros() {
      this.filtros = [];
      const {
        data_inicial,
        data_final,
        isSemEventos,
        isUtilizada,
        isCienciaOperacao,
        isDesconhecimento,
        isConfirmacao,
        isNaoRealizada,
        valorNota,
        filiais,
      } = this.filtrosSel;

      if (data_inicial) {
        this.filtros.push({
          campo: "data_inicial",
          valor: data_inicial,
        });
      }
      if (data_final) {
        this.filtros.push({
          campo: "data_final",
          valor: data_final,
        });
      }
      if (isSemEventos) {
        this.filtros.push({
          campo: "situacao",
          valor: "SEM_EVENTOS",
        });
      }
      if (isUtilizada) {
        this.filtros.push({
          campo: "situacao",
          valor: "UTILIZADA",
        });
      }
      if (isCienciaOperacao) {
        this.filtros.push({
          campo: "situacao",
          valor: "EVE_CIENCIA",
        });
      }
      if (isDesconhecimento) {
        this.filtros.push({
          campo: "situacao",
          valor: "EVE_DESCONHECIMENTO",
        });
      }
      if (isConfirmacao) {
        this.filtros.push({
          campo: "situacao",
          valor: "EVE_CONFIRMACAO",
        });
      }
      if (isNaoRealizada) {
        this.filtros.push({
          campo: "situacao",
          valor: "EVE_NAOREALIZADA",
        });
      }

      const [vlIni, vlFin] = valorNota;
      if (vlIni > 0) {
        this.filtros.push({
          campo: "valor_inicial",
          valor: vlIni,
        });
      }
      if (vlFin > 0) {
        this.filtros.push({
          campo: "valor_final",
          valor: vlFin,
        });
      }
      for (let i in filiais) {
        this.filtros.push({
          campo: "cpfcnpjfilial",
          valor: filiais[i],
        });
      }

      this.fechaFiltro();
      this.retornaFiltro();
    },

    retornaFiltro() {
      if (this.callback) {
        this.callback(this.filtros);
      }
    },

    limpaFiltro() {
      this.setaFiltroInicial();
      this.fechaFiltro();
      this.retornaFiltro();
    },

    setaFiltroInicial() {
      const data = moment().format("YYYY-MM-DD");

      this.filtrosSel = {
        data_inicial: data,
        data_final: data,
        isSemEventos: true,
        isUtilizada: true,
        isCienciaOperacao: true,
        isDesconhecimento: true,
        isConfirmacao: true,
        isNaoRealizada: true,
        valorNota: [0, 0],
        filiais: [],
      };

      this.filtros = [];
      this.filtros.push({
        campo: "data_inicial",
        valor: data,
      });
      this.filtros.push({
        campo: "data_final",
        valor: data,
      });
      this.filtros.push({
        campo: "situacao",
        valor: "SEM_EVENTOS",
      });
      this.filtros.push({
        campo: "situacao",
        valor: "UTILIZADA",
      });
      this.filtros.push({
        campo: "situacao",
        valor: "EVE_CIENCIA",
      });
      this.filtros.push({
        campo: "situacao",
        valor: "EVE_DESCONHECIMENTO",
      });
      this.filtros.push({
        campo: "situacao",
        valor: "EVE_CONFIRMACAO",
      });
      this.filtros.push({
        campo: "situacao",
        valor: "EVE_NAOREALIZADA",
      });
    },

    fechaFiltro() {
      this.panels = [];
      this.menuFiltros = false;
    },

    async buscaFiliais() {
      this.$store.dispatch("buscaFiliais").then(() => {
        const filiais = this.$store.getters.filiais;
        this.filiais = filiais.filter(
          (fil, i) => filiais.findIndex((f) => f.cpfcnpj == fil.cpfcnpj) == i
        );
      });
    },
  },

  created() {
    this.isMobile = window.innerWidth < 800
    this.buscaFiliais().then(() => {
      this.setaFiltroInicial()
      this.retornaFiltro()
    });
  },

  mounted() {
    EventBus.$on("atualiza-cliente", (_ret) => {
      this.buscaFiliais();
    });
  },

  beforeDestroy() {
    EventBus.$off("atualiza-cliente");
  },
};
</script>

<style>
</style>
import axios from '@/plugins/axios'
import { mostraMsgPadrao } from '@/functions'

const state = {
  clientes: [],
  cliente: {},
  tokenCliente: '',
  isLoadingToken: false,
	backups_cliente: [],
	arquivos_backup: []
};

const getters = {
  clientes(state){
    return state.clientes
  },
  cliente(state){
    return state.cliente
  },
  tokenCliente(state){
    return state.tokenCliente
  },
  isLoadingToken(state){
    return state.isLoadingToken
  },
	backups_cliente(state){
		return state.backups_cliente
	},
	arquivos_backup(state){
		return state.arquivos_backup
	}
};

const mutations = {
  clientes(state, clientes){
    state.clientes = clientes
  },
  cliente(state, cliente){
    state.cliente = cliente
  },
  tokenCliente(state, token){
    state.tokenCliente = token
  },
  isLoadingToken(state, loading){
    state.isLoadingToken = loading
  },
	backups_cliente(state, backups){
		state.backups_cliente = backups
	},
	arquivos_backup(state, arquivos){
		state.arquivos_backup = arquivos
	}
};

const actions = {
  async buscaClientes({commit}){
    commit('loading', true)
    await axios.get('/Clientes/')
    .then(res => {
      commit('clientes', res.data.clientes)
    }).finally(() => {
      commit('loading', false)
    })
  },

  async buscaClientePorId({commit}, id){
    commit('loading', true)
    await axios.get(`/Clientes/${id}`)
    .then(res => {
      commit('cliente', res.data.cliente)
    }).finally(() => {
      commit('loading', false)
    })
  },

  async gravaCliente({commit}, cliente){
    commit('loading', true)
    await axios.post('/Clientes/', cliente)
    .then(_res => {
      mostraMsgPadrao('Cliente cadastrado com sucesso')
    }).finally(() => {
      commit('loading', false)
    })
  },

  async alteraCliente({commit}, payload){
    const { id,cliente } = payload
    await axios.put(`/Clientes/${id}`, cliente)
    .then(_res => {
      mostraMsgPadrao('Cliente alterado com sucesso')
    }).finally(() => {
      commit('loading', false)
    })
  },

  async inativaCliente({commit}, id){
    commit('loading', true)
    await axios.put(`/Clientes/Inativa/${id}`)
    .finally(() => {
      commit('loading', false)
    })
  },

  async ativaCliente({commit}, id){
    commit('loading', true)
    await axios.put(`/Clientes/Ativa/${id}`)
    .finally(() => {
      commit('loading', false)
    })
  },

  async buscaTokenCliente({commit}, id){
    commit('tokenCliente', '')
    await axios.get(`/Clientes/Token/${id}`)
    .then(res => {
      if (res.data.cliente){
        commit('tokenCliente', res.data.cliente.token)
      }
    })
  },

  async geraTokenCliente({commit}, id){
    commit('isLoadingToken', true)
    await axios.post(`/Clientes/Token/${id}`)
    .then(res => {
      commit('tokenCliente', res.data.cliente.token)
    }).finally(() => {
      commit('isLoadingToken', false)
    })
  },

	async buscaBackupsCliente({commit}, id){
    commit('loading', true)
    await axios.get(`/Clientes/Backup/${id}`)
    .then(res => {
      commit('backups_cliente', res.data.backups)
    }).finally(() => {
      commit('loading', false)
    })
	},

	async buscaArquivosBackup({commit}, id_backup){
		commit('arquivos_backup', [])
    commit('loading', true)
    await axios.get(`/Clientes/Backup/detalha/${id_backup}`)
    .then(res => {
      commit('arquivos_backup', res.data.backups)
    }).finally(() => {
      commit('loading', false)
    })
	}
};

export default {
  state,
  getters,
  mutations,
  actions
}
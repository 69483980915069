import Vue from 'vue'
import { format } from 'date-fns'
import moment from 'moment'
import ptBR from 'date-fns/locale/pt-BR'

Vue.filter('formataValor', (val) => {
  if (val){
    let numero = val.toFixed(2).split('.');
    numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  } else {
    return 'R$ 0,00'
  }
})

Vue.filter('formataValorSemPrefixo', (val) => {
  if (val){
    let numero = val.toFixed(2).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  } else {
    return '0,00'
  }
})

Vue.filter('formataValorUnitario', (val) => {
  if (val){
    let numero = val.toFixed(3).split('.');
    numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  } else {
    return 'R$ 0,00'
  }
})

Vue.filter('formataValorUnitarioSemPrefixo', (val) => {
  if (val){
    let numero = val.toFixed(3).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  } else {
    return '0,00'
  }
})

Vue.filter('formataPesoQtde', (val) => {
  if (val){
    let numero = val.toFixed(3).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  } else {
    return '0,000'
  }
})

Vue.filter('pegaIniciaisNomeUsuario', (val) => {
  if (val){
    return val.substr(0,2).toUpperCase()
  } else {
    return ''
  }
})

Vue.filter('formataCpfCnpj', (val) => {
  if (val){
    if (val.length > 11){
      return val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
    } else {
      return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4")
    }
  }
})

Vue.filter('formataCep', (val) => {
  if (val && val.length == 8){
    return val.replace(/^([\d]{2})\.?([\d]{3})\-?([\d]{3})/,"$1.$2-$3")
  }
})

Vue.filter('formataData', (val) => {
  if (val){
    return moment(val).format('DD/MM/YYYY')
  }
})

Vue.filter('formataDataHora', (val) => {
  if (val){
    return moment(val).format('DD/MM/YYYY - HH:MM')
  }
})

Vue.filter('formataHora', (val) => {
  if (val){
    return moment(val).format('HH:MM')
  }
})

Vue.filter('formataDataExtenso', (val) => {
  if (val){
    return format(new Date(val), "d 'de' MMMM 'de' yyyy", {
      locale: ptBR
    })
  }
})

Vue.filter('formataNome', (val) => {
  if (val){
    let index = val.search(' ')
    if (index > 0){
      return val.substr(0,index)
    } else {
      return val
    }
  }
})

Vue.filter('formataTelefone', (val) => {
   if (val){
      const numeroLimpo = val.replace(/\D/g, "")
      if (numeroLimpo.length === 10) {
        const regex = /^(\d{2})(\d{4})(\d{4})$/
        const match = numeroLimpo.match(regex)
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`
        }
      } else if (numeroLimpo.length === 11) {
        const regex = /^(\d{2})(\d{5})(\d{4})$/
        const match = numeroLimpo.match(regex)
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`
        }
      }

      return val
   }
})
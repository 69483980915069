<template>
  <v-dialog v-model="dialog" max-width="900px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Detalhes da nota fiscal</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3 text--primary">
        <v-row dense>
          <v-col class="d-flex flex-column" lg="6" cols="12">
            <span class="text--secondary">Chave de acesso</span>
            <span class="font-weight-bold">{{ notafiscal.chave }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">Número</span>
            <span class="font-weight-bold">{{ notafiscal.numero }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">CNPJ filial</span>
            <span class="font-weight-bold">{{ notafiscal.cpfcnpjfilial | formataCpfCnpj }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="6" cols="12">
            <span class="text--secondary">Emitente</span>
            <span class="font-weight-bold">{{ notafiscal.nomeemitente }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">CPF/CNPJ emitente</span>
            <span class="font-weight-bold">{{ notafiscal.cpfcnpjemitente | formataCpfCnpj }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">Cidade emitente</span>
            <span class="font-weight-bold">{{ notafiscal.cidadeemitente }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="6" cols="12">
            <span class="text--secondary">Destinatário</span>
            <span class="font-weight-bold">{{ notafiscal.nomedestinatario }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">CPF/CNPJ destinatário</span>
            <span class="font-weight-bold">{{ notafiscal.cpfcnpjdestinatario | formataCpfCnpj }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">Cidade destinatário</span>
            <span class="font-weight-bold">{{ notafiscal.cidadedestinatario }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="6" cols="12">
            <span class="text--secondary">Data emissão</span>
            <span class="font-weight-bold">{{ notafiscal.dataemissao | formataData }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">Valor total</span>
            <span class="font-weight-bold">{{ notafiscal.valornfe | formataValor }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="3" cols="6">
            <span class="text--secondary">Usuário</span>
            <span class="font-weight-bold">{{ notafiscal.usuariocadastro }}</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <span class="font-weight-bold">Histórico</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <div v-if="isMobile">            
              <v-card
                v-for="evento in eventos"
                :key="evento.id"
                outlined
                class="mb-2"
              >
                <v-card-text>
                  <v-row>
                    <v-col class="d-flex flex-column" cols="12">
                      <span class="text--secondary">Rotina</span>
                      <span class="font-weight-bold" v-if="evento.tipoevento">{{ retornaDescEvento(evento.tipoevento) }}</span>
                      <span class="font-weight-bold" v-else>{{ evento.rotina.descricao }} - N° {{ evento.rotinadocumento }}</span>
                    </v-col>
                    <v-col class="d-flex flex-column" cols="6">
                      <span class="text--secondary">Data</span>
                      <span class="font-weight-bold">{{ evento.dataevento | formataData }}</span>
                    </v-col>
                    <v-col class="d-flex flex-column" cols="6">
                      <span class="text--secondary">Hora</span>
                      <span class="font-weight-bold">{{ evento.horaevento }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <v-simple-table v-else>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Rotina</th>
                    <th class="text-left">Data</th>
                    <th class="text-left">Hora</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="evento in eventos"
                    :key="evento.id"
                  >
                    <td class="truncate" v-if="evento.tipoevento">{{ retornaDescEvento(evento.tipoevento) }}</td>
                    <td class="truncate" v-else>{{ evento.rotina.descricao }} - N° {{ evento.rotinadocumento }}</td>
                    <td class="truncate">{{ evento.dataevento | formataData }}</td>
                    <td class="truncate">{{ evento.horaevento }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="close()"
          :block="isMobile"
          large
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false,
      eventos: [],
      notafiscal: {
        cpfcnpjfilial: '',
        numero: 0,
        dataemissao: '',
        chave: '',
        nomeemitente: '',
        cpfcnpjemitente: '',
        cidadeemitente: '',
        nomedestinatario: '',
        cpfcnpjdestinatario: '',
        cidadedestinatario: '',
        valornfe: 0,
        usuariocadastro: '',
        eventos: []
      }
    }
  },

	props: {
		dialog: Boolean,
    id: Number
	},

  methods: {
    retornaDescEvento(evento){
      if (evento == '210210'){
        return 'EVENTO - CIENCIA DA OPERAÇÃO'
      } else if (evento == '210220'){
        return 'EVENTO - DESCONHECIMENTO DA OPERAÇÃO'
      } else if (evento == '210200'){
        return 'EVENTO - CONFIRMAÇÃO DA OPERAÇÃO'
      } else if (evento == '210240'){
        return 'EVENTO - OPERAÇÃO NÃO REALIZADA'
      } else {
        return 'EVENTO - DESCONHECIDO'
      }
    },

    close(){
      this.notafiscal = {
        cpfcnpjfilial: '',
        numero: 0,
        dataemissao: '',
        chave: '',
        nomeemitente: '',
        cidadeemitente: '',
        nomedestinatario: '',
        cidadedestinatario: '',
        valornfe: 0,
        usuariocadastro: '',
        eventos: []
      }

      this.$emit('fecha-dialog-nota-xml')
    }
  },

  watch: {
    dialog(){
      if (! this.dialog){
        return
      }

      this.$store.dispatch('buscaNotaXmlPorId', this.id)
      .then(() => {
        this.notafiscal = this.$store.getters.nota_xml
        this.eventos = this.notafiscal.eventos.filter(eve => eve.cancelado == 'N')
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800    
  }
}
</script>

<style scoped>
</style>
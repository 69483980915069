import Vue from 'vue'
import { cpf, cnpj } from 'cpf-cnpj-validator'

export function emailValido(email) {
   const regexEmail = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i
   return regexEmail.test(email)
}

export function cpfCnpjValido(cpf_cnpj) {
   return cpf.isValid(cpf_cnpj) || cnpj.isValid(cpf_cnpj)
}

export function removeMascara(val) {
   return val.replace(/\D/gim, '')
}

export function removeMascaraNumero(val) {
   return Number(val.replace('.', '').replace(',', '.'))
}

export function round(value, decimals) {
   if (value < 0) return -round(-value, decimals);
   var p = Math.pow(10, decimals);
   var n = (value * p).toPrecision(15);
   return Math.round(n) / p;
}

export function statusNotaXml(situacao) {
   if (situacao == "EVE_CIENCIA") {
      return {
         label: "Ciência",
         cor: "blue",
      };
   } else if (situacao == "EVE_DESCONHECIMENTO") {
      return {
         label: "Desconhecimento",
         cor: "red",
      };
   } else if (situacao == "EVE_CONFIRMACAO") {
      return {
         label: "Confirmação",
         cor: "green",
      };
   } else if (situacao == "EVE_NAOREALIZADA") {
      return {
         label: "Não realizada",
         cor: "red",
      };
   } else {
      return {
         label: "Sem eventos",
         cor: "yellow",
      };
   }
}

export function statusPedidoVenda(item) {
   const { cancelado, faturado, dataconfirmacao, mapaimpresso } = item

   if (cancelado == 'S') {
      return {
         label: 'Cancelado',
         cor: 'red'
      }
   } else if (faturado == 'S') {
      return {
         label: 'Faturado',
         cor: 'green'
      }
   } else if (dataconfirmacao) {
      return {
         label: 'A faturar',
         cor: 'yellow'
      }
   } else if (mapaimpresso == 'S') {
      return {
         label: 'Impresso',
         cor: 'blue'
      }
   } else {
      return {
         label: 'Digitado',
         cor: 'blue-grey'
      }
   }
}

export function mostraMsgPadrao(text, icon = 'success', timer = 10000) {
   Vue.swal({
      icon,
      text,
      timer,
   })
}

export function randomColor() {
   const cores = ['#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#e74c3c', '#e67e22', '#f1c40f', '#7f8c8d', '#1abc9c']
   const random = Math.floor(Math.random() * 10)
   return cores[random]
}

export async function imageToBase64(url) {
   return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.onloadend = () => resolve(reader.result)
         reader.onerror = reject
         reader.readAsDataURL(blob)
      }))
}

export async function buscaDadosEmpresa(cnpj) {
  try {
    const options = {method: 'GET', mode: 'no-cors', headers: {Accept: 'application/json'}};
    const response = await fetch(`https://receitaws.com.br/v1/cnpj/${cnpj}`, options)
    if (! response.ok){
      console.log(response)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
  }
}
<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Resultado do rastreio da ordem de coleta'"
          :subtitulo="cod_rastreamento"
        />
      </v-col>
    </v-row>

    <v-row v-show="false">
      <v-col>
        <v-alert
          color="info"
          icon="mdi-information-outline"
          text
          dense
        >
          Os horários registrados no sistema não representam os horários reais dos eventos
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          outlined
          :class="'rounded-xl ' + (isMobile ? 'pa-6' : 'pa-12')"
        >
          <v-row>
            <v-col class="d-flex flex-column" cols="12" lg="5">
              <span class="text--secondary">Status</span>
              <h3 v-if="isMobile" class="font-weight-bold">{{ status }}</h3>
              <h2 v-else class="font-weight-bold">{{ status }}</h2>
            </v-col>
            <v-divider vertical v-if="! isMobile"></v-divider>
            <v-col class="d-flex flex-column" cols="12" lg="5">
              <span class="text--secondary">Data</span>
              <h3 v-if="isMobile" class="font-weight-bold">{{ data | formataDataExtenso }}</h3>
              <h2 v-else class="font-weight-bold">{{ data | formataDataExtenso }}</h2>
            </v-col>
            <v-divider vertical v-if="! isMobile"></v-divider>
            <v-col class="d-flex flex-column" cols="12" lg="2">
              <span class="text--secondary">Hora</span>
              <h3 v-if="isMobile" class="font-weight-bold">{{ data | formataHora }}</h3>
              <h2 v-else class="font-weight-bold">{{ data | formataHora }}</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="8" cols="12">
              <v-row>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Remetente</span>
                  <span class="font-weight-bold">{{ ordem_coleta.nome_remetente }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Cidade</span>
                  <span class="font-weight-bold">{{ ordem_coleta.cidade_remetente }}-{{ ordem_coleta.estado_remetente }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Destinatário</span>
                  <span class="font-weight-bold">{{ ordem_coleta.nome_destinatario }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Cidade</span>
                  <span class="font-weight-bold">{{ ordem_coleta.cidade_destinatario }}-{{ ordem_coleta.estado_destinatario }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Transportadora</span>
                  <span class="font-weight-bold">{{ ordem_coleta.cliente.nome }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Notas fiscais</span>
                  <span class="font-weight-bold">{{ notas_fiscais }}</span>
                </v-col>
              </v-row>
            </v-col>
            
            <v-col lg="4" cols="12">
              <span class="text--secondary">Histórico</span>
              <v-timeline
                class="mt-3"
                dense
              >
                <v-timeline-item
                  v-for="(item, index) in historico"
                  :key="item.id"
                  :color="index == 0 ? 'primary' : 'grey'"
                  fill-dot
                  small
                >
                  <strong>{{ item.status }}</strong>
                  <div class="text-caption">
                    {{ item.descricao }}
                  </div>
                  <div class="text-caption">
                    {{ item.created_at | formataDataExtenso }}, {{ item.created_at | formataHora }}
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      cod_rastreamento: '',
      ordem_coleta: {},
      status: '',
      data: '',
      notas_fiscais: '',
      historico: []
    }
  },

  components: {
    Titulo
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.cod_rastreamento = this.$route.params.cod_rastreamento
    this.$store.dispatch('buscaOrdemColeta', this.cod_rastreamento)
    .then(() => {
      this.ordem_coleta = this.$store.getters.ordem_coleta
      const { historico,notas_fiscais } = this.ordem_coleta
      
      if (historico){
        this.historico = historico.sort((a,b) => (a.id < b.id) ? 1 : -1)
        this.status = this.historico[0].status
        this.data = this.historico[0].created_at
      }

      if (notas_fiscais){
        this.notas_fiscais = notas_fiscais.reduce((acum, atual, index) =>  acum + (index > 0 ? '/' : '') + atual.nota_fiscal, '')
      }
    }).catch(() => {
      this.$router.go(-1)
    })
  }
}
</script>

<style>

</style>
<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Alteração de usuário'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">
        <v-card
          :class="'rounded-xl ' + (isMobile ? 'pa-3' : 'pa-6')"
          outlined
        >
          <v-card-text>
            <FormUsuario
              :usuario="usuario"
              @on-submit="grava"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'
import FormUsuario from './FormUsuario'

export default {
  data(){
    return {
      isMobile: false,
      id: 0,
      usuario: {}
    }
  },

  components: {
    Titulo,
    FormUsuario
  },

  methods: {
    grava(form){
      const payload = {
        id: this.id,
        usuario: form
      }
      this.$store.dispatch('alteraUsuario', payload)
      .then(() => {
        this.$router.go(-1)
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.id = Number(this.$route.params.id_usuario)
    this.$store.dispatch('buscaUsuarioPorId', this.id)
    .then(() => {
      this.usuario = this.$store.getters.usuario_alt
    })
  }
}
</script>

<style>

</style>
<template>
  <div>
    <DialogNovoCliente :dialog="dialogcliente" :cliente="clientealtera" @retorna-cliente="retornaCliente" @fecha-dialog-cliente="fechaalteracliente" />
    <v-form
      ref="usuarioForm"
      v-model="valid"
      @submit.prevent="submit()"
    >
      <v-row>
        <v-col>
          <v-tabs
            v-model="tab"
            fixed-tabs
          >
            <v-tab>
              <v-icon left>
                mdi-format-list-bulleted
              </v-icon>
              Geral
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-domain
              </v-icon>
              Clientes
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="mt-3">
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row dense>
                <v-col lg="12" cols="12">
                  <v-text-field
                    label="Nome"
                    v-model="form.nome"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    :autofocus="! isMobile"
                  ></v-text-field>
                </v-col>
                <v-col lg="12" cols="12">
                  <v-select
                    label="Tipo"
                    :items="tipos"
                    v-model="form.tipo"
                    :rules="[v => !!v || 'Campo obrigatório']"
                  ></v-select>
                </v-col>
                <v-col lg="12" cols="12">
                  <v-text-field
                    label="E-mail"
                    v-model="form.email"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    :disabled="isAlteracao && usuarioConfirmado"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row dense>
                <v-col>
                  <v-btn
                    elevation="0"
                    :disabled="form.tipo == 'administrador'"
                    @click="dialogcliente = true"
                    :block="isMobile"
                  >
                    <v-icon
                      left
                    >
                      mdi-plus
                    </v-icon>
                    Adicionar cliente
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col v-if="isMobile">
                  <v-card
                    v-for="cliente in clientes"
                    :key="cliente.id"
                    outlined
                    class="mb-2"
                  >
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="4">
                          <span class="font-weight-bold">Cliente</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ cliente.nome }}</span>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="4">
                          <span class="font-weight-bold">Código</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ cliente.id_usu_sistema }}</span>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <v-btn
                            elevation="0"
                            @click="alteraCliente(cliente.id_cliente)"
                            block
                          >
                            <v-icon
                              left
                            >
                              mdi-pencil-outline
                            </v-icon>
                            Alterar
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            elevation="0"
                            @click="deletaCliente(cliente)"
                            block
                          >
                            <v-icon
                              left
                            >
                              mdi-delete-outline
                            </v-icon>
                            Deletar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col v-else>
                  <v-simple-table v-if="clientes.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="width: 60%;">Cliente</th>
                          <th class="text-left">Cód.</th>
                          <th class="text-left"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="cliente in clientes"
                          :key="cliente.id"
                        >
                          <td class="truncate">{{ cliente.nome }}</td>
                          <td>{{ cliente.id_usu_sistema }}</td>
                          <td class="d-flex">
                            <v-btn
                              @click="alteraCliente(cliente.id_cliente)"
                              icon
                            >
                              <v-icon small>mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn
                              @click="deletaCliente(cliente)"
                              icon
                            >
                              <v-icon small>mdi-delete-outline</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end">
          <v-btn
            color="primary"
            elevation="0"
            type="submit"
            large
            :block="isMobile"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Vue from 'vue'
import DialogNovoCliente from './DialogNovoCliente'

export default {
  data(){
    return {
      valid: true,
      isAlteracao: false,
      usuarioConfirmado: false,
      tipos: ['administrador','gerente','operador'],
      clientes: [],
      tab: null,
      form: {
        nome: '',
        tipo: '',
        email: '',
        clientes: []
      },
      dialogcliente: false,
      clientealtera: null,
      isMobile: false
    }    
  },

  components: {
    DialogNovoCliente
  },

  props: {
    usuario: {
      type: Object,
      default(){
        return {}
      }
    }
  },

  watch: {
    usuario(){
      const { nome,tipo,email,clientes,confirmado } = this.usuario
      this.form = {
        nome: nome ?? '',
        tipo: tipo ?? '',
        email: email ?? '',
      }
      
      for (let index in clientes){
        const cliente = clientes[index]
        this.clientes.push({
          id_cliente: cliente.id_cliente,
          nome: cliente.cliente.nome,
          id_usu_sistema: cliente.id_usu_sistema,
        })
      }

      this.isAlteracao = true
      this.usuarioConfirmado = confirmado
    }
  },

  methods: {
    alteraCliente(id){
      const index = this.clientes.findIndex(cli => cli.id_cliente == id)
      if (index == -1){
        return
      }

      this.clientealtera = this.clientes[index]
      this.dialogcliente = true
    },

    retornaCliente(cliente){
      const { id_cliente } = cliente
      const index = this.clientes.findIndex(cli => cli.id_cliente == id_cliente)

      if (this.clientealtera){
        if (index == -1){
          return
        }

        Vue.set(this.clientes, index, cliente)
      } else {
        if (index > -1){
          return
        }

        this.clientes.push(cliente)
      }
    },

    fechaalteracliente(){
      this.clientealtera = null
      this.dialogcliente = false
    },

    deletaCliente(cliente){
      const { id_cliente,nome } = cliente
      Vue.swal({
        title: 'Remover cliente',
        text: `Confirma a exclusão do cliente ${nome}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed){
          this.clientes = this.clientes.filter(cli => cli.id_cliente !== id_cliente)
        }
      })
    },

    submit(){
      if (this.$refs.usuarioForm.validate()){
        this.form.clientes = this.clientes
        if (this.usuarioConfirmado){
          delete this.form.email
        }

        this.$emit('on-submit', this.form)
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
.truncate {
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
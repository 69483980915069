<template>
  <v-dialog v-model="dialog" max-width="650px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Pedido de liberação #{{ pedido.id }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary pt-3">
        <v-row dense>
          <v-col class="d-flex flex-column" lg="12" cols="12">
            <span class="text--secondary">Status</span>
            <span>
              <v-chip
                label
                small
                class="white--text font-weight-bold"
                :color="verificaCorStatus(pedido.status)"
              >
                {{ verificaTextoStatus(pedido.status) }}
              </v-chip>
            </span>
          </v-col>
          <v-col class="d-flex flex-column" lg="12" cols="12">
            <span class="text--secondary">Data pedido</span>
            <span class="font-weight-bold">{{ pedido.datapedido | formataData }} - {{ pedido.horapedido }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="12" cols="12" v-if="pedido.usuario">
            <span class="text--secondary">Solicitante</span>
            <span class="font-weight-bold">{{ pedido.usuario.nome }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="12" cols="12" v-if="pedido.rotina">
            <span class="text--secondary">Rotina</span>
            <span class="font-weight-bold">{{ pedido.codrotina }}-{{ pedido.rotina.descricao }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="12" cols="12" v-if="pedido.acao">
            <span class="text--secondary">Ação</span>
            <span class="font-weight-bold">{{ pedido.codacao }}-{{ pedido.acao.descricao }}</span>
          </v-col>
          <v-col class="d-flex flex-column" lg="12" cols="12">
            <span class="text--secondary">Histórico</span>
            <span class="font-weight-bold">{{ pedido.historico }}</span>
          </v-col>
        </v-row>

        <v-row dense v-if="pedido.status == 'P'">
          <v-col>
            <v-btn
              @click="liberaNega(true)"
              color="green white--text"
              elevation="0"
              large
              block
            >
              <v-icon
                left
              >
                mdi-thumb-up
              </v-icon>

              Liberar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="red white--text"
              elevation="0"
              @click="liberaNega(false)"
              large
              block
            >
              <v-icon
                left
              >
                mdi-thumb-down
              </v-icon>

              Negar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="close()"
          :block="isMobile"
          large
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default {
  data(){
    return {
      isMobile: false,
    }
  },

	props: {
		dialog: Boolean,
    pedido: {
      type: Object,
      default(){
        return {}
      }
    }
	},

  methods: {
    verificaTextoStatus(status){
      if (status == 'L'){
        return 'Liberado'
      } else if (status == 'N'){
        return 'Negado'
      } else if (status == 'C'){
        return 'Cancelado'
      } else {
        return 'Pendente'
      }
    },

    verificaCorStatus(status){
      if (status == 'L'){
        return 'green'
      } else if (status == 'C' || status == 'N'){
        return 'red'
      } else {
        return 'yellow'
      }
    },

    liberaNega(liberar){
      Vue.swal({
        title: liberar ? 'Liberar pedido' : 'Negar pedido',
        text: `Confirma a ${liberar ? 'liberação' : 'negação'} do pedido?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Sim, ${liberar ? 'liberar' : 'negar'}`,
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed){
          this.$store.dispatch(liberar ? 'liberaPedidoLiberacao' : 'negaPedidoLiberacao', this.pedido.id)
          .then(() => {
            this.$emit('atualiza-pedido-liberacao')
            this.close()
          })
        }
      })
    },

    close(){
      this.$emit('fecha-dialog-pedido-liberacao')
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
</style>
<template>
  <div>
    <DialogRotinasFavoritas :dialog="dialogFavoritos" @fecha-dialog-rotinas-favoritas="dialogFavoritos = false" />
    <DialogDetalhesPedido :dialog="dialogPedido" :pedido="pedidoLiberacao" @fecha-dialog-pedido-liberacao="dialogPedido = false" />
    <DialogUsuariosIndicador :dialog="dialogUsuIndicador" :indicador="indicador" @fecha-dialog-usuarios-indicador="dialogUsuIndicador = false" />
    <v-row>
      <v-col>
        <div class="header rounded-lg pt-6 pb-16 px-8">
          <h2 class="white--text">{{ saudacao }}, <span class="capitalized">{{ $store.getters.usuario.nome | formataNome }}</span></h2>
        </div>
      </v-col>
    </v-row>

    <v-row dense class="px-3" style="margin-top: -60px">
      <v-col
        lg="4"
        cols="12"
        v-for="item in $store.getters.itens_menu.filter(item => item.label !== 'Dashboard')"
        :key="item.id"
      >
        <v-card
          outlined
          :to="item.to"
        >
          <v-card-title>
            <span class="font-weight-bold fonte-titulo text-truncate">{{ item.label }}</span>
            <v-spacer></v-spacer>
            <v-icon>
              {{ item.icone }}
            </v-icon>
          </v-card-title>
        </v-card>
      </v-col>

      <!--
      <v-col lg="6" cols="12">
        <v-row dense>
          <v-col>
            <v-card outlined v-if="indicadorLiberado(1)">
              <v-card-title >
                <span class="font-weight-bold fonte-titulo">Fluxo de caixa</span>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="! isOperador && temUsuariosOperador"
                  @click="indicador = { id: 1, descricao: 'Fluxo de caixa' }, dialogUsuIndicador = true"
                  icon
                  small
                >
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div>
                  <v-row dense>
                    <v-col class="text-center">
                      <img
                        src="../assets/lupa.png"
                        alt="Imagem lupa"
                        height="65"
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="text-center">
                      <span class="text--secondary mt-6">
                        Nenhum fluxo de caixa encontrado
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-card outlined v-if="indicadorLiberado(2)">
              <v-card-title>
                <span class="font-weight-bold fonte-titulo">Pedidos de liberações</span>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="! isOperador && temUsuariosOperador"
                  @click="indicador = { id: 2, descricao: 'Pedidos de liberações' }, dialogUsuIndicador = true"
                  icon
                  small
                >
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-list two-line v-if="$store.getters.pedidos_liberacoes_pendentes.length > 0" dense>
                  <v-list-item
                    v-for="pedido in $store.getters.pedidos_liberacoes_pendentes"
                    :key="pedido.id"
                    @click="abrePedido(pedido)"
                  >
                    <v-list-item-avatar
                      :color="randomColor()"
                      class="rounded"
                    >
                      <span class="white--text font-weight-bold">{{ pedido.usuario.nome | pegaIniciaisNomeUsuario }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ pedido.rotina.descricao }} - {{ pedido.acao.descricao }}</v-list-item-title>
                      <v-list-item-subtitle>{{ pedido.historico }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div v-else>
                  <v-row dense>
                    <v-col class="text-center">
                      <img
                        src="../assets/curtida.png"
                        alt="Imagem curtida"
                        height="65"
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="text-center">
                      <span class="text--secondary mt-6">
                        Nenhuma solicitação pendente
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-card outlined v-if="indicadorLiberado(3)">
              <v-card-title>
                <span class="font-weight-bold fonte-titulo">NFs emitidas contra o CNPJ hoje</span>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="! isOperador && temUsuariosOperador"
                  @click="indicador = { id: 3, descricao: 'NFs emitidas contra o CNPJ hoje' }, dialogUsuIndicador = true"
                  icon
                  small
                >
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div v-if="$store.getters.notas_xmls.length > 0">
                  <div v-if="isMobile">
                    <v-card
                      v-for="nota in $store.getters.notas_xmls"
                      :key="nota.id"
                      outlined
                      class="mb-2"
                    >
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="4">
                            <span class="font-weight-bold">Situação</span>
                          </v-col>
                          <v-col class="text-end">
                            <v-chip
                              label
                              small
                              class="white--text font-weight-bold"
                              :color="statusNotaXml(nota.situacao).cor"
                            >
                              {{ statusNotaXml(nota.situacao).label }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="4">
                            <span class="font-weight-bold">Número</span>
                          </v-col>
                          <v-col class="text-end">
                            <span>{{ nota.numero }}</span>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="4">
                            <span class="font-weight-bold">Emitente</span>
                          </v-col>
                          <v-col cols="8" class="text-end text-truncate">
                            <span>{{ nota.nomeemitente }}</span>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="4">
                            <span class="font-weight-bold">Valor NFe</span>
                          </v-col>
                          <v-col class="text-end">
                            <span>{{ nota.valornfe | formataValor }}</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>

                  <v-simple-table v-else>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Situação</th>
                          <th class="text-left">Número</th>
                          <th class="text-left">Emitente</th>
                          <th class="text-left">Valor NFe</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="nota in $store.getters.notas_xmls"
                          :key="nota.id"
                        >
                          <td>
                            <v-chip
                              label
                              small
                              class="white--text font-weight-bold"
                              :color="statusNotaXml(nota.situacao).cor"
                            >
                              {{ statusNotaXml(nota.situacao).label }}
                            </v-chip>
                          </td>
                          <td>{{ nota.numero }}</td>
                          <td>{{ nota.nomeemitente }}</td>
                          <td class="text-truncate">{{ nota.valornfe | formataValor }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>

                <div v-else>
                  <v-row dense>
                    <v-col class="text-center">
                      <img
                        src="../assets/lupa.png"
                        alt="Imagem lupa"
                        height="65"
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="text-center">
                      <span class="text--secondary mt-6">
                        Nenhuma nota encontrada para exibir
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col lg="6" cols="12">
        <v-row dense>
          <v-col
            lg="6"
            cols="12"
            v-for="thisIndicador in indicadores"
            :key="thisIndicador.id"
          >
            <v-card outlined v-if="indicadorLiberado(thisIndicador.id)">
              <v-card-title>
                <span class="font-weight-bold fonte-titulo text-truncate">{{ thisIndicador.descricao }}</span>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col class="text-end">
                    <span class="font-weight-bold" style="font-size: 1.5rem">{{ thisIndicador.valor }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      -->
    </v-row>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { randomColor } from '@/functions'
import DialogRotinasFavoritas from '@/dialogs/DialogRotinasFavoritas'
import DialogDetalhesPedido from '@/pages/pedidos_liberacoes/DialogDetalhes'
import DialogUsuariosIndicador from '@/dialogs/DialogUsuariosIndicador'
import { statusNotaXml } from "@/functions"

export default {
  data(){
    return {
      temUsuariosOperador: false,
      randomColor,
      saudacao: '',
      isMobile: false,
      dialogFavoritos: false,
      dialogPedido: false,
      statusNotaXml,
      dialogUsuIndicador: false,
      indicador: {},
      periodos: ['Hoje','Semana','15 dias','Mês'],
      pedidoLiberacao: {},
      indicadores: []
    }
  },

  methods: {
    carregaIndicadores(){
      this.$store.dispatch('buscaIndicadoresSistema')
      .then(() => {
        const indicadores = this.$store.getters.indicadores_sistema
        this.indicadores = indicadores.map((ind) => {
          const { id,descricao } = ind
          return {
            id,
            descricao,
            valor: 0
          }
        })
      })

      this.$store.dispatch('buscaUsuariosCliente')
      .then(() =>{
        const usuarios = this.$store.getters.usuarios_cliente
        this.temUsuariosOperador = usuarios.findIndex(usu => usu.tipo == 'operador') > -1
      })

      this.buscaPedidosPendentes()
      this.buscaNotasXmlHoje()
    },

    buscaPedidosPendentes(){
      if (this.indicadorLiberado(2)){
        this.$store.dispatch('buscaPedidosLiberacoesPendentes')
      }
    },

    buscaNotasXmlHoje(){
      if (this.indicadorLiberado(3)){
        this.$store.dispatch('buscaNotasXmlHoje')
      }
    },

    indicadorLiberado(id_indicador){
      if (! this.isOperador) return true
      return this.indicadores.includes(id_indicador)
    },

    abrePedido(pedido){
      this.dialogPedido = true
      this.pedidoLiberacao = pedido
    },
  },

  components: {
    DialogRotinasFavoritas,
    DialogDetalhesPedido,
    DialogUsuariosIndicador
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.isOperador = this.$store.getters.usuario.tipo == 'operador'
    let now = new Date
    if (now.getHours() >= 4 && now.getHours() < 12){
      this.saudacao += 'Bom dia'
    } else if (now.getHours() >= 12 && now.getHours() < 18){
      this.saudacao += 'Boa tarde'
    } else {
      this.saudacao += 'Boa noite'
    }

    if (this.isOperador){
      this.$store.dispatch('buscaIndicadoresUsuario')
      .then(() => {
        this.indicadores = this.$store.getters.usuario_indicadores
        this.carregaIndicadores()
      })
    } else {
      this.carregaIndicadores()
    }
  },

  mounted(){
    EventBus.$on('atualiza-cliente', (_ret) => {
      this.carregaIndicadores()
    })
  },

  beforeDestroy(){
    EventBus.$off('atualiza-cliente')
  }
}
</script>

<style scoped>
.header {
  background: #3494E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3494E6, #EC6EAD);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3494E6, #EC6EAD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.fonte-titulo {
  font-size: 0.9rem;
}
</style>
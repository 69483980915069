import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '@/store'
import Login from '../pages/Login'
import Dashboard from '@/pages/Dashboard'
import Home from '@/pages/Home'
import MeuPerfil from '@/pages/usuarios/MeuPerfil'
import AlteraMinhaSenha from '@/pages/usuarios/AlteraMinhaSenha'
import Usuarios from '@/pages/Usuarios'
import NovoUsuario from '@/pages/usuarios/NovoUsuario'
import AlteraUsuario from '@/pages/usuarios/AlteraUsuario'
import AlteraSenha from '@/pages/usuarios/AlteraSenha'
import Clientes from '@/pages/Clientes'
import NovoCliente from '@/pages/clientes/NovoCliente'
import AlteraCliente from '@/pages/clientes/AlteraCliente'
import TokenCliente from '@/pages/clientes/TokenCliente'
import BackupsCliente from '@/pages/clientes/BackupsCliente'
import Rastreia from '@/pages/ordens_coletas/Rastreia'
import RastreiaResult from '@/pages/ordens_coletas/RastreiaResult'
import PedidosLiberacoes from '@/pages/pedidos_liberacoes/PedidosLiberacoes'
import NotasXml from '@/pages/notas_xml/NotasXml'
import GerenciamentoRiscos from '@/pages/ger_riscos/GerenciamentoRiscos'
import GerenciamentoRiscoDetalhe from '@/pages/ger_riscos/Detalhes'
import ConfirmarFreteEmpresa from '@/pages/ordens_cargas/ConfirmarFreteEmpresa'
import ConfirmarFreteEmpresaDetalhe from '@/pages/ordens_cargas/DetalhesConfirma'
import GerenciaContasReceber from '@/pages/contas_receber/GerenciaContasReceber'
import PedidosVendas from '@/pages/pedidos_vendas/PedidosVendas'
import NovoPedidoVenda from '@/pages/pedidos_vendas/NovoPedidoVenda'
import AlteraPedidoVenda from '@/pages/pedidos_vendas/AlteraPedidoVenda'
import ValidaUsuario from '@/pages/ValidaUsuario'
import Pagina404 from '@/pages/404'
import { mostraMsgPadrao } from '@/functions'

function verificaMasterTransp(next){
  const { sistema } = store.getters.cliente_usuario

  if (sistema !== 'Mastertransp'){
    mostraMsgPadrao('Rotina liberada apenas para o sistema MasterTransp','error')
  } else {
    next()
  }
}

function verificaMasterNFe(next){
  const { sistema } = store.getters.cliente_usuario

  if (sistema !== 'Masternfe'){
    mostraMsgPadrao('Rotina liberada apenas para o sistema MasterNFe','error')
  } else {
    next()
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/valida-usuario/:uuid',
    name: 'ValidaUsuario',
    component: ValidaUsuario
  },
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/meu-perfil',
        name: 'MeuPerfil',
        component: MeuPerfil
      },
      {
        path: '/meu-perfil/altera-senha',
        name: 'AlteraMinhaSenha',
        component: AlteraMinhaSenha
      },
      {
        path: '/usuarios',
        name: 'Usuarios',
        component: Usuarios
      },
      {
        path: '/usuarios/novo',
        name: 'NovoUsuario',
        component: NovoUsuario
      },
      {
        path: '/usuarios/altera/:id_usuario',
        name: 'AlteraUsuario',
        component: AlteraUsuario
      },
      {
        path: '/usuarios/alterasenha/:id_usuario',
        name: 'AlteraSenha',
        component: AlteraSenha
      },
      {
        path: '/clientes',
        name: 'Clientes',
        component: Clientes
      },
      {
        path: '/clientes/novo',
        name: 'NovoCliente',
        component: NovoCliente
      },
      {
        path: '/clientes/altera/:id_cliente',
        name: 'AlteraCliente',
        component: AlteraCliente
      },
      {
        path: '/clientes/token/:id_cliente',
        name: 'TokenCliente',
        component: TokenCliente
      },
      {
        path: '/clientes/backups/:id_cliente',
        name: 'BackupsCliente',
        component: BackupsCliente
      },
      {
        path: '/rastreia_oc',
        name: 'Rastreia',
        component: Rastreia,
        beforeEnter(_to,_from,next){
          verificaMasterTransp(next)
        }
      },
      {
        path: '/rastreia_oc/:cod_rastreamento',
        name: 'RastreiaResult',
        component: RastreiaResult,
        beforeEnter(_to,_from,next){
          verificaMasterTransp(next)
        }
      },
      {
        path: '/pedidos_liberacoes',
        name: 'PedidosLiberacoes',
        component: PedidosLiberacoes
      },
      {
        path: '/notas_xmls',
        name: 'NotasXml',
        component: NotasXml
      },
      {
        path: '/gerenciamento_riscos',
        name: 'GerenciamentoRiscos',
        component: GerenciamentoRiscos,
        beforeEnter(_to,_from,next){
          verificaMasterTransp(next)
        }
      },
      {
        path: '/gerenciamento_riscos/:id_risco',
        name: 'GerenciamentoRiscoDetalhe',
        component: GerenciamentoRiscoDetalhe,
        beforeEnter(_to,_from,next){
          verificaMasterTransp(next)
        }
      },
      {
        path: '/confirmar_frete_oc',
        name: 'ConfirmarFreteEmpresa',
        component: ConfirmarFreteEmpresa,
        beforeEnter(_to,_from,next){
          verificaMasterTransp(next)
        }
      },
      {
        path: '/confirmar_frete_oc/:id',
        name: 'ConfirmarFreteEmpresaDetalhe',
        component: ConfirmarFreteEmpresaDetalhe,
        beforeEnter(_to,_from,next){
          verificaMasterTransp(next)
        }
      },
      {
        path: '/gerencia_contas_receber',
        name: 'GerenciaContasReceber',
        component: GerenciaContasReceber,
        beforeEnter(_to,_from,next){
          verificaMasterTransp(next)
        }
      },
      {
        path: '/pedidos_vendas',
        name: 'PedidosVendas',
        component: PedidosVendas,
        beforeEnter(_to,_from,next){
          verificaMasterNFe(next)
        }
      },
      {
        path: '/pedidos_vendas/novo',
        name: 'NovoPedidoVenda',
        component: NovoPedidoVenda,
        beforeEnter(_to,_from,next){
          verificaMasterNFe(next)
        }
      },
      {
        path: '/pedidos_vendas/altera/:id_pedido',
        name: 'AlteraPedidoVenda',
        component: AlteraPedidoVenda,
        beforeEnter(_to,_from,next){
          verificaMasterNFe(next)
        }
      }
    ],
    beforeEnter(_to, _from, next){
      const token = store.getters.token
      if (! token){
        const route = {
          name: 'Login'
        }

        next(route)

        mostraMsgPadrao('Informe suas credenciais para continuar', 'error')
      } else {
        store.dispatch('setaToKenHeaders', 'Bearer '+token)
				if (store.getters.cliente_usuario){
					store.dispatch('atualizaClienteUsuario', store.getters.cliente_usuario)
				}
        next()
      }
    },
  },
  {
    path: '*',
    name: '404',
    component: Pagina404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <v-dialog v-model="dialog" max-width="700px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">{{ titulo }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col>
              <v-text-field
                label="E-mail"
                v-model="email"
                :rules="[ validaEmail ]"
                :autofocus="! isMobile"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              @click="close()"
              block
              large
            >
              Fechar
            </v-btn>
          </v-col>
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              color="primary"
              type="submit"
              @click="salva()"
              block
              large
            >
              Adicionar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { emailValido,mostraMsgPadrao } from '@/functions'

export default {
  data(){
    return {
      isMobile: false,
      email: '',
      valid: null,
    }
  },

	props: {
		dialog: Boolean,
      titulo: {
         type: String,
         default: 'Envio de e-mail'
      },
      callback: {
         type: Function
      }
	},

  methods: {
    validaEmail(value){
      if (! value) return 'Informe o e-mail'
      if (value.length == 0){
        return 'Informe o e-mail'
      } else if (! emailValido(value)) {
        return 'E-mail inválido'
      } else {
        return true
      }
    },

    close(){
      this.$refs.form.reset()
      this.$emit('fecha-dialog-email')
    },

    salva(){
      if (! this.email){
        return mostraMsgPadrao('Pelo menos um e-mail deve ser informado','error')
      }

      const email_ret = [this.email]

      if (this.callback){
        this.callback(email_ret)
      }

      this.close()
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800    
  }
}
</script>

<style scoped>
</style>
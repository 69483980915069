<template>
  <v-dialog v-model="dialog" max-width="550px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-text :class="(isMobile ? 'pa-1' : 'pa-6') + ' mt-6 d-flex flex-column'">
        <h1 class="text-center">Precisa de ajuda?</h1>
        <p class="text-center mt-2">Entre em contato conosco</p>

        <v-list>
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary">
                mdi-phone-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a href="tel:3433114754">(34) 3311-4754</a>
                </v-list-item-title>
              <v-list-item-subtitle>Horário comercial</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary">
                mdi-whatsapp
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a href="https://api.whatsapp.com/send?phone=3492286395" target="_blank">(34) 9 9228-6395</a>
              </v-list-item-title>
              <v-list-item-subtitle>Plantão</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">
                mdi-email-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a href="mailto:inbrasystems@inbrasystems.com.br">inbrasystems@inbrasystems.com.br</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="close()"
          large
          :block="isMobile"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false
    }
  },

	props: {
		dialog: Boolean
	},

  methods: {
    close(){
      this.$emit('fecha-dialog-suporte')
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
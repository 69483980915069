import router from '@/router'
import axios from '../plugins/axios'
import { token } from '../config/autenticacao'
import { mostraMsgPadrao } from '@/functions'

const state = {
  loading: false,
  isLoadingBtnLogin: false,
  token: null,
  confirmacaoUsuario: {}
};

const getters = {
  loading(state){
    return state.loading
  },
  isLoadingBtnLogin(state){
    return state.isLoadingBtnLogin
  },
  token(state){
    return state.token
  },
  confirmacaoUsuario(state){
    return state.confirmacaoUsuario
  }
};

const mutations = {
  loading(state, loading){
    state.loading = loading
  },
  isLoadingBtnLogin(state, loading){
    state.isLoadingBtnLogin = loading
  },
  setaToken(state, token){
    state.token = token
  },
  confirmacaoUsuario(state, confirmacao){
    state.confirmacaoUsuario = confirmacao
  }
};

const actions = {
  async loga({commit,dispatch}, data){
    commit('isLoadingBtnLogin', true)
    dispatch('setaToKenHeaders', null)
    await axios.post('/Autenticacao', data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      let { usuario,clientes,configuracoes } = res.data
      commit('usuario', usuario)
      
      let cliente = {}
      if (clientes){
        cliente = clientes[0]
      }
      
      commit('clientes_usuario', clientes)
      commit('configuracoes', configuracoes)

      const token = res.data.token
      commit('setaToken', token)
      dispatch('setaToKenHeaders', 'Bearer '+token)
      dispatch('atualizaClienteUsuario', cliente)
      dispatch('atualizaItensMenu')
      router.push({
        name: 'Home'
      })
    }).finally(() => {
      commit('isLoadingBtnLogin', false)
      commit('loading', false)
    })
  },

  async validaUsuario({commit}, payload){
    const { id_usuario,body } = payload
    commit('loading', true)
    await axios.put(`/Autenticacao/ConfirmaUsuario/${id_usuario}`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      mostraMsgPadrao('Senha cadastrada com suceso')
    }).finally(() => {
      commit('loading', false)
    })
  },

  async reenviaEmailUsuario({commit}, id_usuario){
    commit('loading', true)
    await axios.post(`/Autenticacao/ReenviaEmail/${id_usuario}`, '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      mostraMsgPadrao('E-mail reenviado com sucesso')
    }).finally(() => {
      commit('loading', false)
    })
  },

  async buscaConfirmacaoUsuario({commit}, uuid){
    commit('confirmacaoUsuario', {})
    commit('loading', true)
    return await axios.get(`/Autenticacao/BuscaConfirmacaoUsuario/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      commit('confirmacaoUsuario', res.data.confirmacao)
    }).finally(() => {
      commit('loading', false)
    })
  },

  logout({commit,dispatch}){
    commit('usuario', null)
    commit('clientes_usuario', null)
    commit('cliente_usuario', null)
    commit('setaToken', null)
    dispatch('setaToKenHeaders', null)
    dispatch('atualizaClienteUsuario', null)
    router.push({
      name: 'Login'
    })
  },

  setaToKenHeaders(_state, token){
    axios.defaults.headers.get['Authorization'] = token
    axios.defaults.headers.post['Authorization'] = token
    axios.defaults.headers.put['Authorization'] = token
    axios.defaults.headers.patch['Authorization'] = token
    axios.defaults.headers.delete['Authorization'] = token
  }  
};

export default {
  state,
  getters,
  mutations,
  actions
}
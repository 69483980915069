<template>
  <v-dialog v-model="dialog" max-width="500px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Rotinas favoritas</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary">
        <v-row>
          <v-col>
            <v-list>
              <v-list-item
                v-for="item in this.$store.getters.itens_menu"
                :key="item.id"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icone }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="d-flex justify-space-between">
                  {{ item.label }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="close()"
          :block="isMobile"
          large
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false,
      rotinas: [],
    }
  },

	props: {
		dialog: Boolean
	},

  methods: {
    close(){
      this.$emit('fecha-dialog-rotinas-favoritas')
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
import axios from 'axios'
import router from '@/router'
import { mostraMsgPadrao } from '@/functions'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000
})

instance.interceptors.response.use((response) => {
  if (response.data.mensagem){
    mostraMsgPadrao(response.data.mensagem)
  }

  return response
}, function error(error){
  let codigoerro = 400
  if (error.response.data){
    const erro = error.response.data.mensagem
    codigoerro = error.response.status
    mostraMsgPadrao(erro,'error')
  } else {
    mostraMsgPadrao('Não foi possível processar a requisição','error')
  }

  if (codigoerro == 403){
    router.push({
      name: 'Home',
      replace: true
    })
  } else if (codigoerro == 404){
    router.go(-1)
  }
  
  return Promise.reject(error)
})

export default instance

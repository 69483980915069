<template>
  <v-dialog v-model="dialog" max-width="600px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">{{ isAlteracao ? 'Alteração de' : 'Novo' }} cliente do usuário</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col>
              <v-autocomplete
                v-model="form.id_cliente"
                label="Cliente"
                :items="clientes"
                item-text="nome"
                item-value="id"
                :rules="[v => !!v || 'Campo obrigatório']"
                no-data-text="Nenhum cliente encontrado"
                :disabled="isAlteracao"
                auto-select-first
                :autofocus="! isAlteracao && ! isMobile"
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col lg="6" cols="12">
              <v-text-field
                v-model="form.id_usu_sistema"
                label="Código no sistema"
                :rules="[v => !!v || 'Campo obrigatório']"
                v-mask="'###'"
                :autofocus="isAlteracao && ! isMobile"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row dense justify="end">
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              @click="close()"
              block
              large
            >
              Fechar
            </v-btn>
          </v-col>
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              color="primary"
              type="submit"
              @click="salva()"
              block
              large
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false,
      isAlteracao: false,
      clientes: [],
      valid: null,
      form: {
        id_cliente: 0,
        id_usu_sistema: null
      },
      isMobile: false,
    }
  },

	props: {
		dialog: Boolean,
    cliente: {
      type: Object,
      default(){
        return null
      }
    }
	},

  methods: {
    close(){
      this.$refs.form.reset()
      this.$emit('fecha-dialog-cliente')
    },

    salva(){
      if (! this.$refs.form.validate()){
        return
      }

      const { id_cliente,id_usu_sistema } = this.form
      const index = this.clientes.findIndex(cli => cli.id == id_cliente)
      if (index > -1){
        const { nome } = this.clientes[index]
        this.$emit('retorna-cliente', {
          id_cliente,
          id_usu_sistema,
          nome
        })
      }

      this.close()
    }
  },

  watch: {
    dialog(){
      if (! this.dialog){
        return
      }

      this.isAlteracao = this.cliente ? true : false

      this.$store.dispatch('buscaClientes')
      .then(() => {
        this.clientes = this.$store.getters.clientes
        if (this.isAlteracao){
          const { id_cliente,id_usu_sistema } = this.cliente
          this.form = {
            id_cliente,
            id_usu_sistema
          }
        }
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
</style>
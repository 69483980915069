<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Gerenciamento de risco - ID '+id_risco"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Dados do motorista</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          class="pa-3 rounded-xl"
          outlined
        >
          <v-card-text class="text--primary">
            <v-row dense>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">Nome</span>
                <span class="font-weight-bold text-truncate">{{ ordem_risco.motorista.nome }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">CPF</span>
                <span class="font-weight-bold">{{ ordem_risco.motorista.cpf | formataCpfCnpj }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">CNH</span>
                <span class="font-weight-bold">{{ ordem_risco.motorista.cnh }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">Vencimento</span>
                <span class="font-weight-bold">{{ ordem_risco.motorista.cnh_vencimento | formataData }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">Endereço</span>
                <span class="font-weight-bold text-truncate">{{ ordem_risco.motorista.endereco }}, {{ ordem_risco.motorista.numero }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">Bairro</span>
                <span class="font-weight-bold text-truncate">{{ ordem_risco.motorista.bairro }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">Cidade</span>
                <span class="font-weight-bold text-truncate">{{ ordem_risco.motorista.cidade }}-{{ ordem_risco.motorista.estado }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="4" cols="12">
                <span class="text--secondary">CEP</span>
                <span class="font-weight-bold">{{ ordem_risco.motorista.cep | formataCep }}</span>
              </v-col>
              <v-col class="d-flex flex-column">
                <span class="text--secondary">Indicação</span>
                <span class="font-weight-bold text-truncate">{{ ordem_risco.motorista.refindicacao }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Referências do motorista</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          class="pa-3 rounded-xl"
          outlined
        >
          <v-card-text class="text--primary">
            <div v-if="ordem_risco.motorista.referencias.length == 0">
              <span>Nenhuma referência cadastrada para este motorista</span>
            </div>
            <div v-else>
              <div v-if="isMobile">
                <v-card
                  v-for="ref in ordem_risco.motorista.referencias"
                  :key="ref.id"
                  outlined
                  class="mb-2"
                >
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="4">
                        <span class="font-weight-bold">Contato</span>
                      </v-col>
                      <v-col class="text-end">
                        <span>{{ ref.contato }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <span class="font-weight-bold">Telefone</span>
                      </v-col>
                      <v-col class="text-end">
                        <span>{{ ref.telefone | formataTelefone }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <span class="font-weight-bold">Cidade</span>
                      </v-col>
                      <v-col class="text-end">
                        <span>{{ ref.cidade }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <span class="font-weight-bold">Mês/ano</span>
                      </v-col>
                      <v-col class="text-end">
                        <span>{{ ref.mes }}/{{ ref.ano }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="ref.empresa">
                      <v-col cols="4">
                        <span class="font-weight-bold">Empresa</span>
                      </v-col>
                      <v-col class="text-end">
                        <span>{{ ref.empresa }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
              <v-simple-table v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Empresa</th>
                      <th class="text-left">Telefone</th>
                      <th class="text-left">Cidade</th>
                      <th class="text-left">Mês/ano</th>
                      <th class="text-left">Contato</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="ref in ordem_risco.motorista.referencias"
                      :key="ref.id"
                    >
                      <td class="truncate">{{ ref.empresa }}</td>
                      <td class="truncate">{{ ref.telefone | formataTelefone }}</td>
                      <td class="truncate">{{ ref.cidade }}</td>
                      <td class="truncate">{{ ref.mes }}/{{ ref.ano }}</td>
                      <td class="truncate">{{ ref.contato }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Ordens de cargas</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion v-model="panel">
          <v-expansion-panel
            v-for="ordem in ordem_risco.ordens"
            :key="ordem.ordem"
          >
            <v-expansion-panel-header class="font-weight-bold">Nº {{ ordem.ordem }}</v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3">
              <v-row dense>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Filial</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.filial }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Peso total (TN)</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.pesototal / 1000 | formataPesoQtde }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Remetente</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.nomremetente }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Cidade remetente</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.cidremetente }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Destinatário</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.nomdestinatario }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Cidade destinatário</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.ciddestinatario }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Placa cavalo</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.cavalo }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Cidade cavalo</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.cidcavalo }} - {{ ordem.ordem_carga.estcavalo }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12" v-if="ordem.ordem_carga.carreta.trim()">
                  <span class="text--secondary">Placa 1° carreta</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.carreta }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12" v-if="ordem.ordem_carga.carreta.trim()">
                  <span class="text--secondary">Cidade 1° carreta</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.cidcarreta }} - {{ ordem.ordem_carga.estcarreta }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12" v-if="ordem.ordem_carga.bitrem.trim()">
                  <span class="text--secondary">Placa 2º carreta</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.bitrem }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12" v-if="ordem.ordem_carga.bitrem.trim()">
                  <span class="text--secondary">Cidade 2° carreta</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.cidbitrem }} - {{ ordem.ordem_carga.estbitrem }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12" v-if="ordem.ordem_carga.tritrem.trim()">
                  <span class="text--secondary">Placa 3º carreta</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.tritrem }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12" v-if="ordem.ordem_carga.tritrem.trim()">
                  <span class="text--secondary">Cidade 3° carreta</span>
                  <span class="font-weight-bold text-truncate">{{ ordem.ordem_carga.cidtritrem }} - {{ ordem.ordem_carga.esttritrem }}</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="8" cols="12">
                  <span class="text--secondary">Frete empresa</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.valoremptn | formataValor }}/TN</span>
                </v-col>
                <v-col class="d-flex flex-column" lg="4" cols="12">
                  <span class="text--secondary">Frete motorista</span>
                  <span class="font-weight-bold">{{ ordem.ordem_carga.valormottn | formataValor }}/TN</span>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-end">
        <v-btn
          elevation="0"
          color="success"
          @click="libera"
          :block="isMobile"
        >
          Liberaração temporária
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      id_risco: 0,
      panel: 0,
      ordem_risco: {
        motorista: {
          nome: '',
          cpf: '',
          cnh: '',
          cnh_vencimento: '',
          refindicacao: '',
          referencias: []
        },
        ordem_carga: {
          filial: 0,
          cavalo: '',
          carreta: '',
          bitrem: '',
          tritrem: '',
          nomremetente: '',
          nomdestinatario: '',
          valoremptn: 0,
          pesototal: 0,
          nomproprietario: '',
          cidcavalo: '',
          estcavalo: '',
          cidcarreta: '',
          estcarreta: '',
          cidbitrem: '',
          estbitrem: '',
          cidtritrem: '',
          esttritrem: '',
          cidremetente: '',
          ciddestinatario: '',
          valormottn: 0,
          usuariocadastro: ''
        }
      }
    }
  },

  components: {
    Titulo
  },

  methods: {
    libera(){
      Vue.swal({
        title: 'Liberar carregamento',
        text: 'Confirma a liberação temporária do carregamento? Essa operação não pode ser desfeita',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim, liberar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed){
          this.$store.dispatch('liberaRiscoTemporario', this.id_risco)
          .then(() => {
            this.$router.go(-1)
          })
        }
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.id_risco = Number(this.$route.params.id_risco)
    this.$store.dispatch('buscaGerenciamentoRiscoPorId', this.id_risco)
    .then(() => {
      this.ordem_risco = this.$store.getters.ordem_risco
    })
  }
}
</script>

<style>

</style>
<template>
  <v-app>
    <Loading />
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data(){
    return {}
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  color: #252525;
}

.v-btn, .v-tab {
  text-transform: capitalize !important;
}

tr .actionButtons {
  visibility: hidden;
}

tr:hover .actionButtons {
  visibility: visible;
}

.grid-truncate {
  max-width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalized {
  text-transform: capitalize;
}

.cursor-pointer:hover {
  cursor: pointer;
}
</style>

<template>
  <v-dialog v-model="dialog" max-width="600px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Duplicata {{ form.parcela }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-layout class="mt-3">
            <v-flex>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formataData"
                    label="Data vencimento"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    hide-details
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.datavencimento"
                  @input="menu = false"
                  locale="PT-BR"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <VuetifyMoney
                v-model="form.valor"
                label="Valor"
                :rules="[v => !!v || 'Campo obrigatório']"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="close()"
          large
        >
          Fechar
        </v-btn>
        <v-btn
          elevation="0"
          color="primary"
          type="submit"
          @click="salva()"
          large
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import moment from 'moment'
import VuetifyMoney from '@/components/geral/VuetifyMoney'

export default {
  data(){
    return {
      isMobile: false,
      valid: null,
      menu: false,
      form: {
        parcela: null,
        datavencimento: null,
        valor: null
      },
    }
  },

	props: {
		dialog: Boolean,
    duplicata: Object
	},

  methods: {
    close(){
      this.$emit('fecha-dialog-duplicata')
    },

    salva(){
      if (! this.$refs.form.validate()) return
      const duplicata = { ...this.form }
      this.$emit('on-submit', duplicata)
      this.close()
    },
  },

  computed: {
    formataData(){
      return this.form.datavencimento ? moment(this.form.datavencimento).format('DD/MM/YYYY') : ''
    },
  },

  components: {
    VuetifyMoney
  },

  watch: {
    dialog(){
      if (! this.dialog || ! this.duplicata){
        return
      }

      const { parcela,datavencimento,valor } = this.duplicata
      this.form = {
        parcela,
        datavencimento,
        valor
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style>

</style>
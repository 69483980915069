<template>
  <v-dialog v-model="dialog" max-width="600px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Arquivos do backup ({{ arquivos.length }})</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary">
        <v-row class="mt-3">
          <v-col>
						<v-text-field
							label="Pesquisar arquivo"
							v-model="search"
							single-line
							hide-details
							outlined
							dense
							class="text-white rounded-r-0"
						></v-text-field>
					</v-col>
        </v-row>
				<v-row>
					<v-col>
						<div class="d-flex flex-column arquivos">
							<v-card
								v-for="arquivo in arquivos"
								:key="arquivo.id"
								outlined
							>
								<v-list two-line>
									<v-list-item>
										<v-list-item-avatar>
											<v-icon
												:class="arquivo.tipo_arquivo == 'BANCO' ? 'red' : 'blue'"
												color="white"
											>
												{{ arquivo.tipo_arquivo == 'BANCO' ? 'mdi-database-outline' : 'mdi-xml' }}
											</v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title class="text-truncate">
												<small class="font-weight-bold">{{ arquivo.nome_arquivo }}</small>
											</v-list-item-title>
											<v-list-item-subtitle class="d-flex justify-space-between">
												<span>
													{{ arquivo.tipo_arquivo == 'BANCO' ? 'Banco de dados' : 'Arquivo XML' }}
												</span>
												<v-btn
													:href="arquivo.detalhe_arquivo.url"
													target="_blank"
													small
													text
												>
													<v-icon
														left
													>
														mdi-cloud-download-outline
													</v-icon>
													Baixar
												</v-btn>
											</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card>
						</div>
					</v-col>
				</v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="close()"
          :block="isMobile"
          large
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false,
      arquivos: [],
			search: '',
			listaArquivos: []
    }
  },

	props: {
		dialog: Boolean,
		id: Number
	},

  methods: {
    close(){
      this.$emit('fecha-dialog-arquivos-backup')
    },

		filtra(){
			if (this.search){
				this.arquivos = this.listaArquivos.filter(file => file.nome_arquivo.includes(this.search))
			} else {
				this.arquivos = this.listaArquivos
			}
		}
  },

	watch: {
		dialog(){
			if (! this.dialog) return
	
			this.listaArquivos = []
			this.$store.dispatch('buscaArquivosBackup', this.id)
			.then(() => {
			  this.listaArquivos = this.$store.getters.arquivos_backup
				this.filtra()
			})
		},

		search(){
			this.filtra()
		}
	},

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
.arquivos {
	gap: 0.5rem
}
</style>
<template>
  <div>
    <DialogAlteraAvatar :dialog="dialogAvatar" @fecha-dialog-avatar="dialogAvatar = false" />
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Meu perfil'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6" cols="12">
        <v-card
          :class="'rounded-xl ' + (isMobile ? 'pa-6' : 'pa-12')"
          outlined
        >
          <v-layout justify-center>
            <v-avatar
              size="120"
              class="cursor-pointer"
              @click="dialogAvatar = true"
              v-if="isMobile"
              rounded
            >
              <v-img
                :src="$store.getters.usuario.avatar"
                :alt="perfil.nome"
              ></v-img>
            </v-avatar>

            <v-tooltip top v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="120"
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  class="cursor-pointer"
                  @click="dialogAvatar = true"
                >
                  <v-img
                    :src="$store.getters.usuario.avatar"
                    :alt="perfil.nome"
                  ></v-img>
                </v-avatar>
              </template>
              <span>Clique para atualizar sua foto de perfil</span>
            </v-tooltip>
          </v-layout>
          <v-layout>
            <v-text-field
              label="Nome"
              v-model="perfil.nome"
              disabled
            ></v-text-field>
          </v-layout>
          <v-layout>
            <v-text-field
              label="E-mail"
              v-model="perfil.email"
              disabled
            ></v-text-field>
          </v-layout>
          <v-layout>
            <v-select
              label="Tipo"
              :items="tipos"
              v-model="perfil.tipo"
              disabled
            ></v-select>
          </v-layout>
          <v-layout>
            <v-btn
              elevation="0"
              :to="{ name: 'AlteraMinhaSenha' }"
              :block="isMobile"
              large
            >
              <v-icon
                left
              >
                mdi-pencil-outline
              </v-icon>
              Alterar senha
            </v-btn>
          </v-layout>          
        </v-card>
      </v-col>

      <v-col lg="6" cols="12">
        <v-layout v-if="perfil.tipo !== 'administrador'">
          <h4>Empresas</h4>
        </v-layout>
        <v-layout v-if="perfil.tipo !== 'administrador'" class="my-3">
          <v-card
            class="rounded-xl px-4"
            width="100%"
            outlined
          >
            <v-list two-line>
              <v-list-item
                v-for="cli in clientes"
                :key="cli.id"
              >
                <v-list-item-avatar size="42">
                  <v-img
                    :src="cli.logomarca"
                    contain
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ cli.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ cli.cpf_cnpj | formataCpfCnpj }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-layout>

        <span v-show="false">
         <v-layout>
            <h4>Configurações</h4>
         </v-layout>

         <v-layout class="mt-3">
            <v-card
               class="rounded-xl pa-4"
               width="100%"
               outlined
            >
               <v-layout justify-space-between align-center>
               <span>Manter o menu de navegação sempre aberto</span>
               <v-switch
                  @change="alteraConfiguracoesUsuario"
               ></v-switch>
               </v-layout>
            </v-card>
         </v-layout>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'
import DialogAlteraAvatar from './DialogAlteraAvatar'

export default {
  data(){
    return {
      tipos: ['administrador','operador'],
      perfil: {},
      clientes: [],
      dialogAvatar: false,
      configuracoes: this.$store.getters.configuracoes,
      isMobile: false
    }
  },

  components: {
    DialogAlteraAvatar,
    Titulo
  },

  methods: {
    alteraConfiguracoesUsuario(){
      const payload = {
        id: this.$store.getters.usuario.id,
        configuracoes: this.configuracoes
      }

      this.$store.dispatch('alteraConfiguracoesUsuario',payload)
    }
  },

  created(){
    this.perfil = this.$store.getters.usuario
    this.clientes = this.$store.getters.clientes_usuario
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style>

</style>
<template>
  <div>
    <DialogPegaEmail :dialog="dialogEmail" :titulo="'E-mail do pedido de venda'" :callback="processaEmail" @fecha-dialog-email="dialogEmail = false" />
    <DialogConsultaPedido :dialog="dialog" :pedido="pedido_venda" @fecha-dialog-pedido-venda="dialog = false" />
    <v-row>
      <v-col class="d-flex">
        <Titulo
          :titulo="'Pedidos de vendas'"
        />

        <v-spacer></v-spacer>

        <v-btn
          v-if="isMobile"
          elevation="0"
          color="primary"
          @click="carrega"
          icon
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          elevation="0"
          color="primary"
          height="40"
          @click="carrega"
        >
          <v-icon
            small
            left
          >
            mdi-refresh
          </v-icon>
          Atualizar
        </v-btn>

        <v-btn
          v-if="isMobile"
          color="primary"
          fixed
          large
          bottom
          right	
          fab
          :to="{ name: 'NovoPedidoVenda' }"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-else
          elevation="0"
          class="ml-2"
          color="primary"
          :to="{ name: 'NovoPedidoVenda' }"
          height="40"
        >
          Novo pedido
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')">
          <v-data-table
              :headers="headers"
              :items="$store.getters.pedidos_vendas"
              no-data-text="Nenhum pedido encontrado"
              no-results-text="Nenhum pedido encontrado"
              loading-text="Carregando os pedidos de vendas"
              :footer-props="{itemsPerPageText: 'Itens por página', pageText: '{0}-{1} de {2}', itemsPerPageOptions: [10,20,50,100]}"
              @current-items="(pedidos) => qtdpedidos = pedidos.length"
              @dblclick:row.prevent="($event, row) => altera(row.item)"
              :server-items-length="$store.getters.totalPedidos"
              :options.sync="options"
              :items-per-page=15
              :disable-sort="isMobile"
              :class="isMobile ? '' : 'grid-truncate'"
              :height="alturaMaxima"
              fixed-header
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                label
                small
                class="white--text font-weight-bold"
                :color="statusPedidoVenda(item).cor"
              >
                {{ statusPedidoVenda(item).label }}
              </v-chip>
            </template>

            <template v-slot:[`item.dataemissao`]="{ item }">
              {{ item.dataemissao | formataData }}
            </template>

            <template v-slot:[`item.cliente`]="{ item }">
              {{ item.cliente.codigo }}-{{ item.cliente.nome }}
            </template>

            <template v-slot:[`item.vltotalnfe`]="{ item }">
              {{ item.vltotalnfe | formataValor }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-bottom-sheet v-if="isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list rounded="t-xl">
                  <v-layout justify-center class="py-5">
                    <span class="font-weight-bold">Pedido de venda {{ item.id }}</span>
                  </v-layout>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-bottom-sheet>

              <div class="actionButtons" v-else>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    dense
                  >
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { statusPedidoVenda,mostraMsgPadrao } from '@/functions'
import DialogConsultaPedido from './DialogConsultaPedido'
import DialogPegaEmail from '@/dialogs/DialogPegaEmail'
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      headers: [
        {text: 'Status', align: 'start', sortable: false, value: 'status'},
				{text: 'ID', align: 'start', sortable: true, value: 'id'},
				{text: 'Cliente', align: 'start', sortable: true, value: 'cliente'},
				{text: 'Data emissão', align: 'start', sortable: true, value: 'dataemissao'},
				{text: 'Valor pedido', align: 'start', sortable: true, value: 'vltotalnfe'},
				{text: '', value: 'actions', sortable: false}
      ],
      items: [
        {id: 1, text: 'Alterar', icon: 'mdi-pencil-outline', click: this.altera},
        {id: 2, text: 'Consultar', icon: 'mdi-eye-outline', click: this.consulta},
        {id: 3, text: 'Imprimir', icon: 'mdi-printer-outline', click: this.imprime},
        {id: 4, text: 'Enviar por e-mail', icon: 'mdi-email-outline', click: this.email}
      ],
      qtdpedidos: 0,
      options: {},
      dialog: false,
      dialogEmail: false,
      idEmail: 0,
      statusPedidoVenda,
      pedido_venda: {
        id: 0,
        vendedor: {
          nome: ''
        },
        naturezaoperacao: {
          descricao: ''
        },
        cliente: {
          codigo: 0,
          nome: '',
          endereco: '',
          numero: '',
          bairro: '',
          cep: '',
          cidade: '',
          estado: '',
          cpf_cnpj: '',
          ie: ''
        },
        produtos: [],
        duplicatas: []
      },
    }
  },
  
  methods: {
    carrega(){
      const { page,itemsPerPage } = this.options

      const params = new URLSearchParams()
      params.append('pagina', page ?? 1)
      params.append('limite', itemsPerPage ?? 10)
      params.append('filial', this.$store.getters.filial.codigo)

      this.$store.dispatch('buscaPedidosVendas', params)
    },

    imprime(item){
      const { id } = item
      this.$store.dispatch('geraPdfPedidoVenda', id)
    },

    async email(item){
      const { id } = item
      this.idEmail = id
      this.dialogEmail = true
    },

    processaEmail(emails){
      const payload = {
        id: this.idEmail,
        emails
      }

      this.$store.dispatch('emailPedidoVenda', payload)
    },

    consulta(item){
      const { id } = item

      this.$store.dispatch('buscaPedidoVendaPorId', id)
      .then(() => {
        this.pedido_venda = this.$store.getters.pedido_venda
        this.dialog = true
      })
    },

    altera(item){
      const { id,cancelado,faturado,dataconfirmacao,mapaimpresso } = item

      if (cancelado == 'S') return mostraMsgPadrao('Pedido já cancelado, não pode ser alterado', 'error')
      if (faturado == 'S') return mostraMsgPadrao('Pedido já faturado, não pode ser alterado', 'error')
      if (dataconfirmacao) return mostraMsgPadrao('Pedido já confirmado, não pode ser alterado', 'error')
      if (mapaimpresso == 'S') return mostraMsgPadrao('Pedido já impresso, não pode ser alterado', 'error')

      this.$router.push({
        name: 'AlteraPedidoVenda',
        params: {
          id_pedido: id
        }
      })
    },
  },

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 250
    },
  },

  components: {
    DialogConsultaPedido,
    DialogPegaEmail,
    Titulo
  },
  
  watch: {
    options(){
      this.carrega()
    },
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.carrega()
    EventBus.$on('atualiza-filial', (_ret) => {
        this.carrega()
    })
  },

  beforeDestroy(){
    EventBus.$off('atualiza-filial')
  }
}
</script>

<style>
</style>
<template>
  <div>
    <v-row>
      <v-col class="d-flex">
        <Titulo
          :titulo="'Ordens de cargas pendentes de confirmação'"
        />

        <v-spacer></v-spacer>

        <v-text-field
          v-if="mostraPesquisa"
          v-model="search"
          label="Pesquisar OC"
          single-line
          hide-details
          outlined
          dense
          class="text-white rounded-r-0"
        ></v-text-field>
        <v-btn
          elevation="0"
          color="primary"
          height="40"
          :class="mostraPesquisa ? 'rounded-l-0' : ''"
          @click="mostraPesquisa = ! mostraPesquisa"
          v-if="! isMobile"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          elevation="0"
          class="ml-2"
          color="primary"
          height="40"
          @click="carrega"
        >
          <v-icon
            small
            left
          >
            mdi-refresh
          </v-icon>
          Atualizar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')">
          <v-data-table
            :headers="headers"
            :items="$store.getters.ordens_confirma"
            no-data-text="Nenhuma ordem de carga encontrada"
            no-results-text="Nenhuma ordem de carga encontrada"
            loading-text="Carregando as ordens pendentes de confirmação"
            :footer-props="{itemsPerPageText: 'Itens por página', pageText: '{0}-{1} de {2}', itemsPerPageAllText: 'Tudo'}"
            @current-items="(ordens) => qtdordens = ordens.length"
            class="grid-truncate"
            :items-per-page=15
            :search="search"
            @dblclick:row.prevent="($event, row) => detalhes(row.item)"
            :disable-sort="isMobile"
            :height="alturaMaxima"
            fixed-header
          >
            <template v-slot:[`item.valorempresa`]="{ item }">
              {{ item.valorempresa | formataValor }}{{ item.tipovalor == 'T' ? '/TN' : '' }}
            </template>

            <template v-slot:[`item.ordem_carga.pesototal`]="{ item }">
              {{ item.ordem_carga.pesototal | formataPesoQtde }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-bottom-sheet v-if="isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list rounded="t-xl">
                  <v-row justify-center class="py-5">
                    <v-col class="text-center">
                      <span class="font-weight-bold">Ordem N. {{ item.ordem }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-bottom-sheet>

              <div v-else class="actionButtons">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    dense
                  >
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>          
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      headers: [
        {text: 'ID', align: 'start', sortable: true, value: 'id'},
        {text: 'Ordem', align: 'start', sortable: true, value: 'ordem'},
        {text: 'Motorista', align: 'start', sortable: true, value: 'ordem_carga.nommotorista'},
        {text: 'Cavalo', align: 'start', sortable: true, value: 'ordem_carga.cavalo'},
        {text: 'Peso', align: 'start', sortable: true, value: 'ordem_carga.pesototal'},
        {text: 'Frete', align: 'start', sortable: true, value: 'valorempresa'},
				{text: '', value: 'actions', sortable: false}
      ],
      items: [
        {id: 1, text: 'Detalhes', icon: 'mdi-eye-outline', click: this.detalhes}
      ],
      mostraPesquisa: false,
      search: '',
      qtdordens: 0,
    }
  },

  components: {
    Titulo
  },

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 250
    },
  },

  methods: {
    detalhes(ordem){
      const { id } = ordem

      this.$router.push({
        name: 'ConfirmarFreteEmpresaDetalhe',
        params: {
          id
        }
      })
    },

    carrega(){
      this.$store.dispatch('buscaOrdensConfirmacao')
    },
  },
  
  created(){
    this.isMobile = window.innerWidth < 800
    this.carrega()
  },

  mounted(){
    EventBus.$on('atualiza-cliente', (_ret) => {
      this.carrega()
    })
  },

  beforeDestroy(){
    EventBus.$off('atualiza-cliente')
  }
}
</script>

<style>

</style>
<template>
  <v-dialog v-model="dialog" max-width="850px" persistent scrollable :fullscreen="isMobile">
    <v-card class="rounded-xl">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Novo cliente</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col lg="4" cols="12">
              <v-text-field
                label="CPF/CNPJ"
                v-model="form.cpf_cnpj"
                :rules="[ validaCpfCnpj ]"
                v-mask="'##############'"
                hide-details
                @blur="buscaCnpj"
                :autofocus="! isMobile"
              ></v-text-field>
            </v-col>
            <v-col lg="4" cols="12">
              <v-text-field
                label="IE"
                v-model="form.ie"
                :rules="[v => !!v || 'Campo obrigatório']"
                v-mask="'NNNNNNNNNNNNNNNNNN'"
                @keyup="form.ie = form.ie.toUpperCase()"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="12" cols="12">
              <v-text-field
                label="Nome"
                v-model="form.nome"
                :rules="[v => !!v || 'Campo obrigatório']"
                @keyup="form.nome = form.nome.toUpperCase()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col lg="2" cols="12">
              <v-text-field
                label="CEP"
                v-model="form.cep"
                :rules="[v => !!v || 'Campo obrigatório']"
                v-mask="'########'"
                @blur="buscaCep"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col lg="8" cols="12">
              <v-text-field
                label="Endereço"
                v-model="form.endereco"
                :rules="[v => !!v || 'Campo obrigatório']"
                @keyup="form.endereco = form.endereco.toUpperCase()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col lg="2" cols="12">
              <v-text-field
                label="Número"
                v-model="form.numero"
                :rules="[v => !!v || 'Campo obrigatório']"
                @keyup="form.numero = form.numero.toUpperCase()"
                v-mask="'NNNNNNNNNN'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col lg="6" cols="12">
              <v-text-field
                label="Bairro"
                v-model="form.bairro"
                :rules="[v => !!v || 'Campo obrigatório']"
                @keyup="form.bairro = form.bairro.toUpperCase()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col lg="6" cols="12">
              <v-text-field
                label="Cidade"
                v-model="form.cidade"
                :rules="[v => !!v || 'Campo obrigatório']"
                @keyup="form.cidade = form.cidade.toUpperCase()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col lg="4" cols="12">
              <v-select
                label="Estado"
                v-model="form.estado"
                :items="estados"
                item-text="label"
                item-value="value"
                :rules="[v => !!v || 'Campo obrigatório']"
                hide-details
              ></v-select>
            </v-col>
            <v-col lg="4" cols="12">
              <v-text-field
                label="Telefone"
                v-model="form.telefone"
                :rules="[v => !!v || 'Campo obrigatório']"
                v-mask="'###############'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col lg="4" cols="12">
              <v-select
                label="Regime tributário"
                v-model="form.regimetributario"
                :items="regimes"
                item-text="label"
                item-value="value"
                :rules="[v => !!v || 'Campo obrigatório']"
                hide-details
              ></v-select>
            </v-col>
            <v-col lg="4" cols="12">
              <v-select
                label="Ramo de atividade"
                v-model="form.ramoatividade"
                :items="ramos"
                item-text="label"
                item-value="value"
                :rules="[v => !!v || 'Campo obrigatório']"
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col lg="2" cols="6">
            <v-btn
              elevation="0"
              @click="close()"
              block
              large
            >
              Fechar
            </v-btn>
          </v-col>
          <v-col lg="2" cols="6">
            <v-btn
              elevation="0"
              color="primary"
              type="submit"
              @click="salva()"
              block
              large
            >
              Cadastrar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import estados from '@/constantes/estados'
import regimes from '@/constantes/regimes'
import ramos from '@/constantes/ramos'
import { cpfCnpjValido,buscaDadosEmpresa } from '@/functions'

export default {
  data(){
    return {
      isMobile: false,
      estados,
      regimes,
      ramos,
      valid: null,
      form: {
        tipo: 'C',
        nome: '',
        cep: '',
        endereco: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        cpf_cnpj: '',
        ie: '',
        telefone: '',
        regimetributario: '',
        ramoatividade: '',
        pais: 'BRASIL'
      }
    }
  },

	props: {
		dialog: Boolean,
	},

  methods: {
    validaCpfCnpj(cpf_cnpj){
      return ! cpfCnpjValido(cpf_cnpj) ? 'CPF/CNPJ inválido' : true
    },
    
    async buscaCnpj(){
      const cnpj = this.form.cpf_cnpj
      if (! cnpj || cnpj.length !== 14 || ! cpfCnpjValido(cnpj) || this.form.nome) return

      this.$store.dispatch('BuscaDadosEmpresaWS', cnpj)
      .then(() => {
        const empresa = this.$store.getters.empresa_ws
        const { nome,endereco,numero,bairro,cidade,estado,cep,telefones } = empresa

        this.form.nome = nome
        this.form.endereco = endereco
        this.form.numero = numero
        this.form.cep = cep
        this.form.bairro = bairro
        this.form.cidade = cidade
        this.form.estado = estado

        if (telefones.length > 0){
          this.form.telefone = telefones[0]
        }
      })
    },

    buscaCep(){
      const cep = this.form.cep
      if (! cep || cep.length !== 8) return

      fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(res => res.json())
      .then(res => {
        const { logradouro,localidade,uf,bairro } = res

        this.form.endereco = logradouro.toUpperCase()
        this.form.bairro = bairro.toUpperCase()
        this.form.cidade = localidade.toUpperCase()
        this.form.estado = uf.toUpperCase()
      })
    },

    close(){
      this.$refs.form.reset()
      this.$emit('fecha-dialog-empresa')
    },

    salva(){
      if (! this.$refs.form.validate()){
        return
      }

      this.$store.dispatch('gravaEmpresa', this.form)
      .then(() => {
        this.close()
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
</style>
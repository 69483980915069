<template>
  <v-dialog v-model="dialog" max-width="800px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">{{ isAlteracao ? 'Alteração' : 'Inclusão' }} de produto</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="form.produto"
                label="Produto"
                :items="$store.getters.produtos"
                :item-text="formataSelectProduto"
                :rules="[v => !!v || 'Selecione o produto']"
                no-data-text="Nenhum produto encontrado"
                :search-input.sync="searchProduto"
                :loading="$store.getters.loading_produtos"
                @change="verificaProduto"
								@click:clear="form.foto = null"
                :disabled="isAlteracao"
                return-object
                auto-select-first
                cache-items
                clearable
                hide-details
                :autofocus="! isMobile"
              >
								<template v-slot:item="{item}">
									{{ item.codigo }}-{{ item.descricao }}

									<v-spacer></v-spacer>

									<v-img
										:src="item.foto ? item.foto.file_url: urlNotFile"
										max-width="30"
									></v-img>
								</template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="4" cols="12">
              <VuetifyMoney
                v-model="form.quantidade"
                label="Quantidade"
                :rules="[v => !!v || 'Campo obrigatório']"
                :options="{ precision: 3 }"
                hide-details
              />
            </v-col>
            <v-col lg="4" cols="12">
              <VuetifyMoney
                v-model="form.preco"
                label="Vl. unitário"
                :rules="[v => !!v || 'Campo obrigatório']"
                :disabled="form.produto && form.produto.pvenda > 0"
                :options="{ precision: 3 }"
                hide-details
              />
            </v-col>
            <v-col lg="4" cols="12">
              <VuetifyMoney
                v-model="form.precototal"
                label="Vl. produto"
                disabled
              />
            </v-col>
          </v-row>
					<v-row>
						<v-col>
							<v-img
								v-if="form.foto"
								:src="form.foto"
								max-width="100"
							></v-img>
						</v-col>
					</v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col lg="2" cols="6">
            <v-btn
              elevation="0"
              @click="close()"
              block
              large
            >
              Fechar
            </v-btn>
          </v-col>
          <v-col lg="2" cols="6">
            <v-btn
              elevation="0"
              color="primary"
              type="submit"
              @click="salva()"
              block
              large
            >
              Adicionar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import VuetifyMoney from '@/components/geral/VuetifyMoney'
import { round,mostraMsgPadrao } from '@/functions'

export default {
  data(){
    return {
      isMobile: false,
      valid: null,
      searchProduto: '',
      isAlteracao: false,
      form: {
        produto: null,
        quantidade: null,
        preco: null,
        precototal: null,
				foto: null
      },
			urlNotFile: 'https://arquivos-mastertransp-masternfe.s3.sa-east-1.amazonaws.com/1354ac1fdab7eb03a1ad-SEM_FOTO.jpg',
    }
  },

	props: {
		dialog: Boolean,
    dados: Object
	},

  methods: {
    formataSelectProduto(pro){
      return `${pro.codigo}-${pro.descricao}`
    },

    verificaProduto(pro){
      if (! pro) return
      const { pvenda,foto } = pro
      this.form.preco = pvenda
			this.form.foto = foto ? foto.file_url : this.urlNotFile
    },

    calculaTotal(){
      this.form.precototal = this.form.quantidade * this.form.preco
    },

    close(){
			this.form = {}
      this.$refs.form.reset()
      this.$emit('fecha-dialog-pega-produto')
    },

    salva(){
      if (! this.$refs.form.validate()) return
      const { quantidade,preco } = this.form

      if (quantidade <= 0) return mostraMsgPadrao('Por favor informe a quantidade','error')
      if (preco <= 0) return mostraMsgPadrao('Por favor informe o valor unitário','error')

      const produto = { ...this.form }
      this.$emit('on-submit', produto)
      this.close()
    },
  },

  watch: {
    dialog(){
      this.isAlteracao = false
      if (! this.dialog) return
      if (! this.dados) return

      this.$store.commit('adicionaProduto', this.dados.produto)
      this.form = { ...this.dados }
      this.isAlteracao = true
    },

    'form.quantidade'(){
      this.form.precototal = round(this.form.quantidade * this.form.preco,2)
    },

    'form.preco'(){
      this.form.precototal = round(this.form.quantidade * this.form.preco,2)
    },

    searchProduto(val){
      if (! val) return
      if (val.indexOf('-') > -1) return
      
      const params = new URLSearchParams()
      params.append('pagina', 1)
      params.append('limite', 20)
      params.append('descricao', val)

      this.$store.dispatch('buscaProdutos', params)
    },
  },

  components: {
    VuetifyMoney
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.$store.dispatch('buscaProdutos')
  },
}
</script>

<style>

</style>
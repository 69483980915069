<template>
  <v-dialog v-model="dialog" max-width="600px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Alteração da foto de perfil</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout>
          <v-file-input
            ref="input"
            v-model="avatar_obj"
            accept="image/*"
            class="d-none"
            @change="mostraImagem"
          ></v-file-input>
        </v-layout>
        <v-layout justify-center class="mt-6">
          <v-avatar
            size="120"
            contain
            rounded
          >
            <v-img
              :src="url_avatar"
              :alt="url_avatar"
            ></v-img>
          </v-avatar>
        </v-layout>
        <v-layout class="justify-center mt-6" wrap>
          <v-flex lg6 xs12>
            <v-btn 
              elevation="0"
              :class="! isMobile ? 'mr-1' : ''"
              block
              @click="seleciona"
              :loading="$store.getters.isLoadingAvatar"
            >
              <v-icon
                left
              >
                mdi-tray-arrow-up
              </v-icon>
              Selecionar
            </v-btn>
          </v-flex>
          <v-flex lg6 xs12>
            <v-btn 
              elevation="0"
              :class="! isMobile ? 'ml-1' : 'mt-3'"
              block
              @click="remove"
              :loading="$store.getters.isLoadingAvatar"
              :disabled="url_avatar.substr(0,18) !== 'https://s3.sa-east'"
              >
              <v-icon
                left
              >
                mdi-trash-can-outline
              </v-icon>
              Remover
            </v-btn>
          </v-flex>
        </v-layout>

      </v-card-text>

      <v-card-actions>
        <v-row dense justify="end">
          <v-col lg="2" cols="6">
            <v-btn
              elevation="0"
              @click="close()"
              block
              large
            >
              Fechar
            </v-btn>
          </v-col>
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              color="primary"
              type="submit"
              @click="salva()"
              :loading="$store.getters.isLoadingAvatar"
              :disabled="! avatar_obj"
              block
              large
            >
              Adicionar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default {
  data(){
    return {
      isMobile: false,
      avatar_obj: null,
      url_avatar: this.$store.getters.usuario.avatar,
    }
  },

	props: {
		dialog: Boolean
	},

  methods: {
    seleciona(){
      this.$refs.input.$refs.input.click()
    },

    mostraImagem(){
      if (this.avatar_obj){
        const url = URL.createObjectURL(this.avatar_obj)
        this.url_avatar = url
      }
    },

    close(){
      this.avatar_obj = null
      this.url_avatar = this.$store.getters.usuario.avatar
      this.$emit('fecha-dialog-avatar')
    },

    remove(){
      Vue.swal({
        title: 'Exclusão de imagem de perfil',
        text: 'Confirma a exclusão da sua imagem de perfil?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed){
          this.$store.dispatch('deletaAvatar', this.$store.getters.usuario.id)
          .then(() => {
            this.close()
          })
        }
      })
    },

    salva(){
      const payload = {
        id: this.$store.getters.usuario.id,
        imagem: this.avatar_obj
      }

      this.$store.dispatch('alteraAvatar', payload)
      .then(() => {
        this.close()
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
</style>
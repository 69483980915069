<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Rastrear ordem de coleta'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">
        <v-card
          :class="(isMobile ? 'pa-6' : 'pa-12') + ' rounded-xl'"
          outlined
        >
          <v-form
            ref="ratreioForm"
            v-model="valid"
            @submit.prevent="submit()"
          >
            <v-layout>
              <v-text-field
                v-model="cod_rastreamento"
                label="Código de rastreamento"
                :rules="regras"
                v-mask="'AA#############'"
                @keyup="cod_rastreamento = cod_rastreamento.toUpperCase()"
                :autofocus="! isMobile"
              ></v-text-field>
            </v-layout>
            <v-layout>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                elevation="0"
                type="submit"
                :block="isMobile"
                large
              >
                Buscar
              </v-btn>
            </v-layout>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      valid: null,
      cod_rastreamento: '',
      regras: [
        v => !!v || 'Informe o código de rastreamento',
        v => v.length == 15 || 'Código inválido',
      ]
    }
  },

  components: {
    Titulo
  },

  methods: {
    submit(){
      if (this.$refs.ratreioForm.validate()){
        this.$router.push({
          name: 'RastreiaResult',
          params: {
            cod_rastreamento: this.cod_rastreamento
          }
        })
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style>

</style>
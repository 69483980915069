let estados = [
  { value: 'AC', label: 'ACRE' },
  { value: 'AL', label: 'ALAGOAS' },
  { value: 'AP', label: 'AMAPÁ' },
  { value: 'AM', label: 'AMAZONAS' },
  { value: 'BA', label: 'BAHIA' },
  { value: 'CE', label: 'CEARÁ' },
  { value: 'DF', label: 'DISTRITO FEDERAL' },
  { value: 'ES', label: 'ESPÍRITO SANTO' },
  { value: 'GO', label: 'GOÍAS' },
  { value: 'MA', label: 'MARANHÃO' },
  { value: 'MT', label: 'MATO GROSSO' },
  { value: 'MS', label: 'MATO GROSSO DO SUL' },
  { value: 'MG', label: 'MINAS GERAIS' },
  { value: 'PA', label: 'PARÁ' },
  { value: 'PB', label: 'PARAÍBA' },
  { value: 'PR', label: 'PARANÁ' },
  { value: 'PE', label: 'PERNAMBUCO' },
  { value: 'PI', label: 'PIAUÍ' },
  { value: 'RJ', label: 'RIO DE JANEIRO' },
  { value: 'RN', label: 'RIO GRANDE DO NORTE' },
  { value: 'RS', label: 'RIO GRANDE DO SUL' },
  { value: 'RO', label: 'RONDÔNIA' },
  { value: 'RR', label: 'RORAÍMA' },
  { value: 'SC', label: 'SANTA CATARINA' },
  { value: 'SP', label: 'SÃO PAULO' },
  { value: 'SE', label: 'SERGIPE' },
  { value: 'TO', label: 'TOCANTINS' },  
]

export default estados

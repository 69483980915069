<template>
  <div>
    <DialogNovaEmpresa :dialog="dialogCliente" @fecha-dialog-empresa="dialogCliente = false" />
    <DialogPegaProduto :dialog="dialogProduto" @fecha-dialog-pega-produto="fechaDialogProduto" :dados="produtoSel" @on-submit="salvaProduto" />
    <DialogDuplicata :dialog="dialogDuplicata" @fecha-dialog-duplicata="dialogDuplicata = false" :duplicata="duplicataSel.duplicata" @on-submit="salvaDuplicata" />
    <v-layout>
      <v-flex>
        <v-stepper v-model="steper" elevation="0" alt-labels>
          <v-stepper-header class="header">
            <v-stepper-step step="1" :complete="steper > 1">Identificação</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="steper > 2">Produtos</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="steper > 3">Totais</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4">Outros</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form
                ref="step1"
                v-model="valid1"
                @submit.prevent="submit()"
              >
                <v-row dense>
                  <v-col lg="12" cols="12">
                    <v-autocomplete
                      v-model="form.naturezaop"
                      label="Natureza da operação"
                      :items="naturezas"
                      item-value="codigo"
                      :item-text="formataSelectNatureza"
                      :rules="[v => !!v || 'Selecione a natureza de operação']"
                      no-data-text="Nenhuma natureza de operação encontrada"
                      :autofocus="! isAlteracao && ! isMobile"
                      ref="naturezaOp"
                      auto-select-first
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col lg="12" cols="12">
                    <v-autocomplete
                      v-model="form.coddestinatario"
                      label="Cliente"
                      :items="$store.getters.empresas"
                      item-value="codigo"
                      :item-text="formataSelectCliente"
                      :rules="[v => !!v || 'Selecione o cliente do pedido']"
                      no-data-text="Nenhum cliente encontrado"
                      :search-input.sync="searchEmpresa"
                      :loading="$store.getters.loading_empresas"
                      auto-select-first
                      cache-items
                      clearable
                    >
                      <template v-slot:no-data>
                        <v-btn
                          class="text-capitalize ml-2"
                          color="secondary"
                          height="40px"
                          @click="dialogCliente = true"
                          text
                        >
                          <v-icon left>mdi-plus</v-icon>
                          Cadastrar cliente
                        </v-btn>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col lg="6" cols="12">
                    <v-select
                      label="Modalidade do frete"
                      v-model="form.modalidadefrete"
                      :items="modalidadesfretes"
                      item-text="label"
                      item-value="value"
                      :rules="[v => !!v || 'Campo obrigatório']"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row dense>
                <v-col>
                  <v-btn
                    elevation="0"
                    @click="dialogProduto = true"
                    :block="isMobile"
                  >
                    <v-icon
                      left
                    >
                      mdi-plus
                    </v-icon>
                    Adicionar produto
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col v-if="isMobile">
                  <v-card
                    v-for="(prod, index) in produtos"
                    :key="index"
                    outlined
                    class="mb-2"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <span class="font-weight-bold text-truncate">Descrição</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.produto.codigo }}-{{ prod.produto.descricao }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Quantidade</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.quantidade | formataPesoQtde }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Vl. unitário</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.preco | formataValorUnitario }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Vl. produto</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.precototal | formataValor }}</span>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <v-btn
                            elevation="0"
                            @click="alteraProduto(index)"
                            block
                          >
                            <v-icon
                              left
                            >
                              mdi-pencil-outline
                            </v-icon>
                            Alterar
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            elevation="0"
                            @click="deletaProduto(prod,index)"
                            block
                          >
                            <v-icon
                              left
                            >
                              mdi-delete-outline
                            </v-icon>
                            Deletar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col v-else>
                  <v-simple-table v-if="produtos.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
													<th></th>
                          <th class="text-right">Cód.</th>
                          <th>Descrição</th>
                          <th>Quantidade</th>
                          <th>Vl. unitário</th>
                          <th>Vl. produto</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(prod, index) in produtos"
                          :key="index"
                        >
													<td>
														<v-img
															:src="prod.foto"
															max-width="30"
														></v-img>
													</td>
                          <td class="text-right">{{ prod.produto.codigo }}</td>
                          <td>{{ prod.produto.descricao }}</td>
                          <td>{{ prod.quantidade | formataPesoQtde }}</td>
                          <td>{{ prod.preco | formataValorUnitario }}</td>
                          <td>{{ prod.precototal | formataValor }}</td>
                          <td>
                            <v-btn
                              icon
                              @click="alteraProduto(index)"
                            >
                              <v-icon small>mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              @click="deletaProduto(prod,index)"
                            >
                              <v-icon small>mdi-delete-outline</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-row dense>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.vltotalprodutos"
                    label="Valor produtos"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorbcicms"
                    label="BC ICMS"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valoricms"
                    label="Valor ICMS"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorbcicmsst"
                    label="BC ICMS ST"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valoricmsst"
                    label="Valor ICMS ST"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valoripi"
                    label="Valor IPI"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorii"
                    label="Valor II"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorpis"
                    label="Valor PIS"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorcofins"
                    label="Valor COFINS"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorpisst"
                    label="Valor PIS ST"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorcofinsst"
                    label="Valor COFINS ST"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorfcpst"
                    label="Valor FCP ST"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valoricmsdesonerado"
                    label="Valor ICMS desonerado"
                    disabled
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.vlfrete"
                    label="Valor frete"
                    @onBlur="calculaTotaisApi"
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valorseguro"
                    label="Valor seguro"
                    @onBlur="calculaTotaisApi"
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valordesconto"
                    label="Valor desconto"
                    @onBlur="calculaTotaisApi"
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.valordespesas"
                    label="Valor despesas"
                    @onBlur="calculaTotaisApi"
                  />
                </v-col>
                <v-col lg="2" cols="6">
                  <VuetifyMoney
                    v-model="form.vltotalnfe"
                    label="Valor pedido"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-form
                ref="step4"
                v-model="valid4"
                @submit.prevent="submit()"
              >
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="form.idcondicaopagamento"
                      label="Condição de pagamento"
                      :items="condicoes_pagamentos"
                      item-value="id"
                      :item-text="formataSelectCondPgto"
                      :rules="[v => !!v || 'Selecione a condição de pagamento']"
                      no-data-text="Nenhuma condição de pagamento encontrada"
                      auto-select-first
                      clearable
                      @change="calculaParcelas"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div v-if="isMobile">
                      <v-card
                        v-for="(dupl, index) in duplicatas"
                        :key="index"
                        outlined
                        class="mb-2"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <span class="font-weight-bold">Parcela</span>
                            </v-col>
                            <v-col class="text-end">
                              <span>{{ dupl.parcela }}</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <span class="font-weight-bold">Vencimento</span>
                            </v-col>
                            <v-col class="text-end">
                              <span>{{ dupl.datavencimento | formataData }}</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <span class="font-weight-bold">Valor</span>
                            </v-col>
                            <v-col class="text-end">
                              <span>{{ dupl.valor | formataValor }}</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-btn
                                elevation="0"
                                @click="alteraDuplicata(dupl,index)"
                                block
                              >
                                <v-icon
                                  left
                                >
                                  mdi-pencil-outline
                                </v-icon>
                                Alterar
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                    <v-simple-table v-else>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Vencimento</th>
                            <th>Valor</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(dupl, index) in duplicatas"
                            :key="index"
                          >
                            <td>{{ dupl.datavencimento | formataData }}</td>
                            <td>{{ dupl.valor | formataValor }}</td>
                            <td>
                              <v-btn
                                icon
                                @click="alteraDuplicata(dupl,index)"
                              >
                                <v-icon small>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      label="Observação"
                      v-model="form.observacao"
                      rows="1"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
    <v-layout :class="isMobile ? 'px-5 pb-5' : ''">
      <v-flex d-flex>
        <v-btn
          v-if="isMobile"
          @click="voltar"
          color="primary"
          elevation="0"
          :disabled="steper == 1"
          large
          icon
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          @click="voltar"
          color="primary"
          elevation="0"
          :disabled="steper == 1"
          x-large
          text
        >
          <v-icon
            left
          >
            mdi-arrow-left
          </v-icon>
          Voltar
        </v-btn>

        <v-btn
          v-if="isMobile"
          @click="avancar"
          color="primary"
          elevation="0"
          :disabled="steper == 4"
          large
          icon
        >
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          @click="avancar"
          color="primary"
          elevation="0"
          :disabled="steper == 4"
          x-large
          text
        >
          Avançar
          <v-icon
            right
          >
            mdi-arrow-right
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          elevation="0"
          type="submit"
          :disabled="steper !== 4"
          @click="salva"
          large
        >
          Salvar
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Vue from "vue"
import DialogNovaEmpresa from "@/pages/empresas/DialogNovaEmpresa"
import DialogPegaProduto from "@/pages/produtos/DialogPegaProduto"
import DialogDuplicata from "@/pages/pedidos_vendas/DialogDuplicata"
import VuetifyMoney from "@/components/geral/VuetifyMoney"
import modalidadesfretes from "@/constantes/modalidadesfretes"
import { round } from "@/functions"

export default {
  data() {
    return {
      isMobile: false,
      valid1: true,
      valid4: true,
      isAlteracao: false,
      steper: 1,
      naturezas: [],
      searchEmpresa: "",
      dialogCliente: false,
      dialogProduto: false,
      dialogDuplicata: false,
      produtoSel: null,
      indexProAlt: null,
      produtos: [],
      duplicatas: [],
      form: {
        filial: 0,
        naturezaop: 0,
        coddestinatario: 0,
        modalidadefrete: "9",
        observacao: "",
        vltotalprodutos: 0,
        valorbcicms: 0,
        valoricms: 0,
        valorbcicmsst: 0,
        valoricmsst: 0,
        valoripi: 0,
        valorii: 0,
        valorpis: 0,
        valorcofins: 0,
        valorpisst: 0,
        valorcofinsst: 0,
        valorfcpst: 0,
        valoricmsdesonerado: 0,
        vlfrete: 0,
        valorseguro: 0,
        valordesconto: 0,
        valordespesas: 0,
        vltotalnfe: 0,
        produtos: [],
        duplicatas: [],
        idcondicaopagamento: 0
      },
      modalidadesfretes,
      condicoes_pagamentos: [],
      duplicataSel: {
        duplicata: null,
        index: null
      },
			urlNotFile: 'https://arquivos-mastertransp-masternfe.s3.sa-east-1.amazonaws.com/1354ac1fdab7eb03a1ad-SEM_FOTO.jpg'
    }
  },

  methods: {
    formataSelectNatureza(nat) {
      return `${nat.codigo}-${nat.descricao}`
    },

    formataSelectCliente(emp) {
      return `${emp.codigo}-${emp.nome}/${emp.cpf_cnpj}`
    },

    formataSelectCondPgto(cond) {
      return `${cond.id}-${cond.descricao}`
    },

    voltar() {
      this.steper--
    },

    avancar() {
      if (this.steper == 1) {
        if (!this.$refs.step1.validate()) return
      } else if (this.steper == 2) {
        if (this.produtos.length == 0) return
      }

      this.steper++

      if (this.steper == 3) {
        this.calculaTotaisApi()
      }
    },

    calculaParcelas(id){
      this.duplicatas = []
      if (! id) return
      const body = {
        id_condicao: id,
        vl_pedido: this.form.vltotalnfe
      }

      this.$store.dispatch('calculaParcelasPedido', body).then(() => {
        this.duplicatas = this.$store.getters.duplicatas_pedido
      })
    },

    calculaTotaisApi(){
      const {
        naturezaop,
        coddestinatario,
        vlfrete,
        valorseguro,
        valordesconto,
        valordespesas,
        modalidadefrete,
      } = this.form
      if (coddestinatario == 0 || naturezaop == 0) return
      if (this.produtos.length == 0) return
      const produtos = this.produtos.map((prods) => {
        const { produto, quantidade, preco } = prods
        return {
          codigo: produto.codigo,
          quantidade,
          preco,
        }
      })

      const { codigo: filial } = this.$store.getters.filial

      const payload = {
        filial,
        naturezaop,
        coddestinatario,
        vlfrete,
        valorseguro,
        valordesconto,
        valordespesas,
        modalidadefrete,
        produtos,
      }

      this.$store.dispatch("calculaPedidoVenda", payload).then(() => {
        const pedido = this.$store.getters.pedido_venda_calculo
        const {
          vltotalprodutos,
          valorbcicms,
          valoricms,
          valorbcicmsst,
          valoricmsst,
          valoripi,
          valorii,
          valorpis,
          valorcofins,
          valorpisst,
          valorcofinsst,
          valorfcpst,
          valoricmsdesonerado,
          vltotalnfe,
        } = pedido

        this.form.vltotalprodutos = vltotalprodutos
        this.form.valorbcicms = valorbcicms
        this.form.valoricms = valoricms
        this.form.valorbcicmsst = valorbcicmsst
        this.form.valoricmsst = valoricmsst
        this.form.valoripi = valoripi
        this.form.valorii = valorii
        this.form.valorpis = valorpis
        this.form.valorcofins = valorcofins
        this.form.valorpisst = valorpisst
        this.form.valorcofinsst = valorcofinsst
        this.form.valorfcpst = valorfcpst
        this.form.valoricmsdesonerado = valoricmsdesonerado
        this.form.vltotalnfe = vltotalnfe
      })
    },

    salvaProduto(pro) {
      if (!pro) return
      const { produto,quantidade,preco,precototal,foto } = pro

      if (this.produtoSel) {
        Vue.set(this.produtos, this.indexProAlt, {
          produto,
          quantidade: round(quantidade, 3),
          preco: round(preco, 3),
          precototal: round(precototal, 2),
					foto
        })

        this.indexProAlt = null
        this.produtoSel = null
      } else {
        this.produtos.push({
          produto,
          quantidade: round(quantidade, 3),
          preco: round(preco, 3),
          precototal: round(precototal, 2),
					foto
        })
      }
    },

    salvaDuplicata(dup) {
      if (!dup || ! this.duplicataSel) return
      const { index } = this.duplicataSel
      Vue.set(this.duplicatas, index, dup)
    },

    alteraDuplicata(dup,index) {
      this.duplicataSel = {
        duplicata: dup,
        index
      }
      this.dialogDuplicata = true
    },

    deletaProduto(prod, index) {
      const { descricao } = prod.produto
      Vue.swal({
        title: "Remover produto",
        text: `Confirma a exclusão do produto ${descricao}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.produtos.splice(index, 1)
        }
      })
    },

    alteraProduto(index) {
      this.indexProAlt = index
      this.produtoSel = this.produtos[index]
      this.dialogProduto = true
    },

    fechaDialogProduto() {
      this.indexProAlt = null
      this.produtoSel = null
      this.dialogProduto = false
    },

    salva() {
      if (!this.$refs.step4.validate()) return

      this.form.produtos = this.produtos.map((prods) => {
        const { produto, quantidade, preco } = prods
        return {
          codigo: produto.codigo,
          quantidade,
          preco,
        }
      })

      this.form.duplicatas = this.duplicatas
      this.form.filial = this.$store.getters.filial.codigo

      this.$emit("on-submit", this.form)
    },
  },

  props: {
    pedido_venda: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  components: {
    DialogNovaEmpresa,
    DialogPegaProduto,
    DialogDuplicata,
    VuetifyMoney,
  },

  watch: {
    pedido_venda() {
      this.isAlteracao = true

      const {
        naturezaop,
        coddestinatario,
        modalidadefrete,
        observacao,
        produtos,
        duplicatas,
        cliente,
        vlfrete,
        valorseguro,
        valordesconto,
        valordespesas,
        idcondicaopagamento
      } = this.pedido_venda

      this.$store.commit("adicionaEmpresa", cliente)

      this.form.naturezaop = naturezaop
      this.form.coddestinatario = coddestinatario
      this.form.modalidadefrete = modalidadefrete
      this.form.observacao = observacao
      this.form.vlfrete = vlfrete
      this.form.valorseguro = valorseguro
      this.form.valordesconto = valordesconto
      this.form.valordespesas = valordespesas
      this.form.idcondicaopagamento = idcondicaopagamento

      this.produtos = produtos.map((produto) => {
        const { quantidade, preco, precototal } = produto
        return {
          produto,
          quantidade,
          preco,
          precototal,
        }
      })

      this.duplicatas = duplicatas.map((produto) => {
        const { duplicata,datavencimento, valor } = produto
        return {
          parcela: duplicata,
          datavencimento,
          valor,
        }
      })
    },

    searchEmpresa(val) {
      if (!val) return
      if (val.indexOf("-") > -1) return

      const params = new URLSearchParams()
      params.append("pagina", 1)
      params.append("limite", 20)
      params.append("empresa", val)

      this.$store.dispatch("buscaEmpresas", params)
    },
  },

  created() {
    this.isMobile = window.innerWidth < 800
    this.$store.dispatch("buscaEmpresas")
    this.$store.dispatch("buscaNaturezasOperacao").then(() => {
      this.naturezas = this.$store.getters.naturezas_op
    })
    this.$store.dispatch("buscaCondicoesPagamentos").then(() => {
      this.condicoes_pagamentos = this.$store.getters.condicoes_pagamentos
    })
  },
}
</script>

<style scoped>
.header {
  box-shadow: none !important
}
</style>
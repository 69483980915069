<template>
  <div>
    <v-row>
      <v-col class="d-flex">
        <Titulo
          :titulo="'Cadastro de clientes'"
        />

        <v-spacer></v-spacer>

        <v-text-field
          v-if="mostraPesquisa"
          v-model="search"
          label="Pesquisar cliente"
          single-line
          hide-details
          outlined
          dense
          class="text-white rounded-r-0"
        ></v-text-field>

        <v-btn
          elevation="0"
          color="primary"
          height="40"
          :class="mostraPesquisa ? 'rounded-l-0' : ''"
          @click="mostraPesquisa = ! mostraPesquisa"
          v-if="! isMobile"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          v-if="isMobile"
          :to="{ name: 'NovoCliente' }"
          color="primary"
          bottom
          fixed
          large
          right
          fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          elevation="0"
          class="ml-2"
          color="primary"
          :to="{ name: 'NovoCliente' }"
          height="40"
          v-else
        >
          Novo cliente
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')">
          <v-data-table
            :headers="headers"
            :items="$store.getters.clientes"
            no-data-text="Nenhum cliente cadastrado"
            no-results-text="Nenhum cliente encontrado"
            loading-text="Carregando os clientes"
            :footer-props="{itemsPerPageText: 'Clientes por página', pageText: '{0}-{1} de {2}', itemsPerPageAllText: 'Tudo'}"
            @current-items="(clientes) => qtdclientes = clientes.length"
            class="grid-truncate"
            :items-per-page=15
            :search="search"
            @dblclick:row.prevent="($event, row) => ! isMobile ? alteraCliente(row.item) : ''"
            :disable-sort="isMobile"
            fixed-header
            :height="alturaMaxima"
          >
            <template v-slot:[`item.nome`]="{ item }">
              <div class="text-truncate" style="max-width: 320px">
                {{ item.nome }}
              </div>
            </template>

            <template v-slot:[`item.cpf_cnpj`]="{ item }">
              {{ item.cpf_cnpj | formataCpfCnpj }}
            </template>

            <template v-slot:[`item.logomarca`]="{ item }">
              <v-avatar
                size="32"
                rounded
              >
                <v-img
                  :src="item.logomarca"
                  alt="Logomarca da empresa"
                  contain
                ></v-img>
              </v-avatar>
            </template>

            <template v-slot:[`item.ativo`]="{ item }">
              <v-chip
                label
                small
                class="white--text font-weight-bold"
                :color="item.ativo ? 'green' : 'red'"
              >
                {{ item.ativo ? 'Ativo' : 'Inativo' }}
              </v-chip>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formataData }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-bottom-sheet v-if="isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list rounded="t-xl">
                  <v-row dense class="mt-3">
                    <v-col class="text-center">
                      <v-avatar
                        style="border: 1px solid #e0e0e0"
                        size="48"
                      >
                        <v-img
                          :src="item.logomarca"
                          v-bind="attrs"
                          v-on="on"
                          alt="Logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-col>
                  </v-row>
                  <v-row dense class="mb-3">
                    <v-col class="text-center">
                      <span class="font-weight-bold">{{ item.nome }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-list-item
                        v-for="obj in items"
                        :key="obj.id"
                        @click="obj.click(item)"
                        v-show="verificaMenu(obj,item)"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ obj.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ obj.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-list>
              </v-bottom-sheet>

              <div class="actionButtons" v-else>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                      v-show="verificaMenu(obj,item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      mostraPesquisa: false,
      search: '',
      dialogLogo: false,
      id_cliente_logo: 0,
      headers: [
        {text: '', align: 'start', sortable: false, value: 'logomarca'},
        {text: 'Nome', align: 'start', sortable: true, value: 'nome'},
				{text: 'CPF/CNPJ', align: 'start', sortable: true, value: 'cpf_cnpj'},
				{text: 'IE', align: 'start', sortable: true, value: 'ie'},
				{text: 'Situação', align: 'start', sortable: false, value: 'ativo'},
				{text: 'Data cadastro', align: 'start', sortable: true, value: 'created_at'},
				{text: '', value: 'actions', sortable: false}
      ],
      items: [
        {id: 1, text: 'Editar', icon: 'mdi-pencil-outline', click: this.alteraCliente},
        {id: 2, text: 'Ativar', icon: 'mdi-thumb-up-outline', click: this.ativaInativaCliente},
        {id: 3, text: 'Inativar', icon: 'mdi-thumb-down-outline', click: this.ativaInativaCliente},
        {id: 4, text: 'Token', icon: 'mdi-lock-outline', click: this.tokenCliente},
        {id: 5, text: 'Backups', icon: 'mdi-cloud-upload-outline', click: this.backupsCliente},
      ],
      qtdclientes: 0,
      isMobile: false
    }
  },

  components: {
    Titulo
  },

  methods: {
    verificaMenu(obj,item){
      return (obj.text !== 'Ativar' && obj.text !== 'Inativar') || (obj.text == 'Ativar' && ! item.ativo) || (obj.text == 'Inativar' && item.ativo)
    },

    alteraCliente(cliente){
      const id = cliente.id

      this.$router.push({
        name: 'AlteraCliente',
        params: {
          id_cliente: id
        }
      })
    },

    alteraLogoCliente(cliente){
      const { id } = cliente
      this.dialogLogo = true
      this.id_cliente_logo = id
    },

    ativaInativaCliente(cliente){
      const { id,nome,ativo } = cliente

      Vue.swal({
        title: ativo ? 'Bloquear cliente' : 'Desbloquear cliente',
        text: `Confirma o ${ativo ? 'bloqueio' : 'desbloqueio'} do cliente ${nome}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Sim, ${ativo ? 'bloquear' : 'desbloquear'}`,
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed){
          this.$store.dispatch(ativo ? 'inativaCliente' : 'ativaCliente', id)
          .then(() => {
            this.$store.dispatch('buscaClientes')
            .then(() => {
              Vue.swal({
                icon: 'success',
                text: `Cliente ${ativo ? 'bloqueado' : 'desbloqueado'} com sucesso`,
                confirmButtonText: 'Fechar',
                timer: 5000
              })
            })
          })
        }
      })
    },

    tokenCliente(cliente){
      const { id } = cliente

      this.$router.push({
        name: 'TokenCliente',
        params: {
          id_cliente: id
        }
      })
    },

    backupsCliente(cliente){
      const { id } = cliente

      this.$router.push({
        name: 'BackupsCliente',
        params: {
          id_cliente: id
        }
      })
    }
  },

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 250
    },
  },

  created(){
    this.isMobile = window.innerWidth < 800
    const admin = this.$store.getters.usuario.tipo == 'administrador'
    if (! admin){
      this.$router.go(-1)
    }
    this.$store.dispatch('buscaClientes')
  },
}
</script>

<style scoped>
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import autenticacao from './autenticacao'
import usuarios from './usuarios'
import clientes from './clientes'
import ordenscoletas from './ordenscoletas'
import api from './api'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    autenticacao,
    usuarios,
    clientes,
    ordenscoletas,
    api
  },
  plugins: [new VuexPersistence().plugin]
})

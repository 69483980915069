import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Loading from './components/geral/Loading'
import VueMask,{ VueMaskDirective } from 'v-mask'
import './filters'
import './plugins/vuetify-money'

Vue.config.productionTip = false

Vue.use(VueSweetalert2)

Vue.use(VueMask)
Vue.directive('mask', VueMaskDirective)

Vue.component('Loading', Loading)

new Vue({
  router,
  store,
  axios,
  vuetify,
  render: h => h(App)
}).$mount('#app')

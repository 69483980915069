<template>
  <v-app>
    <DialogSuporte
      :dialog="dialogSuporte"
      @fecha-dialog-suporte="dialogSuporte = false"
    />
    <v-navigation-drawer
      :width="isMobile ? '80%' : '250px'"
      v-model="drawer"
      height="100%"
      fixed
      app
    >
      <template v-slot:prepend>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon class="mr-3">
              <v-icon color="primary">mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span class="font-weight-bold">MasterWeb</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>

      <v-list class="pa-0">
        <v-list-item
          class="ma-2 rounded-lg"
          active-class="itemSelecionado"
          v-for="item in $store.getters.itens_menu"
          :key="item.id"
          :to="item.to"
        >
          <v-list-item-icon class="mr-2">
            <v-icon small>{{ item.icone }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="font-size-item text-truncate">
            {{ item.label }}
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon class="mr-3">
              <router-link :to="{ name: 'MeuPerfil' }">
                <v-img
                  :src="$store.getters.usuario.avatar"
                  alt="Avatar do usuário"
                  class="rounded"
                  width="24"
                ></v-img>
              </router-link>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="d-flex justify-space-between align-center">
                <span class="font-weight-bold capitalized">
                  {{ $store.getters.usuario.nome | formataNome }}
                </span>
                <v-btn @click="$store.dispatch('logout')" icon small>
                  <v-icon small>mdi-exit-to-app</v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar class="app-bar" height="57" dense flat app>
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-bottom-sheet v-if="isMobile" v-model="sheet">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="32" class="ml-2" style="border: 1px solid #e0e0e0">
            <v-img
              :src="$store.getters.cliente_usuario.logomarca"
              v-bind="attrs"
              v-on="on"
              alt="Logo"
              contain
            ></v-img>
          </v-avatar>
        </template>

        <v-list rounded="t-xl">
          <v-row class="py-3">
            <v-col class="text-center">
              <span class="font-weight-bold">Trocar empresa</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item
            v-for="cli in clientes_usuario"
            :key="cli.id"
            class="mt-3"
            @click="verificaCliSel(cli)"
          >
            <v-list-item-avatar size="32">
              <v-img
                :src="cli.logomarca"
                :alt="`Logo ${cli.nome}`"
                contain
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ cli.nome }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>

      <v-menu
        v-else
        v-model="menu_cli"
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" height="38">
            {{ $store.getters.cliente_usuario.nome }}
          </v-btn>
        </template>

        <v-card v-if="this.$store.getters.clientes_usuario.length > 0" class="pa-3" outlined>
          <v-text-field
            v-model="search_cli"
            label="Pesquisar"
            single-line
            hide-details
            dense
          ></v-text-field>

          <v-list-item
            v-for="cli in clientes_usuario"
            :key="cli.id"
            class="mt-3"
            @click="verificaCliSel(cli)"
          >
            <v-list-item-avatar size="32">
              <v-img
                :src="cli.logomarca"
                :alt="`Logo ${cli.nome}`"
                contain
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ cli.nome }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-menu>

      <v-select
        v-if="!isMobile"
        v-model="filial"
        label="Filial"
        :items="$store.getters.filiais"
        :item-text="(fil) => fil.codigo + '-' + fil.razao"
        @change="atualizaFilial"
        hide-details
        class="ml-3"
        return-object
        solo
        dense
      ></v-select>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="dialogSuporte = true">
            <v-icon>mdi-lifebuoy</v-icon>
          </v-btn>
        </template>
        <span>Suporte</span>
      </v-tooltip>
      <!-- <v-btn icon>
        <v-icon>mdi-bell-ring-outline</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main class="background">
      <v-container fluid :class="isMobile ? 'pt-4' : 'pa-4'">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { EventBus } from "@/event-bus"
import DialogSuporte from "@/dialogs/DialogSuporte"

export default {
  data() {
    return {
      isMobile: false,
      drawer: false,
      mini: true,
      clientes_usuario: [],
      dialogSuporte: false,
      search_cli: '',
      menu_cli: false,
      filial: {},
      sheet: null,
    }
  },

  components: {
    DialogSuporte,
  },

  methods: {
    verificaCliSel(cli) {
      this.$store.dispatch("atualizaClienteUsuario", cli).then(() => {
        this.atualizaListaClientes()
        this.atualizaListaFiliais()
        this.menu_cli = false
        this.sheet = false
      })
    },

    atualizaListaClientes() {
      const id = this.$store.getters.cliente_usuario.id
      const clientes_usuario = this.$store.getters.clientes_usuario
      this.clientes_usuario = clientes_usuario.filter((cli) => cli.id !== id)
    },

    atualizaListaFiliais() {
      this.$store.dispatch("buscaFiliais").then(() => {
        this.filial = this.$store.getters.filiais[0]
        this.atualizaFilial()
      })
    },

    atualizaFilial() {
      this.$store.commit("filial", this.filial)
      EventBus.$emit("atualiza-filial")
    },
  },

  created() {
    this.isMobile = window.innerWidth < 800
    this.drawer = !this.isMobile
    this.atualizaListaClientes()
    this.atualizaListaFiliais()
  },

	watch: {
		search_cli(){
			const cliente_usuario_id = this.$store.getters.cliente_usuario.id
			const clientes_usuario = this.$store.getters.clientes_usuario.filter((cli) => cli.id !== cliente_usuario_id)

			if (this.search_cli){
				this.clientes_usuario = clientes_usuario.filter((cli) => cli.nome.toUpperCase().includes(this.search_cli.toUpperCase()))
			} else {
				this.clientes_usuario = clientes_usuario
			}
		}
	}
}
</script>

<style scoped>
.font-size {
  font-size: 0.7rem
}
.font-size-item {
  font-size: 0.9rem
}
.app-bar {
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.background {
  background-color: #f4f5ff;
}
.cursor-pointer:hover {
  cursor: pointer;
}
.itemSelecionado {
  background: #3494e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3494e6,
    #ec6ead
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3494e6,
    #ec6ead
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}
</style>
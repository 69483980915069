<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Novo cliente'"
        />
      </v-col>
    </v-row>
    
    <v-row>
      <v-col lg="6">
        <v-card
          :class="'rounded-xl ' + (isMobile ? 'pa-3' : 'pa-6')"
          outlined
        >
          <v-card-text>
            <FormCliente
              @on-submit="grava"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'
import FormCliente from './FormCliente'

export default {
  data(){
    return {
      isMobile: false
    }
  },

  components: {
    Titulo,
    FormCliente
  },

  methods: {
    grava(form){
      this.$store.dispatch('gravaCliente', form)
      .then(() => {
        this.$router.go(-1)
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style>

</style>
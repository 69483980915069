<template>
  <v-container fluid fill-height class="pa-0 bg-fundo">
    <DialogSuporte :dialog="dialogSuporte" @fecha-dialog-suporte="dialogSuporte = false" />
    <v-layout align-center>
      <v-flex lg4 sm10 xs12 offset-sm-1 offset-lg-4 :class="isMobile ? 'pa-2' : ''">
        <v-card :class="'pb-12 ' + (isMobile ? 'px-2' : 'px-8')">
          <v-card-text>
            <v-layout class="my-8" justify-center>
              <span class="font-weight-bold text-center" style="font-size: 1rem">Olá {{ usuario.nome }}<br>Crie a sua senha para acesso ao MasterWeb</span>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-form v-model="formConfirmaValid" ref="formLogin">
                  <v-text-field
                    v-model="form.senha"
                    placeholder="Informe a senha"
                    :append-icon="mostraSenha1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="mostraSenha1 = !mostraSenha1"
                    @keypress.enter="loga"
                    :type="mostraSenha1 ? 'text' : 'password'"
                    :rules="[ v => !!v || 'Informe a senha' ]"
                    :autofocus="! isMobile"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.confirma_senha"
                    placeholder="Confirme a senha"
                    :append-icon="mostraSenha2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="mostraSenha2 = !mostraSenha2"
                    @keypress.enter="loga"
                    :type="mostraSenha2 ? 'text' : 'password'"
                    :rules="[ v => !!v || 'Informe a senha' ]"
                  ></v-text-field>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="d-flex flex-column">
            <v-btn
              :loading="$store.getters.isLoadingBtnLogin"
              color="primary"
              @click="validaUsuario"
              class="pa-6"
              block
            >
              Enviar
            </v-btn>

            <span class="mt-6">
              <a href="#" @click="dialogSuporte = true">Precisa de ajuda?</a>
            </span>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DialogSuporte from '@/dialogs/DialogSuporte'

export default {
  data(){
    return {
      isMobile: false,
      uuid: '',
      usuario: {},
      formConfirmaValid: true,
      mostraSenha1: false,
      mostraSenha2: false,
      dialogSuporte: false,
      form: {
        senha: '',
        confirma_senha: ''
      }
    }
  },

  methods: {
    validaUsuario(){
      if (this.$refs.formLogin.validate()){
        const payload = {
          id_usuario: this.usuario.id,
          body: this.form
        }
        this.$store.dispatch('validaUsuario', payload)
        .then(() => {
          this.$router.push({
            name: 'Login'
          })
        })
      }
    }
  },

  components: {
    DialogSuporte
  },

  computed: {
    paddingCard(){
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'px-1'
        default: return 'px-10'
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.uuid = this.$route.params.uuid
    this.$store.dispatch('buscaConfirmacaoUsuario', this.uuid)
    .then(() => {
      const confirmacaoUsuario = this.$store.getters.confirmacaoUsuario
      this.usuario = confirmacaoUsuario.usuario
    }).catch(() => {
      this.$router.push({
        name: 'Login'
      })
    })
  }
}
</script>

<style scoped>
.header-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.bg-fundo {
  background: #72c5b5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #F8FFAE, #72c5b5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #F8FFAE, #72c5b5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.div-login {
  position: absolute;
}

@media (max-width: 600px){
  .header-title {
    font-size: 1.1rem;
  }
}
</style>
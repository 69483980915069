<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Pedido de venda #{{ pedido.id }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary pt-3">
        <v-row>
          <v-col class="d-flex flex-column" lg="2" cols="6">
            <span class="text--secondary">Status</span>
            <span>
              <v-chip
                label
                small
                class="white--text font-weight-bold"
                :color="statusPedidoVenda(pedido).cor"
              >
                {{ statusPedidoVenda(pedido).label }}
              </v-chip>
            </span>
          </v-col>

          <v-col class="d-flex flex-column" lg="2" cols="6">
            <span class="text--secondary">Data emissão</span>
            <span class="font-weight-bold">{{ pedido.dataemissao | formataData }}</span>
          </v-col>

          <v-col class="d-flex flex-column" lg="2" cols="6">
            <span class="text--secondary">Valor pedido</span>
            <span class="font-weight-bold">{{ pedido.vltotalnfe | formataValor }}</span>
          </v-col>

          <v-col class="d-flex flex-column" lg="6" cols="12">
            <span class="text--secondary">Natureza da operação</span>
            <span class="font-weight-bold text-truncate" v-if="pedido.naturezaoperacao">{{ pedido.naturezaoperacao.codigo }}-{{ pedido.naturezaoperacao.descricao }}</span>
          </v-col>

          <v-col class="d-flex flex-column" lg="6" cols="12">
            <span class="text--secondary">Cliente</span>
            <span class="font-weight-bold text-truncate" v-if="pedido.cliente">{{ pedido.cliente.codigo }}-{{ pedido.cliente.nome }}</span>
          </v-col>

          <v-col class="d-flex flex-column" lg="6" cols="12">
            <span class="text--secondary">Vendedor</span>
            <span class="font-weight-bold text-truncate" v-if="pedido.vendedor">{{ pedido.vendedor.codigo }}-{{ pedido.vendedor.nome }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-tabs
              v-model="tab"
              :vertical="isMobile"
            >
              <v-tab>
                Produtos
              </v-tab>
              <v-tab>
                Totais
              </v-tab>
              <v-tab>
                Duplicatas
              </v-tab>
              <v-tab>
                Outros
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <div v-if="isMobile">
                  <v-card
                    v-for="prod in pedido.produtos"
                    :key="prod.id"
                    outlined
                    class="mb-2"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <span class="font-weight-bold">Descrição</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.descricao }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Quantidade</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.quantidade | formataPesoQtde }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Vl. unitário</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.preco | formataValorUnitario }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Vl. produto</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ prod.precototal | formataValor }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
                <v-simple-table v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-right">Cód.</th>
                        <th>Descrição</th>
                        <th>Quantidade</th>
                        <th>Vl. unitário</th>
                        <th>Vl. produto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="prod in pedido.produtos"
                        :key="prod.id"
                      >
                        <td class="text-right">{{ prod.codigo }}</td>
                        <td>{{ prod.descricao }}</td>
                        <td>{{ prod.quantidade | formataPesoQtde }}</td>
                        <td>{{ prod.preco | formataValorUnitario }}</td>
                        <td>{{ prod.precototal | formataValor }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>

              <v-tab-item>
                <v-row class="mt-2">
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor produtos</span>
                    <span class="font-weight-bold">{{ pedido.vltotalprodutos | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor II</span>
                    <span class="font-weight-bold">{{ pedido.valorii | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor ICMS deson.</span>
                    <span class="font-weight-bold">{{ pedido.valoricmsdesonerado | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">BC ICMS</span>
                    <span class="font-weight-bold">{{ pedido.valorbcicms | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor PIS</span>
                    <span class="font-weight-bold">{{ pedido.valorpis | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor frete</span>
                    <span class="font-weight-bold">{{ pedido.vlfrete | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor ICMS</span>
                    <span class="font-weight-bold">{{ pedido.valoricms | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor COFINS</span>
                    <span class="font-weight-bold">{{ pedido.valorcofins | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor seguro</span>
                    <span class="font-weight-bold">{{ pedido.valorseguro | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">BC ICMS ST</span>
                    <span class="font-weight-bold">{{ pedido.valorbcicmsst | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor PIS ST</span>
                    <span class="font-weight-bold">{{ pedido.valorpisst | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor desconto</span>
                    <span class="font-weight-bold">{{ pedido.valordesconto | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor ICMS ST</span>
                    <span class="font-weight-bold">{{ pedido.valoricmsst | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor COFINS ST</span>
                    <span class="font-weight-bold">{{ pedido.valorcofinsst | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor despesas</span>
                    <span class="font-weight-bold">{{ pedido.valordespesas | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor IPI</span>
                    <span class="font-weight-bold">{{ pedido.valoripi | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor FCP ST</span>
                    <span class="font-weight-bold">{{ pedido.valorfcpst | formataValor }}</span>
                  </v-col>
                  <v-col class="d-flex flex-column" lg="2" cols="6">
                    <span class="text--secondary">Valor pedido</span>
                    <span class="font-weight-bold">{{ pedido.vltotalnfe | formataValor }}</span>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <div v-if="isMobile">
                  <v-card
                    v-for="(dupl,index) in pedido.duplicatas"
                    :key="dupl.id"
                    outlined
                    class="mb-2"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Parcela</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ index+1 }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Vencimento</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ dupl.datavencimento | formataData }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="font-weight-bold">Valor</span>
                        </v-col>
                        <v-col class="text-end">
                          <span>{{ dupl.valor | formataValor }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
                <v-simple-table v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Parcela</th>
                        <th>Vencimento</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(dupl,index) in pedido.duplicatas"
                        :key="dupl.id"
                      >
                        <td>{{ index+1 }}</td>
                        <td>{{ dupl.datavencimento | formataData }}</td>
                        <td>{{ dupl.valor | formataValor }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>

              <v-tab-item>
                <v-row class="mt-2">
                  <v-col class="d-flex flex-column" lg="12" cols="12">
                    <span class="text--secondary">Modalidade frete</span>
                    <span class="font-weight-bold">{{ pedido.modalidadefrete }}-{{ descricaoFrete(pedido.modalidadefrete) }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex flex-column" lg="12" cols="12">
                    <span class="text--secondary">Forma de pagamento</span>
                    <span class="font-weight-bold">{{ pedido.formapagamento }}-{{ descricaoForma(pedido.formapagamento) }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex flex-column" lg="12" cols="12">
                    <span class="text--secondary">Observação</span>
                    <span class="font-weight-bold">{{ pedido.observacao }}</span>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          large
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { statusPedidoVenda } from '@/functions'
import modalidadesfretes from '@/constantes/modalidadesfretes'
import formaspagamentos from '@/constantes/formaspagamentos'

export default {
  data(){
    return {
      tab: null,
      modalidadesfretes,
      formaspagamentos,
      statusPedidoVenda
    }
  },

	props: {
		dialog: Boolean,
    pedido: {
      type: Object,
      default(){
        return {
          cliente: null,
          vendedor: null,
          naturezaoperacao: null,
          produtos: null,
          duplicatas: null
        }
      }
    }
	},

  methods: {
    descricaoFrete(value){
      if (! value) return ''
      return this.modalidadesfretes.find(item => item.value == value).label
    },

    descricaoForma(value){
      if (! value) return ''
      return this.formaspagamentos.find(item => item.value == value).label
    },

    close(){
      this.tab = null
      this.$emit('fecha-dialog-pedido-venda')
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
</style>
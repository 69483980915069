<template>
  <div>
    <v-row>
      <v-col class="d-flex">
        <Titulo
          :titulo="'Cadastro de usuários'"
        />

        <v-spacer></v-spacer>

        <v-text-field
          v-if="mostraPesquisa"
          v-model="search"
          label="Pesquisar usuário"
          single-line
          hide-details
          outlined
          dense
          class="text-white rounded-r-0"
        ></v-text-field>

        <v-btn
          elevation="0"
          color="primary"
          height="40"
          :class="mostraPesquisa ? 'rounded-l-0' : ''"
          @click="mostraPesquisa = ! mostraPesquisa"
          v-if="! isMobile"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          v-if="isMobile"
          :to="{ name: 'NovoUsuario' }"
          color="primary"
          bottom
          fixed
          large
          right
          fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          elevation="0"
          class="ml-2"
          color="primary"
          :to="{ name: 'NovoUsuario' }"
          height="40"
          v-else
        >
          Novo usuário
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-card outlined :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')">
          <v-data-table
            :headers="headers"
            :items="$store.getters.usuarios"
            no-data-text="Nenhum usuário cadastrado"
            no-results-text="Nenhum usuário encontrado"
            loading-text="Carregando os usuários"
            :footer-props="{itemsPerPageText: 'Usuários por página', pageText: '{0}-{1} de {2}', itemsPerPageAllText: 'Tudo'}"
            style="display: inline-block; width: 100%; overflow: hidden"
            @current-items="(usuarios) => qtdusuarios = usuarios.length"
            class="grid-truncate"
            :items-per-page=15
            :search="search"
            @dblclick:row.prevent="($event, row) => alteraUsuario(row.item)"
            :disable-sort="isMobile"
            fixed-header
            :height="alturaMaxima"
          >
            <template v-slot:[`item.avatar`]="{ item }">
              <v-avatar
                size="32"
                rounded
              >
                <v-img
                  :src="item.avatar"
                  alt="Avatar do usuário"
                ></v-img>
              </v-avatar>
            </template>

            <template v-slot:[`item.ativo`]="{ item }">
              <v-chip
                label
                small
                class="white--text font-weight-bold"
                :color="item.ativo ? 'green' : 'red'"
              >
                {{ item.ativo ? 'Ativo' : 'Inativo' }}
              </v-chip>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formataData }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-bottom-sheet v-if="isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list rounded="t-xl">
                  <v-row dense class="mt-3">
                    <v-col class="text-center">
                      <v-avatar
                        style="border: 1px solid #e0e0e0"
                        size="48"
                      >
                        <v-img
                          :src="item.avatar"
                          v-bind="attrs"
                          v-on="on"
                          alt="Logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-col>
                  </v-row>
                  <v-row dense class="mb-3">
                    <v-col class="text-center">
                      <span class="font-weight-bold">{{ item.nome }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="obj in items"
                    :key="obj.id"
                    @click="obj.click(item)"
                    v-show="verificaMenu(obj,item)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ obj.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ obj.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-bottom-sheet>

              <div class="actionButtons" v-else>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    dense
                  >
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                      v-show="verificaMenu(obj,item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      search: '',
      headers: [
        {text: '', align: 'start', sortable: false, value: 'avatar'},
        {text: 'Nome', align: 'start', sortable: true, value: 'nome'},
        {text: 'E-mail', align: 'start', sortable: true, value: 'email'},
        {text: 'Tipo', align: 'start', sortable: true, value: 'tipo'},
				{text: 'Situação', align: 'start', sortable: false, value: 'ativo'},
				{text: 'Data cadastro', align: 'start', sortable: true, value: 'created_at'},
				{text: '', value: 'actions', sortable: false}
      ],
      items: [
        {id: 1, text: 'Editar', icon: 'mdi-pencil-outline', click: this.alteraUsuario},
        {id: 4, text: 'Ativar', icon: 'mdi-thumb-up-outline', click: this.ativaInativaUsuario},
        {id: 5, text: 'Inativar', icon: 'mdi-thumb-down-outline', click: this.ativaInativaUsuario},
        {id: 6, text: 'Alterar senha', icon: 'mdi-key-outline', click: this.alteraSenhaUsuario},
        {id: 7, text: 'Reenviar e-mail', icon: 'mdi-email-outline', click: this.reenviaEmail},
      ],
      cliente: {},
      id_cliente: 0,
      mostraPesquisa: false,
      qtdusuarios: 0
    }
  },

  components: {
    Titulo
  },

  methods: {
    verificaMenu(obj,item){
      return (obj.text !== 'Ativar' && obj.text !== 'Inativar') || (obj.text == 'Ativar' && ! item.ativo) || (obj.text == 'Inativar' && item.ativo)
    },

    alteraUsuario(usuario){
      const id = usuario.id

      this.$router.push({
        name: 'AlteraUsuario',
        params: {
          id_usuario: id
        }
      })
    },

    ativaInativaUsuario(usuario){
      const { id,nome,ativo } = usuario
      Vue.swal({
        title: ativo ? 'Bloquear usuário' : 'Desbloquear usuário',
        text: `Confirma o ${ativo ? 'bloqueio' : 'desbloqueio'} do usuário ${nome}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `Sim, ${ativo ? 'bloquear' : 'desbloquear'}`,
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed){
          this.$store.dispatch(ativo ? 'inativaUsuario' : 'ativaUsuario', id)
          .then(() => {
            this.$store.dispatch('buscaUsuarios', this.id_cliente)
            .then(() => {
              Vue.swal({
                icon: 'success',
                text: `Usuário ${ativo ? 'bloqueado' : 'desbloqueado'} com sucesso`,
                confirmButtonText: 'Fechar',
                timer: 5000
              })
            })
          })
        }
      })
    },

    alteraSenhaUsuario(usuario){
      const id = usuario.id

      this.$router.push({
        name: 'AlteraSenha',
        params: {
          id_usuario: id
        }
      })
    },

    reenviaEmail(usuario){
      const { id } = usuario
      this.$store.dispatch('reenviaEmailUsuario', id)
    }
  },

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 255
    },
  },

  created(){
    this.isMobile = window.innerWidth < 800
    const admin = this.$store.getters.usuario.tipo == 'administrador'
    if (! admin){
      this.$router.go(-1)
    }
    this.$store.dispatch('buscaUsuarios')
  },
}
</script>

<style>

</style>
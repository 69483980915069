<template>
  <v-dialog v-model="dialog" max-width="500px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">{{ indicador.descricao }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary pt-3">
        <v-row dense>
          <v-col>
            <v-switch
              v-model="selTodos"
              inset
              dense
              hide-details
              label="Gerencie os usuários com acesso a esta rotina"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-list>
              <v-list-item
                v-for="usuario in usuarios"
                :key="usuario.id"
                class="pa-0"
              >
                <v-list-item-avatar
                  :color="randomColor()"
                  class="rounded"
                >
                  <span class="white--text font-weight-bold">{{ usuario.nome | pegaIniciaisNomeUsuario }}</span>
                </v-list-item-avatar>
                <v-list-item-content class="d-flex justify-space-between">
                  {{ usuario.nome }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    v-model="ids_usuarios"
                    :value="usuario.id"
                    inset
                    dense
                    hide-details
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              @click="close()"
              block
              large
            >
              Fechar
            </v-btn>
          </v-col>
          <v-col lg="3" cols="6">
            <v-btn
              :disabled="usuarios.length == 0"
              elevation="0"
              color="primary"
              type="submit"
              @click="salva()"
              block
              large
            >
              Gravar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { randomColor } from '@/functions'

export default {
  data(){
    return {
      isMobile: false,
      selTodos: false,
      usuarios: [],
      ids_usuarios: [],
      randomColor
    }
  },

	props: {
		dialog: Boolean,
    indicador: {
      type: Object,
      default(){
        return {
          id: 0,
          descricao: 'Indicador'
        }
      }
    }
	},

  methods: {
    close(){
      this.selTodos = false
      this.$emit('fecha-dialog-usuarios-indicador')
    },

    salva(){
      const payload = {
        id_indicador: this.indicador.id,
        ids_usuarios: this.ids_usuarios
      }

      this.$store.dispatch('gravaUsuariosIndicador', payload)
      .then(() => {
        this.close()
      })
    }
  },

  watch: {
    dialog(){
      if (! this.dialog) return

      this.$store.dispatch('buscaUsuariosIndicador', this.indicador.id)
      .then(() => {
        this.ids_usuarios = this.$store.getters.indicadores_usuarios
        this.$store.dispatch('buscaUsuariosCliente')
        .then(() =>{
          const usuarios = this.$store.getters.usuarios_cliente
          if (usuarios){
            this.usuarios = usuarios.filter(usu => usu.tipo == 'operador')
          }
        })
      })
    },

    selTodos(){
      if (! this.dialog || ! this.usuarios) return

      if (this.selTodos){
        this.ids_usuarios = this.usuarios.map(usu => usu.id)
      } else {
        this.ids_usuarios = []
      }      
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
let modalidadesfretes = [
  { value: '0', label: 'POR CONTA DO REMETENTE' },
  { value: '1', label: 'POR CONTA DO DESTINATÁRIO' },
  { value: '2', label: 'POR CONTA DE TERCEIROS' },
  { value: '3', label: 'PRÓPRIO POR CONTA DO REMETENTE' },
  { value: '4', label: 'PRÓPRIO POR CONTA DO DESTINATÁRIO' },
  { value: '9', label: 'SEM OCORRÊNCIA DE TRANSPORTE' }
]

export default modalidadesfretes

<template>
  <div>
    <DialogDetalhes
      :dialog="dialog"
      :pedido="pedido"
      @fecha-dialog-pedido-liberacao="dialog = false"
      @atualiza-pedido-liberacao="carrega()"
    />

    <v-row>
      <v-col class="d-flex">
        <Titulo
          :titulo="'Pedidos de liberações'"
        />

        <v-spacer></v-spacer>

        <span v-if="!isMobile">
          <v-text-field
            v-if="mostraPesquisa"
            v-model="search"
            label="Pesquisar pedido"
            single-line
            hide-details
            outlined
            dense
            class="text-white rounded-r-0"
          ></v-text-field>
          <v-btn
            elevation="0"
            color="primary"
            height="40"
            :class="mostraPesquisa ? 'rounded-l-0' : ''"
            @click="mostraPesquisa = ! mostraPesquisa"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </span>

        <BtnFiltros :callback="processaFiltro" />

        <v-btn
          elevation="0"
          class="ml-2"
          color="primary"
          height="40"
          @click="carrega"
          v-if="! isMobile"
        >
          <v-icon
            small
            left
          >
            mdi-refresh
          </v-icon>
          Atualizar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="$store.getters.pedidos_liberacoes"
            no-data-text="Nenhum pedido encontrado"
            no-results-text="Nenhum pedido encontrado"
            loading-text="Carregando os pedidos de liberações"
            :footer-props="{itemsPerPageText: 'Itens por página', pageText: '{0}-{1} de {2}', itemsPerPageAllText: 'Tudo'}"
            @current-items="(pedidos) => qtdpedidos = pedidos.length"
            class="grid-truncate max-height"
            :items-per-page=15
            :search="search"
            @dblclick:row.prevent="($event, row) => detalhes(row.item)"
            :disable-sort="isMobile"
            fixed-header
            :height="alturaMaxima"
          >
            <template v-slot:[`item.datapedido`]="{ item }">
              {{ item.datapedido | formataData }} - {{ item.horapedido }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                label
                small
                class="white--text font-weight-bold"
                :color="verificaCorStatus(item.status)"
              >
                {{ verificaTextoStatus(item.status) }}
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-bottom-sheet v-if="isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list rounded="t-xl">
                  <v-layout justify-center class="py-5">
                    <span class="font-weight-bold"
                      >Pedido {{ item.id }}</span
                    >
                  </v-layout>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-bottom-sheet>

              <div class="actionButtons" v-else>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    dense
                  >
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import { EventBus } from '@/event-bus'
import Titulo from '@/components/geral/Titulo'
import DialogDetalhes from './DialogDetalhes'
import BtnFiltros from "@/components/pedidos_liberacoes/BtnFiltros"

export default {
  data(){
    return {
      isMobile: false,
      headers: [
        {text: 'Status', align: 'start', sortable: false, value: 'status'},
				{text: 'Solicitante', align: 'start', sortable: true, value: 'usuario.nome'},
				{text: 'Supervisor', align: 'start', sortable: true, value: 'usuariosuper.nome'},
				{text: 'Filial', align: 'start', sortable: true, value: 'filial'},
				{text: 'Data pedido', align: 'start', sortable: true, value: 'datapedido'},
				{text: '', value: 'actions', sortable: false}
      ],
      items: [
        {id: 1, text: 'Detalhes', icon: 'mdi-eye-outline', click: this.detalhes}
      ],
      mostraPesquisa: false,
      search: '',
      filtros: [],
      dialog: false,
      pedido: {
        status: '',
        usuario: {
          nome: ''
        },
        usuariosuper: {
          nome: ''
        },
        rotina: {
          descricao: ''
        },
        acao: {
          descricao: ''
        }
      },
      qtdpedidos: 0
    }
  },

  components: {
    Titulo,
    DialogDetalhes,
    BtnFiltros
  },

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 250
    },
  },

  methods: {
    detalhes(item){
      this.dialog = true
      this.pedido = item
    },

    verificaTextoStatus(status){
      if (status == 'L'){
        return 'Liberado'
      } else if (status == 'N'){
        return 'Negado'
      } else if (status == 'C'){
        return 'Cancelado'
      } else {
        return 'Pendente'
      }
    },

    verificaCorStatus(status){
      if (status == 'L'){
        return 'green'
      } else if (status == 'C' || status == 'N'){
        return 'red'
      } else {
        return 'yellow'
      }
    },

    processaFiltro(filtros) {
      this.filtros = filtros;
      this.carrega();
    },

    carrega(){
      this.$store.dispatch('buscaPedidosLiberacoes', this.filtros)
    },
  },

  created(){
    this.isMobile = window.innerWidth < 800
  },

  mounted(){
    EventBus.$on('atualiza-cliente', (_ret) => {
      this.carrega()
    })
  },

  beforeDestroy(){
    EventBus.$off('atualiza-cliente')
  }
}
</script>

<style scoped>
</style>
<template>
  <div class="d-flex align-center">
    <v-btn
      @click="$router.go(-1)"
      class="mr-2"
      icon
    >
      <v-icon color="primary">mdi-arrow-left</v-icon>
    </v-btn>

    <div class="d-flex flex-column">
      <h3 v-if="isMobile" class="d-flex align-center">{{ titulo }}</h3>
      <h2 v-else>{{ titulo }}</h2>
      <span v-if="subtitulo">{{ subtitulo }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false
    }
  },

  props: {
    titulo: {
      type: String
    },
    subtitulo: {
      type: String,
      default(){
        return ''
      }
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style>

</style>
<template>
  <div>
    <v-layout align-center>
      <v-btn
        icon
        class="mr-2"
        @click="$router.go(-1)"
      >
        <v-icon color="primary">mdi-arrow-left</v-icon>
      </v-btn>

      <h2>Contas a receber em aberto</h2>

      <v-spacer></v-spacer>

      <v-menu
        v-model="menuFiltros"
        :close-on-content-click="false"
        :nudge-width="260"
        max-width="400"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="filtros.length"
            :value="filtros.length"
            color="red"
            overlap
          >
            <v-btn
              elevation="0"
              color="primary"
              height="40"
              v-bind="attrs"
              v-on="on"
              outlined
            >
              <v-icon
                small
                left
              >
                mdi-filter-variant
              </v-icon>
              Filtros
            </v-btn>
          </v-badge>
        </template>
        <v-card max-width="380">
          <v-card-title>
            <h5>Filtros</h5>
          </v-card-title>
          <v-card-text>
            <v-expansion-panels
              v-model="panels"
              accordion
              dense
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="d-flex align-center">
                    <v-icon
                      small
                      left
                    >
                      mdi-domain
                    </v-icon>
                    Filiais
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-3 height-panel">
                  <v-row
                    v-for="fil in filiais"
                    :key="fil.codigo"
                  >
                    <v-checkbox
                      v-model="filtrosSel.filiais"
                      :label="`${fil.codigo}-${fil.razao}`"
                      :value="fil.codigo"
                      class="text-truncate"
                      hide-details
                      dense
                    ></v-checkbox>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="limpaFiltro"
              class="text-capitalize" 
              text
            >
              Limpar
            </v-btn>
            <v-btn 
              @click="aplicaFiltros"
              class="text-capitalize"
              color="primary"
              text
            >
              Aplicar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-btn
        elevation="0"
        color="primary"
        height="40"
        @click="carrega"
        class="ml-2"
      >
        <v-icon
          small
          left
        >
          mdi-refresh
        </v-icon>
        Atualizar
      </v-btn>
    </v-layout>
    <v-layout class="mt-6">
      <v-flex>
        <v-layout justify-space-between>
          <v-card
            v-for="card in cards"
            :key="card.id"
            class="rounded-xl"
            elevation="0"
            width="32%"
            outlined
          >
            <v-card-text class="text--primary">
              <v-layout>
                <span class="text--secondary">{{ card.titulo }}</span>
              </v-layout>
              <v-layout class="mt-3" justify-end>
                <h2 class="font-weight-bold">{{ card.valor | formataValor }}</h2>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-layout>
        <v-layout class="mt-3" align-center>
          <v-flex>
            <h4>Clientes</h4>
          </v-flex>
          <v-flex lg5>
            <v-text-field
              v-model="search"
              label="Pesquisar"
              prepend-inner-icon="mdi-magnify"
              clearable
              single-line
              hide-details
              dense
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout class="mt-3">
          <v-flex>
            <v-card outlined :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')">
              <v-data-table
                :headers="headers"
                :items="clientes"
                no-data-text="Nenhum cliente encontrado"
                no-results-text="Nenhum cliente encontrado"
                loading-text="Carregando os clientes"
                :footer-props="{itemsPerPageText: 'Itens por página', pageText: '{0}-{1} de {2}', itemsPerPageAllText: 'Tudo'}"
                @current-items="(clientes) => qtdclientes = clientes.length"
                class="grid-truncate cursor-pointer"
                :items-per-page=15
                :search="search"
                fixed-header
                :height="alturaMaxima"
              >
                <template v-slot:[`item.valor_total`]="{ item }">
                  {{ item.valor_total | formataValor }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <div class="actionButtons">
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list
                        dense
                      >
                        <v-list-item
                          v-for="obj in items"
                          :key="obj.id"
                          @click="obj.click(item)"
                        >
                          <v-list-item-icon>
                            <v-icon>{{ obj.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ obj.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false,
      search: '',
      documentos_a_faturar: [],
      contas_a_receber: [],
      total_a_faturar: 0,
      total_faturado: 0,
      cards: [
        {
          id: 1,
          titulo: 'Total a receber',
          valor: 0
        },
        {
          id: 2,
          titulo: 'Total faturado',
          valor: 0
        },
        {
          id: 3,
          titulo: 'Total a faturar',
          valor: 0
        }
      ],
      headers: [
        {text: 'Código', align: 'end', sortable: true, value: 'cod_cliente'},
        {text: 'Cliente', align: 'start', sortable: true, value: 'nome'},
        {text: 'Cidade', align: 'start', sortable: true, value: 'cidade'},
        {text: 'Valor total', align: 'end', sortable: true, value: 'valor_total'},
				{text: '', value: 'actions', sortable: false}
      ],
      clientes: [],
      qtdclientes: 0,
      items: [
        {id: 1, text: 'Detalhes', icon: 'mdi-eye-outline', click: this.detalhes}
      ],
      menuFiltros: false,
      filtros: [],
      panels: [],
      filiais: [],
      filtrosSel: {
        filiais: []
      }
    }
  },

  methods: {
    limpaFiltro(){
      this.filtros = []
      this.filtrosSel = {
        filiais: []
      }
      this.fechaFiltro()
      this.carrega()
    },

    aplicaFiltros(){
      this.fechaFiltro()
      this.carrega()
    },

    fechaFiltro(){
      this.panels = []
      this.menuFiltros = false
    },

    async buscaContasAReceberEmAberto(filtros){
      await this.$store.dispatch('buscaContasAReceberEmAberto', filtros)
      .then(() => {
        this.contas_a_receber = this.$store.getters.contas_a_receber
      })
    },

    async buscaDocumentosAFaturar(filtros){
      await this.$store.dispatch('buscaDocumentosAFaturar', filtros)
      .then(() => {
        this.documentos_a_faturar = this.$store.getters.documentos_a_faturar
      })
    },

    carrega(){
      this.filtros = []
      const { filiais } = this.filtrosSel

      for (const filial of filiais){
        this.filtros.push({
          campo: 'filial',
          valor: filial
        })
      }

      Promise.all([this.buscaContasAReceberEmAberto(this.filtros), this.buscaDocumentosAFaturar(this.filtros)])
      .then(() => {
        this.total_faturado = this.contas_a_receber.reduce((acc, cur) => acc + cur.valor_total, 0)
        this.total_a_faturar = this.documentos_a_faturar.reduce((acc, cur) => acc + cur.valor_total, 0)

        this.cards[0].valor = this.total_faturado + this.total_a_faturar
        this.cards[1].valor = this.total_faturado
        this.cards[2].valor = this.total_a_faturar

        const arrayClientes = [...this.contas_a_receber, ...this.documentos_a_faturar]

        this.clientes = []
        for (const cli of arrayClientes){
          const { cod_cliente,cliente,valor_total } = cli
          const index = this.clientes.findIndex(cli => cli.cod_cliente == cod_cliente)
          if (index > -1){
            this.clientes[index].valor_total += valor_total
          } else {
            this.clientes.push({
              cod_cliente,
              nome: cliente.nome,
              cpf_cnpj: cliente.cpf_cnpj,
              cidade: cliente.cidade+'-'+cliente.estado,
              valor_total
            })
          }
        }

        this.clientes.sort((a,b) => a.valor_total < b.valor_total ? 1 : -1)
      })
    },
  },

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 250
    },
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.carrega()
    this.$store.dispatch('buscaFiliais')
    .then(() => {
      this.filiais = this.$store.getters.filiais
    })
  }
}
</script>

<style scoped>
.height-panel {
  max-height: 300px;
  overflow-x: auto;
}
</style>
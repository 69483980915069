<template>
  <v-dialog v-model="dialog" max-width="550px" persistent scrollable :fullscreen="isMobile">
    <v-card :class="! isMobile ? 'rounded-xl' : ''">
      <v-card-title class="pa-5">
        <span class="font-weight-bold" style="font-size: 1rem">Confirmação do frete empresa</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text--primary">
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col>
              <VuetifyMoney
                v-model="form.valoempresaconf"
                label="Frete empresa"
                :rules="[v => !!v || 'Campo obrigatório']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              @click="close()"
              block
              large
            >
              Fechar
            </v-btn>
          </v-col>
          <v-col lg="3" cols="6">
            <v-btn
              elevation="0"
              color="primary"
              type="submit"
              @click="salva()"
              block
              large
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { removeMascaraNumero } from '@/functions'
import VuetifyMoney from '@/components/geral/VuetifyMoney'

export default {
  data(){
    return {
      isMobile: false,
      valid: null,
      form: {
        valoempresaconf: 0
      }
    }
  },

	props: {
		dialog: Boolean,
    id: Number
	},

  components: {
    VuetifyMoney
  },

  methods: {
    salva(){
      if (this.$refs.form.validate()){
        const payload = {
          id: this.id,
          form: this.form
        }
        this.$store.dispatch('confirmaFreteEmpresaOrdem', payload)
        .then(() => {
          this.$router.go(-1)
        })
      }
    },

    close(){
      this.$refs.form.reset()
      this.$emit('fecha-dialog-confirma-ordem')
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
</style>
let ramos = [
  { value: 'IN', label: 'INDUSTRIA' },
  { value: 'CM', label: 'COMERCIO' },
  { value: 'PR', label: 'PRODUTOR RURAL' },
  { value: 'PT', label: 'PRESTADOR DE TRANSPORTES' },
  { value: 'PE', label: 'PRESTADOR DE ENERGIA ELETRICA' },
  { value: 'PC', label: 'PRESTADOR DE COMUNICAÇÃO' },
  { value: 'OU', label: 'OUTROS' },
]

export default ramos

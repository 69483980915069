<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Novo pedido de venda'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          :class="(isMobile ? 'py-4' : 'pa-6') + ' rounded-xl'"
          outlined
        >
          <FormPedidoVenda
            @on-submit="grava"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'
import FormPedidoVenda from './FormPedidoVenda'

export default {
  data(){
    return {
      isMobile: false
    }
  },

  components: {
    Titulo,
    FormPedidoVenda
  },

  methods: {
    grava(form){
      this.$store.dispatch('gravaPedidoVenda', form)
      .then(() => {
        this.$router.go(-1)
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style scoped>
</style>
<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Altera pedido de venda'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          :class="(isMobile ? 'py-4' : 'pa-6') + ' rounded-xl'"
          outlined
        >
          <FormPedidoVenda
            :pedido_venda="pedido"
            @on-submit="altera"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'
import FormPedidoVenda from './FormPedidoVenda'

export default {
  data(){
    return {
      isMobile: false,
      id: 0,
      pedido: {}
    }
  },

  components: {
    Titulo,
    FormPedidoVenda
  },

  methods: {
    altera(form){
      const payload = {
        id: this.id,
        form
      }

      this.$store.dispatch('alteraPedidoVenda', payload)
      .then(() => {
        this.$router.go(-1)
      })
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.id = Number(this.$route.params.id_pedido)
    this.$store.dispatch('buscaPedidoVendaPorId', this.id)
    .then(() => {
      this.pedido = this.$store.getters.pedido_venda
    })
  }
}
</script>

<style scoped>
</style>
<template>
  <v-layout fill-height justify-center align-center flex-column>
    <h1 v-if="isMobile" style="font-size: 7rem">404</h1>
    <h1 v-else style="font-size: 15rem">404</h1>
    <p v-if="isMobile" style="font-size: 1.5rem">Página não encontrada</p>
    <p v-else style="font-size: 2rem">Página não encontrada</p>
  </v-layout>
</template>

<script>
export default {
  data(){
    return {
      isMobile: false
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
  }
}
</script>

<style>

</style>
import axios from '@/plugins/axios'

const state = {
  ordem_coleta: {},
};

const getters = {
  ordem_coleta(state){
    return state.ordem_coleta
  },
};

const mutations = {
  ordem_coleta(state, ordem_coleta){
    state.ordem_coleta = ordem_coleta
  },
};

const actions = {
  async buscaOrdemColeta({commit}, cod_rastreamento){
    commit('loading', true)
    await axios.get(`/OrdensColetas/BuscaOrdemColeta/${cod_rastreamento}/`)
    .then(res => {
      commit('ordem_coleta', res.data.ordem_coleta)
    }).finally(() => {
      commit('loading', false)
    })
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}
<template>
  <div>
    <v-row>
      <v-col>
        <Titulo
          :titulo="'Token para integração ao MasterTransp/MasterNFe'"
          :subtitulo="cliente.nome"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">
        <v-card
          :class="(isMobile ? 'pa-3' : 'pa-6') + ' rounded-xl'"
          outlined
        >
          <v-card-text class="text--primary">
            <v-row v-if="token">
              <v-col class="text-center">
                <span class="font-weight-bold cursor-pointer" @click="copiaToken">
                  {{ token }}
                  <v-icon small class="pl-1">{{ icone }}</v-icon>
                </span>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-btn
                elevation="0"
                :loading="$store.getters.isLoadingToken"
                :block="isMobile"
                @click="geraToken"
              >
                <v-icon
                  left
                >
                  mdi-lock-outline
                </v-icon>
                Gerar token
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      id: 0,
      token: '',
      icone: 'mdi-content-copy',
      cliente: {}
    }
  },

  components: {
    Titulo
  },

  methods: {
    copiaToken(){
      navigator.clipboard.writeText(this.token)
      this.icone = 'mdi-check'
      setTimeout(() => {
        this.icone = 'mdi-content-copy'
      }, 2000)
    },

    buscaTokenCliente(){
      this.$store.dispatch('buscaTokenCliente', this.id)
      .then(() => {
        this.token = this.$store.getters.tokenCliente
      })
    },

    geraToken(){
     this.$store.dispatch('geraTokenCliente', this.id)
     .then(() => {
        this.buscaTokenCliente()
     }) 
    }
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.id = Number(this.$route.params.id_cliente)
    this.$store.dispatch('buscaClientePorId', this.id)
    .then(() => {
      this.cliente = this.$store.getters.cliente
      this.buscaTokenCliente()
    })
  }
}
</script>

<style>

</style>
<template>
  <div>
    <DialogDetalhes
      :dialog="dialogDetalhes"
      :id="idnotafiscal"
      @fecha-dialog-nota-xml="dialogDetalhes = false"
    />

    <v-row>
      <v-col class="d-flex">
        <Titulo
          :titulo="'Notas emitidas contra o CNPJ'"
        />

        <v-spacer></v-spacer>

        <span v-if="!isMobile">
          <v-text-field
            v-if="mostraPesquisa"
            v-model="search"
            label="Pesquisar nota fiscal"
            single-line
            hide-details
            outlined
            dense
            class="text-white rounded-r-0"
          ></v-text-field>

          <v-btn
            elevation="0"
            color="primary"
            height="40"
            :class="mostraPesquisa ? 'rounded-l-0' : ''"
            @click="mostraPesquisa = !mostraPesquisa"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </span>

        <BtnFiltros :callback="processaFiltro" />

        <v-btn
          elevation="0"
          class="ml-2"
          color="primary"
          height="40"
          @click="carrega"
          v-if="! isMobile"
        >
          <v-icon small left> mdi-refresh </v-icon>
          Atualizar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')">
          <v-data-table
            :headers="headers"
            :items="$store.getters.notas_xmls"
            no-data-text="Nenhuma nota encontrada"
            no-results-text="Nenhuma nota encontrada"
            loading-text="Carregando as notas emitidas contra CNPJ"
            :footer-props="{
              itemsPerPageText: 'Itens por página',
              pageText: '{0}-{1} de {2}',
              itemsPerPageAllText: 'Tudo',
            }"
            @current-items="(notas) => (qtdnotas = notas.length)"
            :items-per-page="15"
            :search="search"
            :disable-sort="isMobile"
            @dblclick:row.prevent="($event, row) => detalhes(row.item)"
            :class="isMobile ? '' : 'grid-truncate'"
            :height="alturaMaxima"
            fixed-header
          >
            <template v-slot:[`item.situacao`]="{ item }">
              <v-chip
                label
                small
                class="white--text font-weight-bold"
                :color="statusNotaXml(item.situacao).cor"
              >
                {{ statusNotaXml(item.situacao).label }}
              </v-chip>
            </template>

            <template v-slot:[`item.cpfcnpjfilial`]="{ item }">
              {{ item.cpfcnpjfilial | formataCpfCnpj }}
            </template>

            <template v-slot:[`item.dataemissao`]="{ item }">
              {{ item.dataemissao | formataData }}
            </template>

            <template v-slot:[`item.valornfe`]="{ item }">
              {{ item.valornfe | formataValor }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-bottom-sheet v-if="isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list rounded="t-xl">
                  <v-layout justify-center class="py-5">
                    <span class="font-weight-bold"
                      >Nota fiscal {{ item.numero }}</span
                    >
                  </v-layout>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-bottom-sheet>

              <div class="actionButtons" v-else>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus"
import Titulo from '@/components/geral/Titulo'
import DialogDetalhes from "./DialogDetalhes"
import BtnFiltros from "@/components/notas_xml/BtnFiltros"
import { statusNotaXml } from "@/functions"

export default {
  data() {
    return {
      isMobile: false,
      colunas: [
        { text: "Situação", sortable: false, value: "situacao", showIfMobile: true },
        { text: "Número", sortable: true, value: "numero", showIfMobile: true },
        { text: "Data", sortable: true, value: "dataemissao", showIfMobile: true },
        { text: "CNPJ filial", sortable: true, value: "cpfcnpjfilial", showIfMobile: true },
        { text: "Emitente", sortable: true, value: "nomeemitente", showIfMobile: true },
        { text: "Destinatário", sortable: true, value: "nomedestinatario", showIfMobile: true },
        { text: "Chave", sortable: true, value: "chave", showIfMobile: false },
        { text: "Valor NFe", sortable: true, value: "valornfe", showIfMobile: true },
        { text: "", value: "actions", sortable: false, showIfMobile: true },
      ],
      headers: [],
      items: [
        {
          id: 1,
          text: "Detalhes",
          icon: "mdi-eye-outline",
          click: this.detalhes,
        },
      ],
      mostraPesquisa: false,
      search: "",
      filtros: [],
      qtdnotas: 0,
      dialogDetalhes: false,
      idnotafiscal: 0,
      statusNotaXml
    }
  },

  components: {
    Titulo,
    DialogDetalhes,
    BtnFiltros,
  },

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 250
    },
  },

  methods: {
    detalhes(nota) {
      this.idnotafiscal = nota.id;
      this.dialogDetalhes = true;
    },

    processaFiltro(filtros) {
      this.filtros = filtros
      this.carrega()
    },

    carrega() {
      this.$store.dispatch("buscaNotasXmls", this.filtros)
    },
  },

  created() {
    this.isMobile = window.innerWidth < 800
    this.headers = this.colunas.filter(coluna => this.isMobile ? coluna.showIfMobile : true)
  },

  mounted() {
    EventBus.$on("atualiza-cliente", (_ret) => {
      this.carrega();
    });
  },

  beforeDestroy() {
    EventBus.$off("atualiza-cliente");
  },
};
</script>

<style scoped>
</style>
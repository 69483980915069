<template>
  <div>
		<DialogArquivosBackup :dialog="dialogBackup" :id="id_backup" @fecha-dialog-arquivos-backup="dialogBackup = false" />
    <v-row>
      <v-col class="d-flex">
        <Titulo
          :titulo="'Backups do cliente'"
          :subtitulo="cliente.nome"
        />

        <v-spacer></v-spacer>

        <v-text-field
          v-if="mostraPesquisa"
          v-model="search"
          label="Pesquisar backup"
          single-line
          hide-details
          outlined
          dense
          class="text-white rounded-r-0"
        ></v-text-field>

        <v-btn
          elevation="0"
          color="primary"
          height="40"
          :class="mostraPesquisa ? 'rounded-l-0' : ''"
          @click="mostraPesquisa = ! mostraPesquisa"
          v-if="! isMobile"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined :class="'rounded-xl '+(! isMobile ? 'pa-2' : '')">
          <v-data-table
            :headers="headers"
            :items="$store.getters.backups_cliente"
            no-data-text="Nenhum backup cadastrado"
            no-results-text="Nenhum backup encontrado"
            loading-text="Carregando os backups"
            :footer-props="{itemsPerPageText: 'Backups por página', pageText: '{0}-{1} de {2}', itemsPerPageAllText: 'Tudo'}"
            @current-items="(backups) => qtdbackups = backups.length"
            class="grid-truncate"
            :items-per-page=15
            :search="search"
            @dblclick:row.prevent="($event, row) => ! isMobile ? detalhaBackup(row.item) : ''"
            :disable-sort="isMobile"
            fixed-header
            :height="alturaMaxima"
          >

            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formataDataHora }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-bottom-sheet v-if="isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list rounded="t-xl">
                  <v-row dense class="my-3">
                    <v-col class="text-center">
                      <span class="font-weight-bold">{{ item.id }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-list-item
                        v-for="obj in items"
                        :key="obj.id"
                        @click="obj.click(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ obj.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ obj.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-list>
              </v-bottom-sheet>

              <div class="actionButtons" v-else>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="obj in items"
                      :key="obj.id"
                      @click="obj.click(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ obj.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ obj.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Titulo from '@/components/geral/Titulo'
import DialogArquivosBackup from '@/dialogs/DialogArquivosBackup'

export default {
	data(){
		return {
      isMobile: false,
			mostraPesquisa: false,
			dialogBackup: false,
			id_backup: 0,
			search: '',
      id: 0,
      cliente: {},
      headers: [
        {text: 'ID', align: 'start', sortable: false, value: 'id'},
				{text: 'Data backup', align: 'start', sortable: true, value: 'created_at'},
				{text: '', value: 'actions', sortable: false}
      ],
      items: [
        {id: 1, text: 'Detalhes', icon: 'mdi-eye-outline', click: this.detalhaBackup},
      ],
		}
	},

	methods: {
		detalhaBackup(backup){
			const { id } = backup

			this.id_backup = id
			this.dialogBackup = true
		}
	},

  computed: {
    alturaMaxima(){
      return this.$vuetify.breakpoint.height - 250
    },
  },

	components: {
		Titulo,
		DialogArquivosBackup
	},

  created(){
    this.isMobile = window.innerWidth < 800
    const admin = this.$store.getters.usuario.tipo == 'administrador'
    if (! admin){
      this.$router.go(-1)
    }

    this.id = Number(this.$route.params.id_cliente)
    this.$store.dispatch('buscaClientePorId', this.id)
    .then(() => {
      this.cliente = this.$store.getters.cliente
			this.$store.dispatch('buscaBackupsCliente', this.id)
    })
  }
}
</script>

<style>

</style>
let formaspagamentos = [
  { value: '01', label: 'DINHEIRO' },
  { value: '02', label: 'CHEQUE' },
  { value: '03', label: 'CARTÃO DE CRÉDITO' },
  { value: '04', label: 'CARTÃO DE DÉBITO' },
  { value: '05', label: 'CRÉDITO LOJA' },
  { value: '10', label: 'VALE ALIMENTAÇÃO' },
  { value: '11', label: 'VALE REFEIÇÃO' },
  { value: '12', label: 'VALE PRESENTE' },
  { value: '13', label: 'VALE COMBUSTÍVEL' },
  { value: '14', label: 'DUPLICATA MERCANTIL' },
  { value: '15', label: 'BOLETO BANCÁRIO' },
  { value: '16', label: 'DEPÓSITO BANCÁRIO' },
  { value: '17', label: 'PIX' },
  { value: '18', label: 'TRANSFERÊNCIA BANCÁRIA' },
  { value: '19', label: 'PROGRAMA DE FIDELIDADE' },
  { value: '90', label: 'SEM PAGAMENTO' },
  { value: '99', label: 'OUTROS' }
]

export default formaspagamentos

import axios from '@/plugins/axios'
import { mostraMsgPadrao } from '@/functions'
import moment from 'moment'

const state = {
	pedidos_liberacoes: [],
	pedidos_liberacoes_pendentes: [],
	notas_xmls: [],
	nota_xml: {},
	filiais: [],
	filial: {},
	ordens_risco: [],
	ordem_risco: {},
	ordens_confirma: [],
	ordem_confirma: {},
	documentos_a_faturar: [],
	contas_a_receber: [],
	naturezas_op: [],
  condicoes_pagamentos: [],
	empresas: [],
	loading_empresas: false,
	produtos: [],
	loading_produtos: false,
	pedido_venda_calculo: {},
	pedidos_vendas: [],
	pedido_venda: {},
	totalPedidos: 0,
	loadingPedidosPendentes: false,
  duplicatas_pedido: [],
  empresa_ws: {}
};

const getters = {
	pedidos_liberacoes(state) {
		return state.pedidos_liberacoes
	},
	pedidos_liberacoes_pendentes(state) {
		return state.pedidos_liberacoes_pendentes
	},
	notas_xmls(state) {
		return state.notas_xmls
	},
	nota_xml(state) {
		return state.nota_xml
	},
	filiais(state) {
		return state.filiais
	},
	filial(state) {
		return state.filial
	},
	ordens_risco(state) {
		return state.ordens_risco
	},
	ordem_risco(state) {
		return state.ordem_risco
	},
	ordens_confirma(state) {
		return state.ordens_confirma
	},
	ordem_confirma(state) {
		return state.ordem_confirma
	},
	documentos_a_faturar(state) {
		return state.documentos_a_faturar
	},
	contas_a_receber(state) {
		return state.contas_a_receber
	},
	naturezas_op(state) {
		return state.naturezas_op
	},
  condicoes_pagamentos(state){
    return state.condicoes_pagamentos
  },
	empresas(state) {
		return state.empresas
	},
	loading_empresas(state) {
		return state.loading_empresas
	},
	produtos(state) {
		return state.produtos
	},
	loading_produtos(state) {
		return state.loading_produtos
	},
	pedido_venda_calculo(state) {
		return state.pedido_venda_calculo
	},
	pedidos_vendas(state) {
		return state.pedidos_vendas
	},
	pedido_venda(state) {
		return state.pedido_venda
	},
	totalPedidos(state) {
		return state.totalPedidos
	},
	loadingPedidosPendentes(state) {
		return state.loadingPedidosPendentes
	},
  duplicatas_pedido(state){
    return state.duplicatas_pedido
  },
  empresa_ws(state){
    return state.empresa_ws
  }
};

const mutations = {
	pedidos_liberacoes(state, pedidos_liberacoes) {
		state.pedidos_liberacoes = pedidos_liberacoes
	},
	pedidos_liberacoes_pendentes(state, pedidos_liberacoes_pendentes) {
		state.pedidos_liberacoes_pendentes = pedidos_liberacoes_pendentes
	},
	notas_xmls(state, notas_xmls) {
		state.notas_xmls = notas_xmls
	},
	nota_xml(state, nota_xml) {
		state.nota_xml = nota_xml
	},
	filiais(state, filiais) {
		state.filiais = filiais
	},
	filial(state, filial) {
		state.filial = filial
	},
	ordens_risco(state, ordens_risco) {
		state.ordens_risco = ordens_risco
	},
	ordem_risco(state, ordem_risco) {
		state.ordem_risco = ordem_risco
	},
	ordens_confirma(state, ordens_confirma) {
		state.ordens_confirma = ordens_confirma
	},
	ordem_confirma(state, ordem_confirma) {
		state.ordem_confirma = ordem_confirma
	},
	documentos_a_faturar(state, documentos_a_faturar) {
		state.documentos_a_faturar = documentos_a_faturar
	},
	contas_a_receber(state, contas_a_receber) {
		state.contas_a_receber = contas_a_receber
	},
	naturezas_op(state, naturezas_op) {
		state.naturezas_op = naturezas_op
	},
  condicoes_pagamentos(state, condicoes_pagamentos){
    state.condicoes_pagamentos = condicoes_pagamentos
  },
	empresas(state, empresas) {
		state.empresas = empresas
	},
	adicionaEmpresa(state, empresa) {
		const index = state.empresas.findIndex(emp => emp.codigo == empresa.codigo)
		if (index < 0) {
			state.empresas.push(empresa)
		}
	},
	loading_empresas(state, loading) {
		state.loading_empresas = loading
	},
	produtos(state, produtos) {
		state.produtos = produtos
	},
	adicionaProduto(state, produto) {
		const index = state.produtos.findIndex(pro => pro.codigo == produto.codigo)
		if (index < 0) {
			state.produtos.push(produto)
		}
	},
	loading_produtos(state, loading) {
		state.loading_produtos = loading
	},
	pedido_venda_calculo(state, pedido_venda) {
		state.pedido_venda_calculo = pedido_venda
	},
	pedidos_vendas(state, pedidos) {
		state.pedidos_vendas = pedidos
	},
	pedido_venda(state, pedido) {
		state.pedido_venda = pedido
	},
	totalPedidos(state, qtde) {
		state.totalPedidos = qtde
	},
	loadingPedidosPendentes(state, loading) {
		state.loadingPedidosPendentes = loading
	},
  duplicatas_pedido(state, duplicatas){
    state.duplicatas_pedido = duplicatas
  },
  empresa_ws(state, empresa_ws){
    state.empresa_ws = empresa_ws
  }
};

const actions = {
	async buscaPedidosLiberacoes({ commit }, filtros) {
		const params = filtros.reduce((acum, atual, index) => acum + (index > 0 ? '&' : '?') + (atual.campo + '=' + atual.valor), '')
		commit('pedidos_liberacoes', [])
		commit('loading', true)
		await axios.get(`/Api/PedidosLiberacoes${params}`)
			.then(res => {
				commit('pedidos_liberacoes', res.data.pedidosliberacao)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaPedidosLiberacoesPendentes({ commit }) {
		commit('loadingPedidosPendentes', true)
		commit('pedidos_liberacoes_pendentes', [])
		await axios.get('/Api/PedidosLiberacoes?status=P')
			.then(res => {
				commit('pedidos_liberacoes_pendentes', res.data.pedidosliberacao)
			}).finally(() => {
				commit('loadingPedidosPendentes', false)
			})
	},

	async liberaPedidoLiberacao({ commit }, id) {
		commit('loading', true)
		await axios.put(`/Api/PedidosLiberacoes/Libera/${id}`)
			.then(() => {
				mostraMsgPadrao('Pedido liberado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async negaPedidoLiberacao({ commit }, id) {
		commit('loading', true)
		await axios.put(`/Api/PedidosLiberacoes/Nega/${id}`)
			.then(() => {
				mostraMsgPadrao('Pedido negado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaNotasXmls({ commit }, filtros) {
		const params = filtros.reduce((acum, atual, index) => acum + (index > 0 ? '&' : '?') + (atual.campo + '=' + atual.valor), '')
		commit('notas_xmls', [])
		commit('loading', true)
		await axios.get(`/Api/NotasXml${params}`)
			.then(res => {
				commit('notas_xmls', res.data.notasXml)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaNotasXmlHoje({ commit }) {
		const data_hoje = moment().format('YYYY-MM-DD')
		commit('notas_xmls', [])
		commit('loading', true)
		await axios.get(`/Api/NotasXml?data_inicial=${data_hoje}&data_final=${data_hoje}`)
			.then(res => {
				commit('notas_xmls', res.data.notasXml.splice(0,5))
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaNotaXmlPorId({ commit }, id) {
		commit('nota_xml', {})
		commit('loading', true)
		await axios.get(`/Api/NotasXml/${id}`)
			.then(res => {
				commit('nota_xml', res.data.notaxml)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaFiliais({ commit }) {
		commit('filiais', [])
		commit('loading', true)
		await axios.get('/Api/Filiais')
			.then(res => {
				commit('filiais', res.data.filiais)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaGerenciamentoRiscos({ commit }) {
		commit('ordens_risco', [])
		commit('loading', true)
		await axios.get('/Api/BuscaGerenciamentosRisco')
			.then(res => {
				commit('ordens_risco', res.data.riscos)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaGerenciamentoRiscoPorId({ commit }, id) {
		commit('ordem_risco', {})
		commit('loading', true)
		await axios.get(`/Api/BuscaGerenciamentosRisco/${id}`)
			.then(res => {
				commit('ordem_risco', res.data.risco)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async liberaRiscoTemporario({ commit }, id) {
		commit('loading', true)
		await axios.put(`/Api/LiberaRisco/${id}`)
			.then(() => {
				mostraMsgPadrao('Carregamento liberado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaOrdensConfirmacao({ commit }) {
		commit('loading', true)
		await axios.get('/Api/BuscaOrdensConfirmacao')
			.then(res => {
				commit('ordens_confirma', res.data.ordens)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaOrdemConfirmacaoPorId({ commit }, id) {
		commit('loading', true)
		await axios.get(`/Api/BuscaOrdensConfirmacao/${id}`)
			.then(res => {
				commit('ordem_confirma', res.data.ordem)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async confirmaFreteEmpresaOrdem({ commit }, payload) {
		const { id, form } = payload
		commit('loading', true)
		await axios.put(`/Api/ConfirmaFreteEmpresaOrdem/${id}`, form)
			.then(() => {
				mostraMsgPadrao('Frete confirmado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaDocumentosAFaturar({ commit }, filtros) {
		let params = ''
		if (filtros) {
			params = filtros.reduce((acum, atual, index) => acum + (index > 0 ? '&' : '?') + (atual.campo + '=' + atual.valor), '')
		}

		commit('loading', true)
		await axios.get(`/Api/DocumentosAFaturarPorClientes${params}`)
			.then(res => {
				commit('documentos_a_faturar', res.data.clientes)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaContasAReceberEmAberto({ commit }, filtros) {
		let params = ''
		if (filtros) {
			params = filtros.reduce((acum, atual, index) => acum + (index > 0 ? '&' : '?') + (atual.campo + '=' + atual.valor), '')
		}

		commit('loading', true)
		await axios.get(`/Api/ContasAReceberEmAbertoPorClientes${params}`)
			.then(res => {
				commit('contas_a_receber', res.data.clientes)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaNaturezasOperacao({ commit }) {
		commit('loading', true)
		await axios.get('/Api/NaturezasOperacoes')
			.then(res => {
				commit('naturezas_op', res.data.naturezas)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaCondicoesPagamentos({ commit }) {
		commit('loading', true)
		await axios.get('/Api/CondicoesPagamentos')
			.then(res => {
				commit('condicoes_pagamentos', res.data.condicoes)
			}).finally(() => {
				commit('loading', false)
			})
	},

  async calculaParcelasPedido({ commit }, body){
    commit('loading', true)
    commit('duplicatas_pedido', [])
    await axios.post('/Api/CondicoesPagamentos/CalculaParcelas', body)
      .then(res => {
        commit('duplicatas_pedido',res.data.parcelas)
      }).finally(() => {
        commit('loading', false)
      })
  },

	async buscaEmpresas({ commit }, params) {
		commit('loading_empresas', true)
		await axios.get('/Api/Empresas', { params })
			.then(res => {
				commit('empresas', res.data.empresas)
			}).finally(() => {
				commit('loading_empresas', false)
			})
	},

	async gravaEmpresa({ commit }, empresa) {
		commit('loading', true)
		await axios.post('/Api/empresas', empresa)
			.then(_res => {
				mostraMsgPadrao('Empresa cadastrada com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaProdutos({ commit }, params) {
		commit('loading_produtos', true)
		await axios.get('/Api/Produtos', { params })
			.then(res => {
				commit('produtos', res.data.produtos)
			}).finally(() => {
				commit('loading_produtos', false)
			})
	},

	async calculaPedidoVenda({ commit }, payload) {
		commit('loading', true)
		commit('pedido_venda_calculo', {})
		await axios.post('/Api/PedidosVendas/Calcula', payload)
			.then(res => {
				commit('pedido_venda_calculo', res.data.pedido)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async gravaPedidoVenda({ commit }, payload) {
		commit('loading', true)
		await axios.post('/Api/PedidosVendas', payload)
			.then(_res => {
				mostraMsgPadrao('Pedido cadastrado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async alteraPedidoVenda({ commit }, payload) {
		const { id, form } = payload
		commit('loading', true)
		await axios.put(`/Api/PedidosVendas/${id}`, form)
			.then(_res => {
				mostraMsgPadrao('Pedido alterado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaPedidosVendas({ commit }, params) {
		commit('loading', true)
		await axios.get('/Api/PedidosVendas', { params })
			.then(res => {
				commit('pedidos_vendas', res.data.pedidos)
				commit('totalPedidos', res.data.totalPedidos)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaPedidoVendaPorId({ commit }, id) {
		commit('loading', true)
		await axios.get(`Api/PedidosVendas/${id}`)
			.then(res => {
				commit('pedido_venda', res.data.pedido)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async geraPdfPedidoVenda({ commit }, id) {
		commit('loading', true)
		await axios.get(`Api/PedidosVendas/${id}/Pdf`, { responseType: 'blob' })
			.then(res => {
				const pedido_venda_pdf = res.data
				const pdfBlob = new Blob([pedido_venda_pdf], { type: 'application/pdf' })
				const downloadUrl = URL.createObjectURL(pdfBlob)
				window.open(downloadUrl)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async emailPedidoVenda({ commit }, payload) {
		const { id, emails } = payload
		const body = {
			emails
		}

		commit('loading', true)
		await axios.post(`Api/PedidosVendas/${id}/Email`, body)
			.then(() => {
				mostraMsgPadrao('E-mail enviado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

  async BuscaDadosEmpresaWS({ commit }, cnpj){
    commit('empresa_ws', {})
		commit('loading', true)
    await axios.get(`/Outros/BuscaDadosEmpresaWS/${cnpj}`)
      .then(res => {
        commit('empresa_ws', res.data.empresa)
      }).finally(() => {
        commit('loading', false)
      })
  }
};

export default {
	state,
	getters,
	mutations,
	actions
}
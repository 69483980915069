<template>
  <div>
    <DialogConfirma :dialog="dialog" :id="id" @fecha-dialog-confirma-ordem="dialog = false" />

    <v-row>
      <v-col>
        <Titulo
          :titulo="'Ordem pendente de confirmação'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          class="pa-3 rounded-xl"
          outlined
        >
          <v-card-text class="text--primary">
            <v-row dense>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Nª ordem</span>
                <span class="font-weight-bold">{{ ordem_confirma.ordem }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Emissão</span>
                <span class="font-weight-bold">{{ ordem_confirma.ordem_carga.dataemissao |formataData }} {{ ordem_confirma.ordem_carga.horaemissao }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Motorista</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.nommotorista }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Proprietário</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.nomproprietario }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Remetente</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.nomremetente }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Destinatário</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.nomdestinatario }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Placa cavalo</span>
                <span class="font-weight-bold">{{ ordem_confirma.ordem_carga.cavalo }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Cidade cavalo</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.cidcavalo }} - {{ ordem_confirma.ordem_carga.estcavalo }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12" v-if="ordem_confirma.ordem_carga.carreta.trim()">
                <span class="text--secondary">Placa 1º carreta</span>
                <span class="font-weight-bold">{{ ordem_confirma.ordem_carga.carreta }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12" v-if="ordem_confirma.ordem_carga.carreta.trim()">
                <span class="text--secondary">Cidade 1º carreta</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.cidcarreta }} - {{ ordem_confirma.ordem_carga.estcarreta }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12" v-if="ordem_confirma.ordem_carga.bitrem.trim()">
                <span class="text--secondary">Placa 2º carreta</span>
                <span class="font-weight-bold">{{ ordem_confirma.ordem_carga.bitrem }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12" v-if="ordem_confirma.ordem_carga.bitrem.trim()">
                <span class="text--secondary">Cidade 2º carreta</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.cidbitrem }} - {{ ordem_confirma.ordem_carga.estbitrem }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12" v-if="ordem_confirma.ordem_carga.tritrem.trim()">
                <span class="text--secondary">Placa 3º carreta</span>
                <span class="font-weight-bold">{{ ordem_confirma.ordem_carga.tritrem }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12" v-if="ordem_confirma.ordem_carga.tritrem.trim()">
                <span class="text--secondary">Cidade 3º carreta</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.ordem_carga.cidtritrem }} - {{ ordem_confirma.ordem_carga.esttritrem }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Peso total (TN)</span>
                <span class="font-weight-bold">{{ ordem_confirma.ordem_carga.pesototal / 1000 | formataPesoQtde }}</span>
              </v-col>
              <v-col class="d-flex flex-column" lg="6" cols="12">
                <span class="text--secondary">Frete empresa</span>
                <span class="font-weight-bold text-truncate">{{ ordem_confirma.valorempresa | formataValor }}{{ ordem_confirma.tipovalor == 'T' ? '/TN' : '' }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-end">
        <v-btn
          elevation="0"
          color="success"
          @click="dialog = true"
          :block="isMobile"
        >
          Confirmar frete empresa
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DialogConfirma from './DialogConfirma'
import Titulo from '@/components/geral/Titulo'

export default {
  data(){
    return {
      isMobile: false,
      id: 0,
      dialog: false,
      ordem_confirma: {
        ordem: 0,
        filial: 0,
        valorempresa: 0,
        tipovalor: '',
        ordem_carga: {
          dataemissao: '',
          horaemissao: '',
          nommotorista: '',
          nomproprietario: '',
          cavalo: '',
          cidcavalo: '',
          estcavalo: '',
          carreta: '',
          cidcarreta: '',
          estcarreta: '',
          bitrem: '',
          cidbitrem: '',
          estbitrem: '',
          tritrem: '',
          cidtritrem: '',
          esttritrem: '',
          pesototal: 0,
          nomremetente: '',
          nomdestinatario: ''
        }
      },
    }
  },

  components: {
    Titulo,
    DialogConfirma
  },

  methods: {
  },

  created(){
    this.isMobile = window.innerWidth < 800
    this.id = Number(this.$route.params.id)
    this.$store.dispatch('buscaOrdemConfirmacaoPorId', this.id)
    .then(() => {
      this.ordem_confirma = this.$store.getters.ordem_confirma
    })
  }
}
</script>

<style>

</style>
import axios from '@/plugins/axios'
import { mostraMsgPadrao } from '@/functions'
import { EventBus } from '@/event-bus'

const state = {
	isLoadingAvatar: false,
	usuarios: [],
	usuario: {},
	clientes_usuario: [],
	cliente_usuario: {},
	usuario_alt: {},
	configuracoes: {},
	itens_menu: [],
	usuarios_cliente: [],
	indicadores_usuarios: [],
	usuario_indicadores: [],
	indicadores_sistema: [],
};

const getters = {
	isLoadingAvatar(state) {
		return state.isLoadingAvatar
	},
	usuarios(state) {
		return state.usuarios
	},
	usuario(state) {
		return state.usuario
	},
	usuario_alt(state) {
		return state.usuario_alt
	},
	clientes_usuario(state) {
		return state.clientes_usuario
	},
	cliente_usuario(state) {
		return state.cliente_usuario
	},
	configuracoes(state) {
		return state.configuracoes
	},
	itens_rotinas(state) {
		return state.itens_rotinas
	},
	itens_menu(state) {
		return state.itens_menu
	},
	usuarios_cliente(state) {
		return state.usuarios_cliente
	},
	indicadores_usuarios(state) {
		return state.indicadores_usuarios
	},
	usuario_indicadores(state) {
		return state.usuario_indicadores
	},
	indicadores_sistema(state) {
		return state.indicadores_sistema
	}
}

const mutations = {
	isLoadingAvatar(state, loading) {
		state.isLoadingAvatar = loading
	},
	usuarios(state, usuarios) {
		state.usuarios = usuarios
	},
	usuario(state, usuario) {
		state.usuario = usuario
	},
	usuario_alt(state, usuario) {
		state.usuario_alt = usuario
	},
	clientes_usuario(state, clientes) {
		state.clientes_usuario = clientes
	},
	cliente_usuario(state, cliente) {
		state.cliente_usuario = cliente
	},
	avatarUsuario(state, avatar) {
		state.usuario.avatar = avatar
	},
	configuracoes(state, configuracoes) {
		state.configuracoes = configuracoes
	},
	itens_menu(state, itens) {
		state.itens_menu = itens
	},
	usuarios_cliente(state, usuarios) {
		state.usuarios_cliente = usuarios
	},
	indicadores_usuarios(state, usuarios) {
		state.indicadores_usuarios = usuarios
	},
	usuario_indicadores(state, indicadores) {
		state.usuario_indicadores = indicadores
	},
	indicadores_sistema(state, indicadores) {
		state.indicadores_sistema = indicadores
	}
};

const actions = {
	async buscaUsuarios({ commit }) {
		commit('loading', true)
		commit('usuarios', [])
		await axios.get(`/Usuarios`)
			.then(res => {
				commit('usuarios', res.data.usuarios)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaUsuarioPorId({ commit }, id) {
		commit('loading', true)
		await axios.get(`/Usuarios/${id}`)
			.then(res => {
				commit('usuario_alt', res.data.usuario)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaUsuariosCliente({ state, commit }) {
		commit('loading', true)
		commit('usuarios_cliente', [])
		await axios.get(`/Usuarios/PorCliente/${state.cliente_usuario.id}`)
			.then(res => {
				commit('usuarios_cliente', res.data.usuarios)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async gravaUsuario({ commit }, usuario) {
		commit('loading', true)
		await axios.post('/Usuarios', usuario)
			.then(res => {
				const { email_enviado } = res.data
				let mensagem = 'Usuário cadastrado com sucesso'
				if (email_enviado) {
					mensagem += ', verifique o e-mail cadastrado'
				} else {
					mensagem += ' mas não foi possível enviar o e-mail de confirmação'
				}

				mostraMsgPadrao(mensagem)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async alteraUsuario({ commit }, payload) {
		const { id, usuario } = payload
		commit('loading', true)
		await axios.put(`/Usuarios/${id}`, usuario)
			.then(() => {
				mostraMsgPadrao('Usuário alterado com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async alteraAvatar({ commit }, payload) {
		const { id, imagem } = payload
		commit('isLoadingAvatar', true)
		const formData = new FormData()
		formData.append('avatar', imagem)
		await axios.put(`/Usuarios/AlteraAvatar/${id}`, formData)
			.then((res) => {
				const avatar = res.data.avatar
				commit('avatarUsuario', avatar)
			}).finally(() => {
				commit('isLoadingAvatar', false)
			})
	},

	async deletaAvatar({ commit }, id) {
		commit('isLoadingAvatar', true)
		await axios.delete(`/Usuarios/RemoveAvatar/${id}`)
			.then((res) => {
				const avatar = res.data.avatar
				commit('avatarUsuario', avatar)
			}).finally(() => {
				commit('isLoadingAvatar', false)
			})
	},

	async alteraMinhaSenha({ commit }, payload) {
		const { id, form } = payload
		commit('loading', true)
		await axios.put(`/Usuarios/AlteraSenha/${id}`, form)
			.then(() => {
				mostraMsgPadrao('Senha alterada com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async AlteraSenhaOutroUsuario({ commit }, payload) {
		const { id, form } = payload
		commit('loading', true)
		await axios.put(`/Usuarios/AlteraSenhaOutroUsuario/${id}`, form)
			.then(() => {
				mostraMsgPadrao('Senha alterada com sucesso')
			}).finally(() => {
				commit('loading', false)
			})
	},

	async inativaUsuario({ commit }, id) {
		commit('loading', true)
		await axios.put(`/Usuarios/Inativa/${id}`)
			.finally(() => {
				commit('loading', false)
			})
	},

	async ativaUsuario({ commit }, id) {
		commit('loading', true)
		await axios.put(`/Usuarios/Ativa/${id}`)
			.finally(() => {
				commit('loading', false)
			})
	},

	async atualizaClienteUsuario({ commit, dispatch }, cliente) {
		commit('cliente_usuario', cliente)
		if (cliente) {
			axios.defaults.headers.common['idcliente'] = cliente.id
			dispatch('atualizaItensMenu')
			EventBus.$emit('atualiza-cliente')
		}
	},

	async alteraConfiguracoesUsuario({ commit }, payload) {
		const { id, configuracoes } = payload
		commit('loading', true)
		await axios.put(`/Usuarios/Configuracoes/${id}`, configuracoes)
			.then(_res => {
				commit('configuracoes', configuracoes)
			}).finally(() => {
				commit('loading', false)
			})
	},

	async buscaUsuariosIndicador({ state, commit }, id_indicador) {
		commit('indicadores_usuarios', [])
		await axios.get(`/IndicadoresUsuarios/BuscaUsuarios/${id_indicador}/${state.cliente_usuario.id}`)
			.then((res) => {
				commit('indicadores_usuarios', res.data.ids_usuarios)
			})
	},

	async buscaIndicadoresUsuario({ state, commit }) {
		commit('usuario_indicadores', [])
		await axios.get(`/IndicadoresUsuarios/BuscaIndicadores/${state.usuario.id}/${state.cliente_usuario.id}`)
			.then((res) => {
				commit('usuario_indicadores', res.data.ids_indicadores)
			})
	},

	async buscaIndicadoresSistema({ state, commit }) {
		commit('indicadores_sistema', [])
		await axios.get(`/IndicadoresUsuarios/BuscaIndicadoresSistema/${state.cliente_usuario.sistema}`)
			.then((res) => {
				commit('indicadores_sistema', res.data.indicadores)
			})
	},

	async gravaUsuariosIndicador({ state, commit }, payload) {
		const { id_indicador, ids_usuarios } = payload
		commit('loading', true)
		await axios.put(`/IndicadoresUsuarios/${id_indicador}/${state.cliente_usuario.id}`, { ids_usuarios })
			.finally(() => {
				commit('loading', false)
			})
	},

	atualizaItensMenu({ state, commit }) {
		const { tipo } = state.usuario

		const rotinas = [
			{ id: 1, label: 'Dashboard', icone: 'mdi-home-outline', to: { name: 'Home' }, sistemas: ['Mastertransp', 'Masternfe'], gerente: true, operador: true },
			{ id: 2, label: 'Meu perfil', icone: 'mdi-account-edit-outline', to: { name: 'MeuPerfil' }, sistemas: ['Mastertransp', 'Masternfe'], gerente: true, operador: true },
			{ id: 3, label: 'Usuários', icone: 'mdi-account-group-outline', to: { name: 'Usuarios' }, sistemas: ['Mastertransp', 'Masternfe'], gerente: false, operador: false },
			{ id: 4, label: 'Clientes', icone: 'mdi-domain', to: { name: 'Clientes' }, sistemas: ['Mastertransp', 'Masternfe'], gerente: false, operador: false },
			{ id: 5, label: 'Pedido de liberações', icone: 'mdi-shield-check-outline', to: { name: 'PedidosLiberacoes' }, sistemas: ['Mastertransp', 'Masternfe'], gerente: true, operador: false },
			// { id: 6, label: 'Gerenciamento de O.C.', icone: 'mdi-chart-line', to: { name: 'GerenciaOC' }, sistemas: ['Mastertransp'], gerente: false, operador: false },
			{ id: 7, label: 'Rastreador de coleta', icone: 'mdi-package-variant-closed', to: { name: 'Rastreia' }, sistemas: ['Mastertransp'], gerente: true, operador: true },
			{ id: 8, label: 'NFes contra o CNPJ', icone: 'mdi-file-edit-outline', to: { name: 'NotasXml' }, sistemas: ['Mastertransp', 'Masternfe'], gerente: true, operador: false },
			{ id: 9, label: 'Gerenciamento riscos', icone: 'mdi-alert-outline', to: { name: 'GerenciamentoRiscos' }, sistemas: ['Mastertransp'], gerente: true, operador: false },
			{ id: 10, label: 'Confirmar frete da O.C.', icone: 'mdi-check-all', to: { name: 'ConfirmarFreteEmpresa' }, sistemas: ['Mastertransp'], gerente: true, operador: false },
			// { id: 11, label: 'Contas a receber', icone: 'mdi-currency-usd', to: { name: 'GerenciaContasReceber' }, sistemas: ['Mastertransp'], gerente: false, operador: false },
			{ id: 12, label: 'Pedidos de vendas', icone: 'mdi-cash', to: { name: 'PedidosVendas' }, sistemas: ['Masternfe'], gerente: true, operador: true },
		]

		let itens = []
		
		if (state.cliente_usuario){
			const sistema = state.cliente_usuario.sistema
			itens = rotinas.filter(rot => rot.sistemas.includes(sistema))
		} else {
			itens = rotinas.filter(rot => rot.id <= 4)
		}

		if (tipo == 'gerente') {
			itens = itens.filter(rot => rot.gerente)
		} else if (tipo == 'operador') {
			itens = itens.filter(rot => rot.operador)
		}

		commit('itens_menu', itens)
	}
};

export default {
	state,
	getters,
	mutations,
	actions
}
import { render, staticRenderFns } from "./DialogAlteraAvatar.vue?vue&type=template&id=4ccb3bf7&scoped=true&"
import script from "./DialogAlteraAvatar.vue?vue&type=script&lang=js&"
export * from "./DialogAlteraAvatar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ccb3bf7",
  null
  
)

export default component.exports